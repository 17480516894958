// TODO: add qa and stage url's after netlify deployment
const BASE_HOSTS_URI_FOR_REFERRAL_PORTAL = [
  'partners.carclarity.com.au',
  'partners-staging.carclarity.com.au',
  'partners-360finance.carclarity.com.au',
  'partners-qa.carclarity.com.au',
  'partners-fuel-qa.carclarity.com.au',
  'partners-staging.carclarity.com.au',
  'partners-uat.carclarity.com.au',
  'accelerate-qa.carclarity.com.au',
  // 'localhost:8081',
]

export const REFERRAL_HOSTS = [...BASE_HOSTS_URI_FOR_REFERRAL_PORTAL]
