var render = function () {
  var _vm$dialogData, _vm$dialogData2, _vm$dialogData3, _vm$dialogData4, _vm$dialogData5, _vm$dialogData5$vehic, _vm$dialogData6, _vm$dialogData6$vehic, _vm$dialogData7, _vm$dialogData7$vehic, _vm$dialogData8, _vm$dialogData8$vehic, _vm$dialogData9, _vm$dialogData9$vehic, _vm$dialogData10, _vm$dialogData10$vehi, _vm$dialogData11, _vm$dialogData11$vehi, _vm$dialogData12, _vm$dialogData13, _vm$dialogData13$vehi, _vm$dialogData14, _vm$dialogData14$vehi, _vm$dialogData15, _vm$dialogData15$vehi, _vm$dialogData16, _vm$dialogData16$vehi, _vm$dialogData17, _vm$dialogData17$vehi, _vm$dialogData18, _vm$dialogData18$vehi, _vm$dialogData19, _vm$dialogData19$vehi, _vm$dialogData20, _vm$dialogData20$vehi, _vm$dialogData21, _vm$dialogData21$vehi, _vm$dialogData22, _vm$dialogData22$vehi, _vm$dialogData23, _vm$dialogData23$vehi, _vm$dialogData24, _vm$dialogData24$vehi, _vm$dialogData25, _vm$dialogData25$vehi, _vm$dialogData26, _vm$dialogData26$vehi, _vm$dialogData27, _vm$dialogData27$vehi;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.dialogData ? _c('el-dialog', {
    staticClass: "modal-income title-hide",
    attrs: {
      "width": "30%",
      "close-on-click-modal": false,
      "visible": _vm.visibleSync,
      "data-selector": "asset-detail-view-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visibleSync = $event;
      }
    }
  }, [_c('div', {
    staticClass: "application-form"
  }, [_c('el-card', {
    staticClass: "card-child-p-0 pb-4",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "adv-header-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_ASSET_DETAILS))])])])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Type")]), _vm._v(" "), (_vm$dialogData = _vm.dialogData) !== null && _vm$dialogData !== void 0 && _vm$dialogData.assetType ? _c('p', {
    staticClass: "text-xs mb-4 line-height-normal text-capitalize",
    attrs: {
      "data-selector": "adv-selected-type"
    }
  }, [_vm._v(_vm._s(_vm.dialogData.assetType))]) : _vm._e()])]), _vm._v(" "), ((_vm$dialogData2 = _vm.dialogData) === null || _vm$dialogData2 === void 0 ? void 0 : _vm$dialogData2.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Sub type")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-4 line-height-normal text-capitalize",
    attrs: {
      "data-selector": "adv-selected-type"
    }
  }, [_vm._v(_vm._s(_vm.dialogData.assetSubType))])])]) : _vm._e()], 1), _vm._v(" "), ((_vm$dialogData3 = _vm.dialogData) === null || _vm$dialogData3 === void 0 ? void 0 : _vm$dialogData3.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && ((_vm$dialogData4 = _vm.dialogData) === null || _vm$dialogData4 === void 0 ? void 0 : _vm$dialogData4.assetSubType) === 'Car' && ((_vm$dialogData5 = _vm.dialogData) === null || _vm$dialogData5 === void 0 ? void 0 : (_vm$dialogData5$vehic = _vm$dialogData5.vehicle) === null || _vm$dialogData5$vehic === void 0 ? void 0 : _vm$dialogData5$vehic.makeCode) != '' ? _c('div', {
    class: {
      'border-t border-light-grey pt-4': _vm.dialogData.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && _vm.dialogData.vehicle.makeCode != ''
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Make")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-make"
    }
  }, [_vm._v(_vm._s((_vm$dialogData6 = _vm.dialogData) !== null && _vm$dialogData6 !== void 0 && (_vm$dialogData6$vehic = _vm$dialogData6.vehicle) !== null && _vm$dialogData6$vehic !== void 0 && _vm$dialogData6$vehic.makeCode ? _vm.dialogData.vehicle.makeCode : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Model")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-model"
    }
  }, [_vm._v(_vm._s((_vm$dialogData7 = _vm.dialogData) !== null && _vm$dialogData7 !== void 0 && (_vm$dialogData7$vehic = _vm$dialogData7.vehicle) !== null && _vm$dialogData7$vehic !== void 0 && _vm$dialogData7$vehic.makeDescription ? _vm.dialogData.vehicle.makeDescription : 'Not Available'))])])])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Year")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-year"
    }
  }, [_vm._v(_vm._s((_vm$dialogData8 = _vm.dialogData) !== null && _vm$dialogData8 !== void 0 && (_vm$dialogData8$vehic = _vm$dialogData8.vehicle) !== null && _vm$dialogData8$vehic !== void 0 && _vm$dialogData8$vehic.year ? _vm.dialogData.vehicle.year : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Model type")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-model-type"
    }
  }, [_vm._v(_vm._s((_vm$dialogData9 = _vm.dialogData) !== null && _vm$dialogData9 !== void 0 && (_vm$dialogData9$vehic = _vm$dialogData9.vehicle) !== null && _vm$dialogData9$vehic !== void 0 && _vm$dialogData9$vehic.codeDescription ? _vm.dialogData.vehicle.codeDescription : 'Not Available'))])])])], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    class: {
      'border-t border-light-grey pt-4': ((_vm$dialogData10 = _vm.dialogData) === null || _vm$dialogData10 === void 0 ? void 0 : (_vm$dialogData10$vehi = _vm$dialogData10.vehicle) === null || _vm$dialogData10$vehi === void 0 ? void 0 : _vm$dialogData10$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && !((_vm$dialogData11 = _vm.dialogData) !== null && _vm$dialogData11 !== void 0 && (_vm$dialogData11$vehi = _vm$dialogData11.vehicle) !== null && _vm$dialogData11$vehi !== void 0 && _vm$dialogData11$vehi.codeDescription)
    }
  }, [((_vm$dialogData12 = _vm.dialogData) === null || _vm$dialogData12 === void 0 ? void 0 : _vm$dialogData12.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && !((_vm$dialogData13 = _vm.dialogData) !== null && _vm$dialogData13 !== void 0 && (_vm$dialogData13$vehi = _vm$dialogData13.vehicle) !== null && _vm$dialogData13$vehi !== void 0 && _vm$dialogData13$vehi.codeDescription) ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Description")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-saved-desc"
    }
  }, [_vm._v(_vm._s((_vm$dialogData14 = _vm.dialogData) !== null && _vm$dialogData14 !== void 0 && (_vm$dialogData14$vehi = _vm$dialogData14.vehicle) !== null && _vm$dialogData14$vehi !== void 0 && _vm$dialogData14$vehi.customerNote ? _vm.dialogData.vehicle.customerNote : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Year")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-year"
    }
  }, [_vm._v(_vm._s((_vm$dialogData15 = _vm.dialogData) !== null && _vm$dialogData15 !== void 0 && (_vm$dialogData15$vehi = _vm$dialogData15.vehicle) !== null && _vm$dialogData15$vehi !== void 0 && _vm$dialogData15$vehi.year ? _vm.dialogData.vehicle.year : 'Not Available'))])])])], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    class: {
      'border-t border-light-grey pt-4': ((_vm$dialogData16 = _vm.dialogData) === null || _vm$dialogData16 === void 0 ? void 0 : (_vm$dialogData16$vehi = _vm$dialogData16.vehicle) === null || _vm$dialogData16$vehi === void 0 ? void 0 : _vm$dialogData16$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_OTHER || ((_vm$dialogData17 = _vm.dialogData) === null || _vm$dialogData17 === void 0 ? void 0 : (_vm$dialogData17$vehi = _vm$dialogData17.vehicle) === null || _vm$dialogData17$vehi === void 0 ? void 0 : _vm$dialogData17$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY
    }
  }, [((_vm$dialogData18 = _vm.dialogData) === null || _vm$dialogData18 === void 0 ? void 0 : (_vm$dialogData18$vehi = _vm$dialogData18.vehicle) === null || _vm$dialogData18$vehi === void 0 ? void 0 : _vm$dialogData18$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_OTHER || ((_vm$dialogData19 = _vm.dialogData) === null || _vm$dialogData19 === void 0 ? void 0 : (_vm$dialogData19$vehi = _vm$dialogData19.vehicle) === null || _vm$dialogData19$vehi === void 0 ? void 0 : _vm$dialogData19$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Description")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal text-capitalize",
    class: {
      'mb-4': ((_vm$dialogData20 = _vm.dialogData) === null || _vm$dialogData20 === void 0 ? void 0 : (_vm$dialogData20$vehi = _vm$dialogData20.vehicle) === null || _vm$dialogData20$vehi === void 0 ? void 0 : _vm$dialogData20$vehi.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY
    },
    attrs: {
      "data-selector": "adv-saved-desc"
    }
  }, [_vm._v(_vm._s((_vm$dialogData21 = _vm.dialogData) !== null && _vm$dialogData21 !== void 0 && (_vm$dialogData21$vehi = _vm$dialogData21.vehicle) !== null && _vm$dialogData21$vehi !== void 0 && _vm$dialogData21$vehi.customerNote ? _vm.dialogData.vehicle.customerNote : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Year")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-year"
    }
  }, [_vm._v(_vm._s((_vm$dialogData22 = _vm.dialogData) !== null && _vm$dialogData22 !== void 0 && (_vm$dialogData22$vehi = _vm$dialogData22.vehicle) !== null && _vm$dialogData22$vehi !== void 0 && _vm$dialogData22$vehi.year ? _vm.dialogData.vehicle.year : 'Not Available'))])])])], 1) : _vm._e()], 1), _vm._v(" "), ((_vm$dialogData23 = _vm.dialogData) === null || _vm$dialogData23 === void 0 ? void 0 : (_vm$dialogData23$vehi = _vm$dialogData23.vehicle) === null || _vm$dialogData23$vehi === void 0 ? void 0 : _vm$dialogData23$vehi.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('div', {
    staticClass: "border-t border-light-grey pt-4"
  }, [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Condition")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-condition"
    }
  }, [_vm._v(_vm._s((_vm$dialogData24 = _vm.dialogData) !== null && _vm$dialogData24 !== void 0 && (_vm$dialogData24$vehi = _vm$dialogData24.vehicle) !== null && _vm$dialogData24$vehi !== void 0 && _vm$dialogData24$vehi.condition ? _vm.dialogData.vehicle.condition : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Sale type")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-4 text-capitalize",
    attrs: {
      "data-selector": "adv-selected-sale-type"
    }
  }, [_vm._v(_vm._s((_vm$dialogData25 = _vm.dialogData) !== null && _vm$dialogData25 !== void 0 && (_vm$dialogData25$vehi = _vm$dialogData25.vehicle) !== null && _vm$dialogData25$vehi !== void 0 && _vm$dialogData25$vehi.purchaseSource ? _vm.dialogData.vehicle.purchaseSource : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("VIN")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-0 text-capitalize",
    attrs: {
      "data-selector": "adv-saved-vin"
    }
  }, [_vm._v(_vm._s((_vm$dialogData26 = _vm.dialogData) !== null && _vm$dialogData26 !== void 0 && (_vm$dialogData26$vehi = _vm$dialogData26.vehicle) !== null && _vm$dialogData26$vehi !== void 0 && _vm$dialogData26$vehi.vIN ? _vm.dialogData.vehicle.vIN : 'Not Available'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold line-height-normal"
  }, [_vm._v("Rego")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs line-height-normal mb-0 text-capitalize",
    attrs: {
      "data-selector": "adv-saved-rego"
    }
  }, [_vm._v(_vm._s((_vm$dialogData27 = _vm.dialogData) !== null && _vm$dialogData27 !== void 0 && (_vm$dialogData27$vehi = _vm$dialogData27.vehicle) !== null && _vm$dialogData27$vehi !== void 0 && _vm$dialogData27$vehi.registrationPlate ? _vm.dialogData.vehicle.registrationPlate : 'Not Available'))])])])], 1)], 1) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "adv-close-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }