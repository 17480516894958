var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.changeDocumentTypeForm ? _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "changeDocumentType",
    attrs: {
      "visible": _vm.dialogOpened,
      "width": "460px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "data-selector": "change-doc-type-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "data-selector": "m-change-doc-cancel-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.closeDialog();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticClass: "proceed-btn",
          attrs: {
            "type": "primary",
            "data-selector": "m-change-doc-yes-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.submitForm();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])];
      },
      proxy: true
    }], null, false, 3249366495)
  }, [_c('el-form', {
    ref: "changeDocumentTypeForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.changeDocumentTypeForm
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  }), _vm._v(" "), _c('h3', {
    staticClass: "title text-sm mt-2 text-black",
    attrs: {
      "data-selector": "m-title"
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c('p', {
    staticClass: "description text-sm pt-4 pb-6 mt-0 mb-6",
    attrs: {
      "data-selector": "m-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.subtitle) + " "), _c('b', {
    attrs: {
      "data-selector": "to-doc"
    }
  }, [_vm._v(_vm._s(_vm.titlePurpose))]), _vm._v("?")]), _vm._v(" "), !_vm.addOtherDocument ? _c('div', [_c('p', {
    staticClass: "title reason text-xs my-2 mt-10 text-black"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.reason) + "\n        ")]), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-0 ml-6 mr-6 pl-6 pr-6 justify-center",
    attrs: {
      "prop": "documentNewName",
      "data-selector": "m-doc-type-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.handleChange();
      }
    },
    model: {
      value: _vm.changeDocumentTypeForm.documentNewName,
      callback: function ($$v) {
        _vm.$set(_vm.changeDocumentTypeForm, "documentNewName", $$v);
      },
      expression: "changeDocumentTypeForm.documentNewName"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-doc-type-options-container"
    }
  }, _vm._l(_vm.documentTypeOptions, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.key,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.addOtherDocument ? _c('div', {
    staticClass: "other-document-field-container"
  }, [_c('div', {
    staticClass: "card-header text-center bg-white m-0"
  }, [_c('span', {
    staticClass: "text-xs font-bolder other-document-type-label",
    attrs: {
      "data-selector": "add-other-doc-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_OTHER_DOCUMENT_TYPE))]), _vm._v(" "), _c('el-button', {
    staticClass: "button",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "text",
      "data-selector": "add-other-doc-cross"
    },
    on: {
      "click": function ($event) {
        _vm.addOtherDocument = false;
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-close text-sm font-bolder text-danger"
  })])], 1), _vm._v(" "), _c('p', {
    staticClass: "title reason text-xs my-2 mt-3 text-black"
  }, [_c('span', {
    staticClass: "required pr-0"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_DOCUMENT_TYPE_NAME))]), _vm._v(" "), _vm.addOtherDocument ? _c('el-form-item', {
    staticClass: "ml-6 mr-6 pl-6 pr-6",
    attrs: {
      "prop": "addOtherDocumentName",
      "data-selector": "m-other-doc-name-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "m-other-doc-name-input"
    },
    model: {
      value: _vm.changeDocumentTypeForm.addOtherDocumentName,
      callback: function ($$v) {
        _vm.$set(_vm.changeDocumentTypeForm, "addOtherDocumentName", $$v);
      },
      expression: "changeDocumentTypeForm.addOtherDocumentName"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), !_vm.addOtherDocument ? _c('p', {
    staticClass: "py-1"
  }, [_vm._v("OR")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "ml-6 mr-6 pl-6 pr-6"
  }, [!_vm.addOtherDocument ? _c('el-button', {
    staticClass: "d-block add-other-doc-btn",
    attrs: {
      "plain": "",
      "data-selector": "add-other-doc-btn"
    },
    on: {
      "click": function ($event) {
        _vm.addOtherDocument = true;
      }
    }
  }, [_vm._v("\n          + " + _vm._s(_vm.LABEL_ADD_OTHER_DOCUMENT_TYPE))]) : _vm._e()], 1)])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }