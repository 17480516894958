var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6,
      "lg": 3
    }
  }, [_c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-item",
    class: {
      active: _vm.activeSubTab === _vm.MAINTAB_COMPLIANCE_FINANCE_KEY
    },
    attrs: {
      "index": "0",
      "data-selector": "activeSubTab"
    },
    on: {
      "click": function ($event) {
        return _vm.setActiveSubTab(_vm.MAINTAB_COMPLIANCE_FINANCE_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.MAINTAB_COMPLIANCE_FINANCE_LABEL))])]), _vm._v(" "), _c('div', {
    staticClass: "menu-item",
    class: {
      active: _vm.activeSubTab === _vm.MAINTAB_COMPLIANCE_INSURANCE_KEY
    },
    attrs: {
      "index": "1",
      "data-selector": "activeSubTab"
    },
    on: {
      "click": function ($event) {
        return _vm.setActiveSubTab(_vm.MAINTAB_COMPLIANCE_INSURANCE_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.MAINTAB_COMPLIANCE_INSURANCE_LABEL))])])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 21
    }
  }, [_vm.activeSubTab === _vm.MAINTAB_COMPLIANCE_INSURANCE_KEY ? _c('ComplianceInsuranceTab', _vm._b({
    attrs: {
      "is-commercial": _vm.isCommercial
    }
  }, 'ComplianceInsuranceTab', Object.assign({}, _vm.$props), false)) : _vm._e(), _vm._v(" "), _vm.activeSubTab !== _vm.MAINTAB_COMPLIANCE_INSURANCE_KEY ? _c('ComplianceFinanceTab', _vm._b({}, 'ComplianceFinanceTab', Object.assign({}, _vm.$props), false)) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }