var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "modal-income loan-details title-hide child-max-w-full",
    attrs: {
      "width": "460px",
      "close-on-click-modal": false,
      "visible": _vm.openDialogAssetDetailsSettlement,
      "destroy-on-close": "",
      "close-on-press-escape": false,
      "data-selector": "m-asset-details-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.openDialogAssetDetailsSettlement = $event;
      }
    }
  }, [_c('div', [_vm.assetForm && _vm.assetData ? [_c('el-card', {
    staticClass: "card-child-p-0 pb-4",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "m-as-d-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_ASSET_DETAILS))])])]), _vm._v(" "), _c('el-form', {
    ref: "assetData",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.assetData,
      "rules": _vm.rules
    }
  }, [_vm.assetData.vehicle && _vm.assetData.vehicle.assetType ? _c('el-form-item', {
    class: _vm.isAssetTypeVehicle ? 'border-b border-light-grey pb-4 mb-4' : 'mb-4',
    attrs: {
      "label": _vm.LABEL_DOCUMENT_TYPE,
      "prop": "vehicle.assetType",
      "rules": _vm.rules.assetType,
      "data-selector": "m-as-d-type-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.checkAssetType(_vm.assetData.vehicle);
      },
      "input": _vm.onChangeAssetType
    },
    model: {
      value: _vm.assetData.assetType,
      callback: function ($$v) {
        _vm.$set(_vm.assetData, "assetType", $$v);
      },
      expression: "assetData.assetType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-type-options-container"
    }
  }, _vm._l(_vm.ASSET_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _vm.assetData.assetType === 'Vehicle' ? _c('el-form-item', {
    staticClass: "border-b border-light-grey pb-4 mb-4",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_SUB_TYPE,
      "prop": "assetType",
      "data-selector": "asset-sub-type-input"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.checkAssetType(_vm.assetData.vehicle);
      }
    },
    model: {
      value: _vm.assetData.assetSubType,
      callback: function ($$v) {
        _vm.$set(_vm.assetData, "assetSubType", $$v);
      },
      expression: "assetData.assetSubType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "r-asset-sub-type-options-container"
    }
  }, _vm._l(_vm.ASSET_SUB_TYPE_OPTIONS_FOR_LOAN, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1) : _vm._e(), _vm._v(" "), _vm.isAssetTypeVehicle && !_vm.isAssetTypeOther && !_vm.isAssetTypeUnknown ? _c('el-form-item', {
    staticClass: "radio-with_contnt mb-4",
    attrs: {
      "data-selector": "m-as-d-vehicle-radio-container"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": false,
      "data-selector": "m-as-d-select-v-radio"
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_vm._v("\n            Select vehicle\n          ")]), _vm._v(" "), _c('el-radio', {
    attrs: {
      "label": true,
      "data-selector": "m-as-d-manual-v-radio"
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_vm._v("\n            Enter details manually\n          ")])], 1) : _vm._e(), _vm._v(" "), _vm.manuallySelected && _vm.isAssetTypeVehicle || _vm.isAssetTypeOther ? _c('el-form-item', {
    class: _vm.isAssetTypeUnknown ? 'mb-6' : 'mb-4',
    attrs: {
      "label": _vm.LABEL_FOR_DESCRIPTION,
      "prop": "vehicle.customerNote",
      "rules": _vm.rules.customerNote,
      "data-selector": "m-as-d-desc-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2,
      "placeholder": _vm.PLACEHOLDER_FOR_ASSET_DESCRIPTION,
      "data-selector": "m-as-d-desc-input"
    },
    model: {
      value: _vm.assetData.vehicle.customerNote,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "customerNote", $$v);
      },
      expression: "assetData.vehicle.customerNote"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.manuallySelected || _vm.isAssetTypeUnknown ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MAKE,
      "prop": "vehicle.makeCode",
      "rules": _vm.rules.makeCode,
      "data-selector": "m-as-d-make-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModels();
      }
    },
    model: {
      value: _vm.assetData.vehicle.makeCode,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "makeCode", $$v);
      },
      expression: "assetData.vehicle.makeCode"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-make-options-container"
    }
  }, _vm._l(_vm.MAKE, function (data) {
    return _c('el-option', {
      key: data.makeCode,
      attrs: {
        "label": data.description,
        "value": data.makeCode
      }
    });
  }), 1)])], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MODEL,
      "prop": "vehicle.familyCode",
      "rules": _vm.rules.familyCode,
      "data-selector": "m-as-d-model-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setYears();
      }
    },
    model: {
      value: _vm.assetData.vehicle.familyCode,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "familyCode", $$v);
      },
      expression: "assetData.vehicle.familyCode"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-model-options-container"
    }
  }, _vm._l(_vm.MODEL_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.familyCode,
      attrs: {
        "label": data.description,
        "value": data.familyCode
      }
    });
  }), 1)])], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.year,
      "data-selector": "m-as-d-year-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.yearDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModelTypes();
      }
    },
    model: {
      value: _vm.assetData.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "year", $$v);
      },
      expression: "assetData.vehicle.year"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-year-options-container"
    }
  }, _vm._l(_vm.YEARS_OPTIONS, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)])], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('div', [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MODEL_TYPE,
      "prop": "vehicle.codeDescription",
      "rules": _vm.rules.codeDescription,
      "data-selector": "m-as-d-model-type-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelTypeDisabled,
      "placeholder": "select"
    },
    model: {
      value: _vm.assetData.vehicle.codeDescription,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "codeDescription", $$v);
      },
      expression: "assetData.vehicle.codeDescription"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-model-type-options-container"
    }
  }, _vm._l(_vm.MODEL_TYPES_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.code,
      attrs: {
        "label": data.description,
        "value": data.code
      }
    });
  }), 1)])], 1) : _vm._e()], 1), _vm._v(" "), _vm.isAssetTypeUnknown ? _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DETAILS,
      "prop": "vehicle.customerNote"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2
    },
    model: {
      value: _vm.assetData.vehicle.customerNote,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "customerNote", $$v);
      },
      expression: "assetData.vehicle.customerNote"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.manuallySelected && _vm.isAssetTypeVehicle ? _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 24,
      "data-selector": "year-input-container"
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.year,
      "data-selector": "year-input"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.assetData.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "year", $$v);
      },
      expression: "assetData.vehicle.year"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "r-year-options-container"
    }
  }, _vm._l(_vm.years, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)])], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    class: {
      'border-t border-light-grey pt-4 mt-4': _vm.isAssetTypeVehicleOrOther
    }
  }, [_vm.isAssetTypeVehicleOrOther ? _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 12,
      "lg": 12
    }
  }, [_c('el-row', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "required",
    staticStyle: {
      "padding-right": "0px"
    }
  }, [_vm._v("*")]), _vm._v(" "), _c('label', {
    staticClass: "text-xs font-normal"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_CONDITION))])]), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "data-selector": "m-as-d-condition-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    class: !_vm.condition && _vm.showConditionErr ? 'assets-details-error' : '',
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.condition,
      callback: function ($$v) {
        _vm.condition = $$v;
      },
      expression: "condition"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-condition-options-container"
    }
  }, _vm._l(_vm.CONDITION_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)]), _vm._v(" "), !_vm.condition && _vm.showConditionErr ? _c('p', {
    staticStyle: {
      "font-size": "12px",
      "color": "#f56c6c",
      "line-height": "25px"
    }
  }, [_vm._v("Please select condition")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 12,
      "lg": 12
    }
  }, [_c('el-row', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "required",
    staticStyle: {
      "padding-right": "0px"
    }
  }, [_vm._v("*")]), _vm._v(" "), _c('label', {
    staticClass: "text-xs font-normal"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_SALE_TYPE))])]), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-4",
    attrs: {
      "data-selector": "m-as-d-sale-type-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    class: !_vm.purchaseSource && _vm.showSaleTypeErr ? 'assets-details-error' : '',
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.purchaseSource,
      callback: function ($$v) {
        _vm.purchaseSource = $$v;
      },
      expression: "purchaseSource"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "m-as-d-sale-type-options-container"
    }
  }, _vm._l(_vm.SALE_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)]), _vm._v(" "), !_vm.purchaseSource && _vm.showSaleTypeErr ? _c('p', {
    staticStyle: {
      "font-size": "12px",
      "color": "#f56c6c",
      "line-height": "25px"
    }
  }, [_vm._v("Please select sale type")]) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 12,
      "lg": 12
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_VIN,
      "prop": "vehicle.vIN",
      "rules": _vm.rules.vIN,
      "data-selector": "m-as-d-vin-input-container"
    }
  }, [_c('el-input', {
    staticClass: "w-full",
    attrs: {
      "data-selector": "m-as-d-vin-input"
    },
    model: {
      value: _vm.assetData.vehicle.vIN,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "vIN", $$v);
      },
      expression: "assetData.vehicle.vIN"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 12,
      "lg": 12
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_REGISTRATION_PLATE,
      "prop": "vehicle.registrationPlate",
      "rules": _vm.rules.registrationPlate,
      "data-selector": "m-as-d-rego-input-container"
    }
  }, [_c('el-input', {
    staticClass: "w-full",
    attrs: {
      "data-selector": "m-as-d-rego-input"
    },
    model: {
      value: _vm.assetData.vehicle.registrationPlate,
      callback: function ($$v) {
        _vm.$set(_vm.assetData.vehicle, "registrationPlate", $$v);
      },
      expression: "assetData.vehicle.registrationPlate"
    }
  })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('hr', {
    staticStyle: {
      "border-top": "0.1px",
      "margin": "0 -1rem"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-0 mt-4 child-w-full flex items-center"
  }, [_c('el-button', {
    staticClass: "mr-auto",
    attrs: {
      "plain": "",
      "type": "text",
      "data-selector": "m-as-d-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "float-right",
    attrs: {
      "type": "primary",
      "data-selector": "m-as-d-update-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("Update\n          ")])], 1)], 1)] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }