//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MissingFieldsModal from './UtilComponents/MissingFieldsModal.vue'
import ZeroConfirmationDialog from './UtilComponents/ZeroConfirmationDialog.vue'
import { getBalloonAmountWithApi } from '@/helpers/calculateBalloonAmount'
import { getBalloonPctWithApi } from '@/helpers/calculateBalloonPct'
import {
  LABEL_LOAN_DETAILS,
  LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
  LABEL_TRADE_IN_EQUITY,
  LABEL_NAF,
  LABEL_DOCUMENT_DEPOSIT,
  VEHICLE_CONDITION_BALLOON,
  LABEL_LOAN_TREM,
  LABEL_RATE_AND_FEE,
  LABEL_BASE_RATE,
  LABEL_MONTHLY_REPAYMENT_AMOUNT,
  LABEL_CUSTOMER_RATE,
  LABEL_LENDER_MONTHLY_FEE,
  LABEL_LENDER_FEE,
  LABEL_PSPR_FEE,
  LABEL_ORIGINATION_FEE,
  LABEL_PARTNER_REFERRAL_FEE,
  LABEL_LENDING_COMMISSION,
  LABEL_SUBMIT_DECISION_DETAILS,
  OUTCOME_OPTIONS,
  LABEL_SUBMITTED,
  LABEL_EXPIRY_APPROVAL,
  LABEL_OUTCOME_APPLICATION,
  LABEL_APPROVAL_CONDITIONS,
  LABEL_APPLICATION_SUBMITTED,
  LABEL_APPROVED,
  LABEL_OTHER_ACTIONS,
  LABEL_APPROVAL_DETAILS,
  LABEL_CONDITION,
  LOAN_DECLINED_REASONS_OPTIONS,
  LABEL_DECLINE_REASON,
  LABEL_WITHDRAWN,
  LABEL_WITHDRAW_REASON,
  LOAN_TERM_OPTION,
  LABEL_APPROVED_SUBTITLE,
  LABEL_WITHDRAW_APPLICATION,
  LABEL_DECLINED_OR_WITHDRAW_SUBTITLE_APPLICATION,
  LABEL_WITHDRAWN_SUBTITLE,
  LABEL_CLONE,
  LABEL_CLONE_SUBTITLE,
  CLONE_APPLICATION,
  LABEL_YES,
  LABEL_NO,
  LABEL_DECLINED,
  LABEL_OTHER_OPTION,
  LABEL_NOT_AVAILABLE,
  LABEL_DOCUMENT_BALLOON,
  LOAN_TERM_YEAR_OPTIONS,
  LABEL_APPLICATION_IS_APPROVED_AT,
  LABEL_APPLICATION_IS,
  LABEL_LENDER,
  LABEL_WITHDRAW_APPROVED_APPLICATION,
  LOAN_DECLINED_REASON_OTHER,
  LOAN_WITHDRAWN_REASON_OTHER,
  LABEL_REASON,
  LABEL_DECLINE_DETAILS,
  LABEL_WITHDRAWN_DETAILS,
  LOAN_WITHDRAWN_REASONS_OPTIONS,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
  APPLICATION_STATUS_APPROVED,
  APPLICATION_STATUS_WITHDRAWN,
  APPLICATION_STATUS_DECLINED,
  LABEL_REASON_FOR_BALLOON_PAYMENT,
  LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW,
  REASON_FOR_BALLOON_PAYMENT_OPTIONS,
  LABEL_BALLOON_REPAYMENT_OTHER,
  APPLICATION_TRADE_IN_VEHICLE,
  APPLICATION_STATUS_STARTED,
  LABEL_TRADING_LIABILITIES,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
  LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
  LABEL_SETTLEMENT_ETA,
  LABEL_APPROVAL_SETTLEMENT_ETA,
  LABEL_APPROVED_AS_SUBMITTED,
  LABEL_EXPIRY,
  LABEL_LENDING_COMMISSION_FEE,
  LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
  LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
  MAINTAB_LOANDETAILS_DECISION_LABEL,
  LABEL_LOAN_BALLOON_DOLLAR,
  LOAN_WITHDRAWN_REASON_STOPPED_RESPONDING,
} from '@/constants/applications'

import {
  ERROR_NAF,
  ERROR_MESSAGE_FOR_REQUIRED_FIELD,
  ERROR_EXPIRY,
  ERROR_SETTLEMENT_ETA,
  ERROR_APPROVAL_CONDITIONS,
  ERROR_APPROVAL_CONDITIONS_OPTIONS,
  ERROR_DECLINED_OPTION,
  ERROR_LOAN_TERM,
  ERROR_MESSAGE_BASE_RATE,
  ERROR_MESSAGE_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_CUSTOMER_RATE,
  ERROR_MESSAGE_ACCOUNT_KEEPING_FEE,
  ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE,
  ERROR_MESSAGE_PSPR_FEE,
  ERROR_MESSAGE_BROKER_FEE,
  ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE,
  ERROR_MESSAGE_LENDING_COMMISION_FEE,
  ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT,
  ERROR_MESSAGE_FOR_BALLOON,
  ERROR_MESSAGE_FOR_DATE_LESS_THAN_THREE_MONTH,
  MILLISECONDS_IN_A_WEEK,
  ERROR_MESSAGE_FOR_STOPPED_RESPONDING_APPLICATIONS_WITHIN_7_DAYS,
} from '@/constants'

import { format } from 'date-fns'
import { getBalloonAmount } from '@/helpers/calculateBalloonAmount'
import { getBalloonPct } from '@/helpers/calculateBalloonPct'

import LoanWithdrawDialog from './LoanWithdrawDialog.vue'
import NotificationBar from './UtilComponents/NotificationBar.vue'
import CloneApplicationDialog from './CloneApplicationDialog.vue'
import { toCurrency } from '@/helpers/currency'
import { tradeInLiabilityCommon } from '@/helpers/tradeInLiabilityCommon'
import { yearViewOption } from '~/utils/form'

import { axiosErrorMessageExtractor } from '~/utils/error'
import {
  validatePositiveNumber,
  validatePercentageWithGreaterThanZero,
  validateBalloonPercentage,
  validateAmountShouldGreaterThanZero,
  checkAmount,
  checkExpiryOfApproval,
  validateBrokerFee,
} from '~/helpers/validators'
import { handleDatePickerDisable } from '~/mixins/datevalidator.mixin'
import { changeRoute } from '~/helpers/changeRoute'
import { scrollToElement } from '~/utils/scroll'

export default {
  mixins: [handleDatePickerDisable],
  name: 'LoanDetails',
  components: {
    LoanWithdrawDialog,
    NotificationBar,
    CloneApplicationDialog,
    MissingFieldsModal,
    ZeroConfirmationDialog,
  },
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkNumberValue = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)

    const checkNumberShouldBeGreaterThanZero = (_rule, value, callback) =>
      validateAmountShouldGreaterThanZero(value, callback)

    const checkPurchasePrice = (_rule, value, callback) =>
      checkAmount(_rule, value, callback)
    const checkFinanceDepositAmount = (_rule, value, callback) =>
      checkAmount(
        _rule,
        value,
        callback,
        this.requirementData.loanDetails.approval.purchasePrice
      )

    return {
      allZeroValueFieldsData: [],
      openMissingFieldsModal: false,
      errorsdata: '',
      submitBtnLoading: false,
      toCurrency,
      format,
      yearViewOption,
      withdrawDialog: false,
      cloneApplicationDialog: false,
      LABEL_LOAN_DETAILS,
      LABEL_SUBMITTED,
      LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      LABEL_NAF,
      LABEL_TRADE_IN_EQUITY,
      LABEL_DOCUMENT_DEPOSIT,
      VEHICLE_CONDITION_BALLOON,
      LABEL_LOAN_BALLOON_DOLLAR,
      LABEL_LOAN_TREM,
      LABEL_RATE_AND_FEE,
      LABEL_BASE_RATE,
      LABEL_MONTHLY_REPAYMENT_AMOUNT,
      LABEL_CUSTOMER_RATE,
      LABEL_LENDER_MONTHLY_FEE,
      LABEL_LENDER_FEE,
      ERROR_NAF,
      LABEL_PSPR_FEE,
      LABEL_ORIGINATION_FEE,
      LABEL_LENDER,
      LABEL_PARTNER_REFERRAL_FEE,
      LABEL_WITHDRAW_APPROVED_APPLICATION,
      APPLICATION_STATUS_SUBMITTED_TO_LENDER,
      ERROR_MESSAGE_LENDING_COMMISION_FEE,
      LOAN_WITHDRAWN_REASONS_OPTIONS,
      LABEL_LENDING_COMMISSION,
      LABEL_NOT_AVAILABLE,
      LABEL_SUBMIT_DECISION_DETAILS,
      ERROR_MESSAGE_MONTHLY_REPAYMENT,
      ERROR_MESSAGE_CUSTOMER_RATE,
      OUTCOME_OPTIONS,
      LABEL_EXPIRY_APPROVAL,
      LABEL_OUTCOME_APPLICATION,
      LABEL_APPROVAL_CONDITIONS,
      LABEL_APPLICATION_SUBMITTED,
      LABEL_APPROVED,
      LABEL_DOCUMENT_BALLOON,
      LABEL_OTHER_ACTIONS,
      LABEL_APPROVAL_DETAILS,
      LABEL_CONDITION,
      LOAN_DECLINED_REASONS_OPTIONS,
      LABEL_DECLINE_REASON,
      LABEL_WITHDRAW_REASON,
      LABEL_REASON,
      LABEL_DECLINE_DETAILS,
      LABEL_APPLICATION_IS_APPROVED_AT,
      ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT,
      ERROR_MESSAGE_FOR_DATE_LESS_THAN_THREE_MONTH,
      LABEL_APPLICATION_IS,
      LABEL_WITHDRAWN,
      LOAN_TERM_OPTION,
      LABEL_APPROVED_SUBTITLE,
      LABEL_WITHDRAW_APPLICATION,
      ERROR_MESSAGE_ACCOUNT_KEEPING_FEE,
      LABEL_DECLINED_OR_WITHDRAW_SUBTITLE_APPLICATION,
      ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE,
      APPLICATION_STATUS_APPROVED,
      ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE,
      APPLICATION_STATUS_WITHDRAWN,
      LOAN_WITHDRAWN_REASON_STOPPED_RESPONDING,
      APPLICATION_STATUS_DECLINED,
      ERROR_MESSAGE_PSPR_FEE,
      ERROR_MESSAGE_BROKER_FEE,
      LABEL_WITHDRAWN_SUBTITLE,
      LABEL_WITHDRAWN_DETAILS,
      LOAN_DECLINED_REASON_OTHER,
      LOAN_WITHDRAWN_REASON_OTHER,
      LABEL_CLONE,
      LABEL_CLONE_SUBTITLE,
      LABEL_REASON_FOR_BALLOON_PAYMENT,
      LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW,
      REASON_FOR_BALLOON_PAYMENT_OPTIONS,
      LABEL_BALLOON_REPAYMENT_OTHER,
      CLONE_APPLICATION,
      LABEL_YES,
      LABEL_NO,
      LOAN_TERM_YEAR_OPTIONS,
      LABEL_SETTLEMENT_ETA,
      ERROR_MESSAGE_BASE_RATE,
      ERROR_MESSAGE_FOR_REQUIRED_FIELD,
      APPLICATION_TRADE_IN_VEHICLE,
      LABEL_DECLINED,
      LABEL_OTHER_OPTION,
      ERROR_MESSAGE_FOR_BALLOON,
      APPLICATION_STATUS_STARTED,
      requirementData: { ...this.sharedData },
      getIsDialogLoading: false,
      LABEL_TRADING_LIABILITIES,
      LABEL_APPROVED_AS_SUBMITTED,
      LABEL_APPROVAL_SETTLEMENT_ETA,
      LABEL_EXPIRY,
      remainingLiability: 0,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
      LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      openZeroConfirmationDialog: false,
      MAINTAB_LOANDETAILS_DECISION_LABEL,
      LABEL_LENDING_COMMISSION_FEE,
      LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
      LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
      rules: {
        'loanDetails.approval.outcomeOfAnApplication': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_REQUIRED_FIELD,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.settlementEta': [
          {
            required: true,
            message: ERROR_SETTLEMENT_ETA,
            trigger: 'change',
          },
        ],
        'loanDetails.approval.approvalExpiresAt': [
          {
            required: true,
            message: ERROR_EXPIRY,
            trigger: 'change',
          },
          {
            validator: (_rule, value, callback) => {
              if (
                !value ||
                (value && checkExpiryOfApproval(Date.parse(value)))
              ) {
                callback(
                  new Error(ERROR_MESSAGE_FOR_DATE_LESS_THAN_THREE_MONTH)
                )
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
        'loanDetails.approval.approvalConditions': [
          {
            required: true,
            message: ERROR_APPROVAL_CONDITIONS,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.doesApprovedAsSubmitted': [
          {
            required: true,
            message: ERROR_APPROVAL_CONDITIONS_OPTIONS,
            trigger: ['blur', 'change'],
          },
        ],
        declinedReasonType: [
          {
            required: true,
            message: ERROR_DECLINED_OPTION,
            trigger: ['blur', 'change'],
          },
        ],
        declinedReason: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_REQUIRED_FIELD,
            trigger: ['blur', 'change'],
          },
        ],
        withdrawnReasonType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_REQUIRED_FIELD,
            trigger: ['blur', 'change'],
          },
        ],
        withdrawnReason: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_REQUIRED_FIELD,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.purchasePrice': [
          {
            required: true,
            validator: checkPurchasePrice,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.tradeInAmount': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.depositAmount': [
          {
            required: true,
            validator: checkFinanceDepositAmount,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.balloonPct': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validateBalloonPercentage(
                value,
                callback,
                this.requirementData.loanDetails.approval.loanTermYears,
                ERROR_MESSAGE_FOR_BALLOON
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.balloonAmount': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.loanTermYears': [
          {
            required: true,
            message: ERROR_LOAN_TERM,
            trigger: ['blur', 'change'],
          },
        ],
        customerReasonForBalloon: [
          {
            required: true,
            message: ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.baseRate': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validatePercentageWithGreaterThanZero(
                value,
                callback,
                ERROR_MESSAGE_BASE_RATE
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.customerRate': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validatePercentageWithGreaterThanZero(
                value,
                callback,
                ERROR_MESSAGE_CUSTOMER_RATE
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.monthlyRepayment': [
          {
            required: true,
            validator: checkNumberShouldBeGreaterThanZero,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.accountKeepingFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.lenderEstablishmentFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.ppsrFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.brokerFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              validateBrokerFee(value, callback)
            },
            trigger: ['blur', 'change'],
          },
        ],

        'loanDetails.approval.lendingCommision': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.approval.netAmountFinanced': [
          {
            required: true,
            validator: checkNumberShouldBeGreaterThanZero,
            trigger: ['blur', 'change'],
          },
        ],
      },
      customDebounce: null,
      btnLoading: false,
    }
  },
  computed: {
    loanDecisionForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.requirementData = JSON.parse(JSON.stringify(this.sharedData))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return { ...this.sharedData }
    },
    submittedApproved() {
      const { approval } = this.requirementData.loanDetails
      if (approval) {
        const { outcomeOfAnApplication } = approval
        return (
          outcomeOfAnApplication &&
          [LABEL_APPROVED].includes(outcomeOfAnApplication)
        )
      }
      return false
    },
    applicationSubmitted() {
      const { approval } = this.requirementData.loanDetails
      if (approval) {
        const { outcomeOfAnApplication } = approval
        return (
          outcomeOfAnApplication &&
          [LABEL_SUBMITTED].includes(outcomeOfAnApplication)
        )
      }
      return false
    },
    applicationDeclined() {
      const { approval } = this.requirementData.loanDetails
      if (approval) {
        const { outcomeOfAnApplication } = approval
        return (
          outcomeOfAnApplication &&
          [LABEL_DECLINED].includes(outcomeOfAnApplication)
        )
      }
      return false
    },
    applicationWithdrawn() {
      const { approval } = this.requirementData.loanDetails
      if (approval) {
        const { outcomeOfAnApplication } = approval
        return (
          outcomeOfAnApplication &&
          [LABEL_WITHDRAWN].includes(outcomeOfAnApplication)
        )
      }
      return false
    },
    /* eslint-disable */
    isEditMode() {
      const { approval } = this.requirementData.loanDetails
      if (approval) {
        return approval.doesApprovedAsSubmitted
      }
      return false
    },
    /* eslint-enable */
    isApplicationSubmitted() {
      const { approval, submission } = this.requirementData.loanDetails
      return (
        (approval &&
          [APPLICATION_STATUS_SUBMITTED_TO_LENDER].includes(
            this.requirementData.status
          )) ||
        submission.submittedAt
      )
    },
    isApplicationApproved() {
      const { approval } = this.requirementData.loanDetails
      if (approval) {
        return (
          approval.approvedAt &&
          [APPLICATION_STATUS_APPROVED].includes(
            approval.outcomeOfAnApplication
          )
        )
      }
      return false
    },
    isApplicationWithdrawn() {
      const { approval } = this.requirementData.loanDetails
      return approval && approval.withdrawnAt
    },
    isApplicationDeclined() {
      const { approval } = this.requirementData.loanDetails
      return approval && approval.declinedAt
    },
    isDeclinedOther() {
      return (
        this.requirementData &&
        this.requirementData.declinedReasonType === LOAN_DECLINED_REASON_OTHER
      )
    },
    isWithdrawnOther() {
      return (
        this.requirementData &&
        this.requirementData.withdrawnReasonType === LOAN_WITHDRAWN_REASON_OTHER
      )
    },
    hasBalloonPctHasValue() {
      const { approval } = this.requirementData.loanDetails
      return approval.balloonPct > 0
    },
    isApplicationSettled() {
      const { settlement } = this.requirementData.loanDetails
      return settlement && settlement.settledAt
    },
    shouldDisplayWithdrawnBanner() {
      return (
        this.isApplicationApproved &&
        !this.isApplicationWithdrawn &&
        !this.isApplicationSettled
      )
    },
  },
  watch: {
    'requirementData.loanDetails.approval.outcomeOfAnApplication'() {
      this.isStatusSubmitted()
    },
  },
  async mounted() {
    this.checkTradeInLiability()

    this.isStatusSubmitted()
    this.$nuxt.$on('closeWithdrawDialog', () => {
      this.withdrawDialog = false
    })
    this.$nuxt.$on('submit-loan-decision', (data) => this.submit(data))
    this.$nuxt.$on(
      'close-zero-confirmation-dialog-loan-decision',
      () => (this.openZeroConfirmationDialog = false)
    )
    if (this.requirementData.status === APPLICATION_STATUS_STARTED) {
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: 'loanDetails',
          subtab: 'submission',
        },
      })
      this.$nuxt.$emit('updateComponent', 'loanDetails|submission')
    }
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('closeWithdrawDialog')
    this.$nuxt.$off('submit-loan-decision')
    this.$nuxt.$off('close-zero-confirmation-dialog-loan-decision')
    this.$nuxt.$off('close-loader')
  },
  methods: {
    onInput(field) {
      let isValid = false
      this.$refs.loanDecisionForm.validateField(field, (result) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (isValid = result)
      })
    },
    openMissingFieldsModalApproval(err) {
      this.errorsdata = err
      this.openMissingFieldsModal = !this.openMissingFieldsModal
    },
    closeMissingFieldsModalApproval() {
      this.openMissingFieldsModal = !this.openMissingFieldsModal
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    isStatusSubmitted() {
      if (
        this.requirementData?.loanDetails?.approval?.outcomeOfAnApplication ===
        LABEL_SUBMITTED
      ) {
        this.requirementData.loanDetails.approval.outcomeOfAnApplication = ''
      }
    },
    handleModel() {
      this.withdrawDialog = true
    },
    handleCloneModel() {
      this.cloneApplicationDialog = !this.cloneApplicationDialog
    },

    async calculateBallonAmount() {
      const { approval } = this.requirementData.loanDetails

      this.btnLoading = true
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        approval.balloonAmount =
          (await getBalloonAmountWithApi(
            approval?.purchasePrice || 0,
            approval?.balloonPct || 0
          )) || 0
        this.btnLoading = false
      }, 600)
    },
    calculateBallonPct() {
      const { approval } = this.requirementData.loanDetails
      this.btnLoading = true
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        approval.balloonPct =
          (await getBalloonPctWithApi(
            approval?.purchasePrice || 0,
            approval?.balloonAmount || 0
          )) || 0
        this.btnLoading = false
      }, 600)
    },

    async checkZeroValFields() {
      let isValid = false
      await this.$refs?.loanDecisionForm?.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        const nodes = document.querySelectorAll('.loanDecision .is-error')
        if (nodes && nodes.length > 0 && nodes[0]) {
          this.$nextTick(() => {
            scrollToElement(nodes[0])
          })
        }
        return
      }
      this.allZeroValueFieldsData = []
      let { brokerFee, lendingCommision, lenderEstablishmentFee } =
        this.requirementData?.loanDetails?.approval
      this.checkZeroVal(brokerFee, LABEL_ORIGINATION_FEE)
      this.checkZeroVal(lendingCommision, LABEL_LENDING_COMMISSION_FEE)
      this.checkZeroVal(lenderEstablishmentFee, LABEL_LENDER_FEE)
      if (this.allZeroValueFieldsData.length) {
        this.openZeroConfirmationDialog = true
      } else {
        this.submit()
      }
    },
    async submit(data) {
      try {
        const sFOpportunity = await this.$store.getters[
          'applications/getSFOpportunity'
        ]
        if (
          sFOpportunity.createdDate &&
          this.requirementData.loanDetails.approval.outcomeOfAnApplication ===
            APPLICATION_STATUS_WITHDRAWN &&
          this.requirementData.withdrawnReasonType ==
            LOAN_WITHDRAWN_REASON_STOPPED_RESPONDING &&
          new Date() - new Date(sFOpportunity.createdDate) <
            MILLISECONDS_IN_A_WEEK
        ) {
          this.$notify({
            title: 'Error',
            message:
              ERROR_MESSAGE_FOR_STOPPED_RESPONDING_APPLICATIONS_WITHIN_7_DAYS,
            type: 'error',
            duration: 5000,
          })
          return
        }
        this.submitBtnLoading = true
        const { id, applicationId } = this.$route.params
        let isValid = false
        await this.$refs.loanDecisionForm?.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          const nodes = document.querySelectorAll('.loanDecision .is-error')
          if (nodes && nodes.length > 0 && nodes[0]) {
            this.$nextTick(() => {
              scrollToElement(nodes[0])
            })
          }
          this.submitBtnLoading = false
          return
        }
        this.getIsDialogLoading = true
        const status = APPLICATION_STATUS_APPROVED
        if (
          ![LABEL_DECLINED, LABEL_WITHDRAWN].includes(
            this.requirementData.loanDetails.approval.outcomeOfAnApplication
          )
        ) {
          await this.$store.dispatch('applications/checkMissingFields', {
            loanDetails: { ...this.requirementData },
            id,
            applicationId,
            status,
          })
        }
        this.requirementData.loanDetails.approval.balloonPct = parseFloat(
          this.requirementData.loanDetails.approval.balloonPct
        ).toFixed(2)
        this.requirementData.loanDetails.approval.baseRate = parseFloat(
          this.requirementData.loanDetails.approval.baseRate
        ).toFixed(2)
        this.requirementData.loanDetails.approval.customerRate = parseFloat(
          this.requirementData.loanDetails.approval.customerRate
        ).toFixed(2)
        const { approval } = this.requirementData.loanDetails ?? {}
        if (!approval.depositAmount) {
          approval.depositAmount = 0
        }
        if (data) {
          this.requirementData.loanDetails.submission.zeroValueReason =
            data?.zeroValueReason
          this.requirementData.loanDetails.submission.zeroValueReasonType =
            data?.zeroValueReasonType
        }
        approval.doesApprovedAsSubmitted = false
        await this.$store.dispatch('applications/updateLoanDecision', {
          loanDetails: { ...this.requirementData },
          id,
          applicationId,
        })
        this.getIsDialogLoading = false
      } catch (err) {
        if (err?.response?.data?.errors) {
          this.getIsDialogLoading = false
          const error = err?.response?.data?.errors
          this.openMissingFieldsModalApproval(error)
        } else {
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
      } finally {
        this.submitBtnLoading = false
      }
    },
    checkZeroVal(val, type) {
      if (parseFloat(val) <= 0 || isNaN(parseFloat(val))) {
        this.allZeroValueFieldsData.push(type)
      }
    },
    onChangeApprovalSubmit(value) {
      if (value === false) {
        this.showDecisionFilledUpFields = false
        this.showDecisionFormFields = true
      } else {
        this.showDecisionFilledUpFields = true
        this.showDecisionFormFields = false
      }
    },
    checkTradeInLiability() {
      let remainingLiaArr = []
      remainingLiaArr =
        tradeInLiabilityCommon(
          this.sharedData?.tradeIn?._id,
          this.sharedData?.primaryApplicantData?.liabilities
        ) ?? []

      if (remainingLiaArr?.length)
        this.remainingLiability = remainingLiaArr[0].totalRemaining
    },
  },
}
