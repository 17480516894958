//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WarrantyFormDialog from './UtilComponents/WarrantyFormDialog.vue'
import InsuranceFormDialog from './UtilComponents/InsuranceFormDialog.vue'
import { toCurrency } from '@/helpers/currency'
import { removeUndefinedProps } from '~/helpers/objectHelper'
import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import { changeRoute } from '~/helpers/changeRoute'
import {
  LABEL_FOR_WARRANTY,
  DOCUMENT_EDIT_LINK,
  LABEL_FOR_ADD_WARRANTY,
  LABEL_FOR_PROVIDER,
  LABEL_NOT_AVAILABLE,
  LABEL_FOR_PRODUCT,
  LABEL_FOR_WHOLESALE,
  LABEL_FOR_RETAIL,
  LABEL_FOR_COMMISSION,
  LABEL_FOR_INSURANCE,
  LABEL_FOR_ADD_INSURANCE,
  LABEL_FOR_PREMIUM,
  LABEL_FOR_POLICY,
  LABEL_NEXT,
  SETTLEMENT_MENU_LOAN_DETAILS_KEY,
  FINANCE_APPLICATION_TAB_NOTES_KEY,
  INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT,
  INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT,
  INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_GIVEN,
} from '~/constants/applications'
import {
  LABEL_FOR_FREQUENCY,
  USER_SESSION_DATA_KEY,
  USER_TYPE_AGENT,
  USER_TYPE_SETTLEMENT,
  LABEL_ANNUALLY_OPTION_VALUE,
  LABEL_ANNUALLY_OPTION_LABEL,
  APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
  APPLICATION_STATUS_SETTLED,
  FORM_TYPE_ADD,
  FORM_TYPE_EDIT,
  TITLE_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
  DESCRIPTION_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
} from '@/constants'
import { validateUserRoles } from '~/helpers/user'
import { format } from 'date-fns'
export default {
  components: {
    WarrantyFormDialog,
    InsuranceFormDialog,
    TabFooter,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: true,
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DOCUMENT_EDIT_LINK,
      LABEL_FOR_WARRANTY,
      LABEL_FOR_ADD_WARRANTY,
      LABEL_FOR_PROVIDER,
      LABEL_NOT_AVAILABLE,
      LABEL_FOR_PRODUCT,
      LABEL_FOR_WHOLESALE,
      LABEL_FOR_RETAIL,
      LABEL_FOR_COMMISSION,
      LABEL_FOR_INSURANCE,
      LABEL_FOR_POLICY,
      USER_SESSION_DATA_KEY,
      USER_TYPE_AGENT,
      USER_TYPE_SETTLEMENT,
      LABEL_FOR_ADD_INSURANCE,
      LABEL_FOR_PREMIUM,
      LABEL_FOR_FREQUENCY,
      LABEL_NEXT,
      LABEL_ANNUALLY_OPTION_VALUE,
      LABEL_ANNUALLY_OPTION_LABEL,
      SETTLEMENT_MENU_LOAN_DETAILS_KEY,
      FINANCE_APPLICATION_TAB_NOTES_KEY,
      APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
      APPLICATION_STATUS_SETTLED,
      FORM_TYPE_ADD,
      FORM_TYPE_EDIT,
      TITLE_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
      DESCRIPTION_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
      loanData: {},
      isDialogLoading: false,
      // WarrantyFormDialogState and InsuranceFormDialogState is either "" | "Edit" | "Add"
      // Empty string means the dialog is not opened, "Edit" means it is opened in edit mode and "Add" means it is opened in add mode
      WarrantyFormDialogState: '',
      InsuranceFormDialogState: '',
      format,
      INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT,
      INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT,
      INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_GIVEN,
    }
  },
  async mounted() {
    this.$nuxt.$on('closeRequestWarrantyDialog', () => {
      this.WarrantyFormDialogState = ''
    })
    this.$nuxt.$on('closeRequestInsuranceDialog', () => {
      this.InsuranceFormDialogState = ''
    })
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('closeRequestWarrantyDialog')
    this.$nuxt.$off('closeRequestInsuranceDialog')
    this.$nuxt.$off('close-loader')
  },
  computed: {
    loanDetailsForm() {
      return this.setInitialData()
    },
    isSettled() {
      return this.loanData.appData.status === APPLICATION_STATUS_SETTLED
    },
    disableWarranty() {
      const userRoles = this.$auth.$storage.getUniversal(
        USER_SESSION_DATA_KEY
      )?.systemRoleTypes

      return validateUserRoles(userRoles, [USER_TYPE_AGENT]) && this.isSettled
    },
    disableInsurance() {
      const userRoles = this.$auth.$storage.getUniversal(
        USER_SESSION_DATA_KEY
      )?.systemRoleTypes

      return (
        (validateUserRoles(userRoles, [USER_TYPE_AGENT]) && this.isSettled) ||
        (validateUserRoles(userRoles, [
          USER_TYPE_AGENT,
          USER_TYPE_SETTLEMENT,
        ]) &&
          !this.sharedData.insurancePrivacyConsent?.date)
      )
    },
    getInsuranceFrquency() {
      const frequency =
        this.loanData.appData?.loanDetails?.settlement?.insurance?.frequency ||
        ''
      return frequency
        ? frequency === LABEL_ANNUALLY_OPTION_VALUE
          ? LABEL_ANNUALLY_OPTION_LABEL
          : frequency
        : LABEL_NOT_AVAILABLE
    },
    insuranceAlertStatus() {
      return !this.sharedData.insurancePrivacyConsent?.date &&
        !this.sharedData.insurancePrivacyConsent?.lastSentAt?.date
        ? INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT
        : !this.sharedData.insurancePrivacyConsent?.date &&
          this.sharedData.insurancePrivacyConsent?.lastSentAt?.date
        ? INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT
        : INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_GIVEN
    },
  },
  methods: {
    setInitialData() {
      this.loanData = {
        appData: removeUndefinedProps(this.sharedData),
      }

      if (
        this.loanData.appData.loanDetails.settlement &&
        !this.loanData.appData.loanDetails.settlement.warranty
      ) {
        this.loanData.appData.loanDetails.settlement.warranty = {
          provider: '',
          product: '',
          wholesale: '',
          retail: '',
          commission: '',
        }
      }

      if (
        this.loanData.appData.loanDetails.settlement &&
        !this.loanData.appData.loanDetails.settlement.insurance
      ) {
        this.loanData.appData.loanDetails.settlement.insurance = {
          provider: '',
          product: '',
          wholesale: '',
          retail: '',
          commission: '',
        }
      }
      return true
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    openWarrantyForm(newWarrantyFormDialogState) {
      this.WarrantyFormDialogState = newWarrantyFormDialogState
    },
    openInsuranceForm(newInsuranceFormDialogState) {
      this.InsuranceFormDialogState = newInsuranceFormDialogState
    },
    async sectionRedirect(subTab) {
      this.$emit('sectionRedirect', subTab)
    },
    async gotoNext() {
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: FINANCE_APPLICATION_TAB_NOTES_KEY,
        },
      })
      this.$nuxt.$emit('updateComponent', FINANCE_APPLICATION_TAB_NOTES_KEY)
    },
    async sendInsurancePrivacyConsentEmailAndSmsRequest(applicantId) {
      this.isDialogLoading = true
      try {
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        const { id, applicationId } = this.$route.params
        await this.$store.dispatch(
          'applications/insurancePrivacyConsentRequestEmailAndSms',
          {
            id,
            applicationId,
            applicantId,
          }
        )

        await this.$store.dispatch('applications/fetchApplications', {
          id,
          applicationId,
        })
        this.isDialogLoading = false
      } catch (err) {
        this.isDialogLoading = false
      }
    },
  },
}
