import { format, differenceInCalendarYears, differenceInYears } from 'date-fns'
import {
  ERROR_MESSAGE_FOR_INVALID,
  ERROR_MESSAGE_FOR_NUMBER_LENGTH,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_MAX_AMOUNT,
  ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN,
  ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO,
  ERROR_MESSAGE_FOR_VALID_ABN,
  ERROR_MESSAGE_FOR_DEPOSIT,
  ERROR_MESSAGE_FOR_REQUIRED_FIELD,
  ERROR_FOR_MAX_NUMBER_REACHED,
  ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN,
  ERROR_MESSAGE_FOR_DISTINCT_MOBILE_NUM,
  REGEX_FOR_AUS_PHONE,
  REGEX_FOR_AUS_MOBILES,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_ZERO_AMOUNT,
  ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO,
  ERROR_MESSAGE_FOR_MAX_BROKER_FEE,
  ERROR_MESSAGE_FOR_WARRENTY_RETAIL_PRICE,
} from '../constants'

import {
  BALLOON_PCT_MAX_PER_LOAN_TERMS,
  BALLOON_AMOUNT_CAN_NOT_BE_GREATER_THAN,
  LABEL_FOR,
  LABEL_YEARS,
  ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE,
  MAX_BROKER_FEE,
  ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE_ZERO_TO_HUNDRED,
} from '../constants/applications'
import { numbersOnly, mobileWithoutCountryCode } from './mobile'
import { postcodeToState } from './address'
import { ERROR_MESSAGE_FOR_INVALID_BALLOON } from '~/constants'

export const validateBrokerFee = (value, callback) => {
  if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (Number(value) && Number(value) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else if (parseInt(value) < 0 || value > parseInt(MAX_BROKER_FEE)) {
    callback(new Error(ERROR_MESSAGE_FOR_MAX_BROKER_FEE + MAX_BROKER_FEE))
  } else if (value === null || value === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD))
  } else {
    callback()
  }
}

export const isMobile = (_, value, callback) => {
  const mobileNumbersOnly = Number(numbersOnly(value))
  const mobileWithoutCountryCode_ = mobileWithoutCountryCode(mobileNumbersOnly)
  const isValidMobile = mobileWithoutCountryCode_.startsWith('0')
    ? /^04\d{8}$/.test(mobileWithoutCountryCode_)
    : /^4\d{8}$/.test(mobileWithoutCountryCode_)
  if (isValidMobile) {
    callback()
  } else {
    callback(new Error('Please input valid mobile number'))
  }
}

export const isPostcodeValid = (postcode) => Boolean(postcodeToState(postcode))

export const isValidPhoneNum = (phonenumber) => {
  const validPhone = /^(0\d{9})|(614\d{8}|04\d{8})$/
  return validPhone.test(phonenumber)
}

export const isValidABNNum = (abnNumber) => {
  const validABN = /^[0-9]{11}$/
  return validABN.test(abnNumber)
}

export const isdateOfBirthGTE18Years = (date) => {
  const day = format(new Date(date), 'dd')
  const month = format(new Date(date), 'MM')
  const year = format(new Date(date), 'yyyy')
  const birthDate = new Date(year, month - 1, day)
  return differenceInCalendarYears(new Date(), birthDate) >= 18
}

export const isdateOfBirthGTE18YearsAndLessThan110Years = (date) => {
  const day = format(new Date(date), 'dd')
  const month = format(new Date(date), 'MM')
  const year = format(new Date(date), 'yyyy')
  const birthDate = new Date(year, month - 1, day)
  return (
    differenceInYears(new Date(), birthDate) >= 18 &&
    differenceInYears(new Date(), birthDate) <= 110
  )
}
export const isdateLessThan10Years = (date) => {
  const day = format(new Date(date), 'dd')
  const month = format(new Date(date), 'MM')
  const year = format(new Date(date), 'yyyy')
  const MainDate = new Date(year, month - 1, day)
  return differenceInCalendarYears(MainDate, new Date()) <= 10
}

export const validateReferralBalloonPct = (_, value, callback) => {
  try {
    const amount = Number(value)
    if (amount >= 0 && amount <= 60) {
      callback()
    } else {
      callback(new Error(ERROR_MESSAGE_FOR_INVALID_BALLOON))
    }
  } catch {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID_BALLOON))
  }
}

export const checkExpiryOfApproval = (date) => {
  let currentDate = new Date()
  let selectedDate = new Date(date)
  let currentTime = Date.now()
  var d = currentDate.getDate()
  currentDate.setMonth(currentDate.getMonth() + 3)
  if (currentDate.getDate() != d) {
    currentDate.setDate(0)
  }
  return (
    currentDate <= selectedDate.getTime() ||
    currentTime >= selectedDate.getTime()
  )
}

const checkLoanYearWithBalloon = (callback, balloon, year) => {
  const getLimit = BALLOON_PCT_MAX_PER_LOAN_TERMS[year]
  if (balloon > getLimit) {
    callback(
      new Error(
        BALLOON_AMOUNT_CAN_NOT_BE_GREATER_THAN +
          ` ` +
          getLimit +
          ` ` +
          LABEL_FOR +
          ` ` +
          year +
          ` ` +
          LABEL_YEARS
      )
    )
  } else {
    callback()
  }
}

export const validateNumber = (
  _rule,
  value,
  callback,
  comparisonAmount = undefined
) => {
  if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  }

  if (parseInt(value).toString().length > 10) {
    return callback(new Error(ERROR_MESSAGE_FOR_MAX_AMOUNT))
  }
  if (parseInt(value) > Number.MAX_SAFE_INTEGER) {
    return callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED))
  }

  if (comparisonAmount && parseInt(value) > parseInt(comparisonAmount)) {
    return callback(
      new Error(
        `${ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN}${comparisonAmount}`
      )
    )
  }

  if (parseInt(value) === parseInt(comparisonAmount)) {
    return callback(
      new Error(`${ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO}${comparisonAmount}`)
    )
  }

  if (value === null || value === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_DEPOSIT))
  }
  callback()
}

export const validatePositiveNumber = (value, callback) => {
  const checkVal = parseFloat(value).toFixed(2)

  if (isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (Number(checkVal) && Number(checkVal) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else if (`${checkVal.split('.')[0]}`.length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH))
  } else if (Number(checkVal) > Number.MAX_SAFE_INTEGER) {
    callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED))
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD))
  } else {
    callback()
  }
}

export const validateAmountShouldGreaterThanZero = (value, callback) => {
  const checkVal = value ? parseFloat(value).toFixed(2) : value
  if (Number(checkVal) <= 0 || !checkVal) {
    callback(new Error(ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO))
  } else if (isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (String(checkVal).length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH))
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD))
  } else {
    callback()
  }
}

export const validateNumericField = (value, callback) => {
  const checkVal = parseFloat(value).toFixed(2)

  if (isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (Number(checkVal) && Number(checkVal) <= 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else if (String(parseInt(checkVal)).length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH))
  } else if (!checkVal) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD))
  } else if (checkVal == 0) {
    callback(new Error(ERROR_MESSAGE_FOR_ZERO_AMOUNT))
  } else {
    callback()
  }
}

export const validatePercentageWithGreaterThanZero = (
  value,
  callback,
  defaultErrorMessage
) => {
  if (value === null || value === undefined || value === '') {
    callback(defaultErrorMessage)
  } else if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (parseInt(value) <= 0 || value > 100) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else {
    callback()
  }
}

export const validatePercentage = (value, callback, defaultErrorMessage) => {
  if (value === null || value === undefined) {
    callback(defaultErrorMessage)
  } else if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (parseInt(value) < 0 || value > 100) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else {
    callback()
  }
}

export const validateBalloonPercentage = (
  value,
  callback,
  loanYears,
  defaultErrorMessage,
  maxBalloonPct = 100
) => {
  if (!value && value !== 0) {
    callback(defaultErrorMessage)
  } else if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (parseInt(value) < 0 || value > maxBalloonPct) {
    callback(
      new Error(`${ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE} ${maxBalloonPct}%`)
    )
  } else {
    callback()
    // TO FULFILLED THE PURPOSE OF THIS TICKET - https://carclarity.atlassian.net/browse/WEB-4451
    // BELOW CODE IS COMMENTED
    // checkLoanYearWithBalloon(callback, value, loanYears)
  }
}
export const validateBalloonPercentageWithGreaterThanZero = (
  value,
  callback,
  loanYears,
  defaultErrorMessage,
  maxBalloonPct = 100
) => {
  if (!value && value !== 0) {
    callback(defaultErrorMessage)
  } else if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (parseInt(value) < 0 || value > maxBalloonPct) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE_ZERO_TO_HUNDRED))
  } else {
    callback()
    // TO FULFILLED THE PURPOSE OF THIS TICKET - https://carclarity.atlassian.net/browse/WEB-4451
    // BELOW CODE IS COMMENTED
    // checkLoanYearWithBalloon(callback, value, loanYears)
  }
}

export const checkAmount = (
  _rule,
  value,
  callback,
  comparisonAmount = undefined
) => {
  if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  }

  if (parseInt(value).toString().length > 10) {
    return callback(new Error(ERROR_MESSAGE_FOR_MAX_AMOUNT))
  }
  if (parseInt(value) > Number.MAX_SAFE_INTEGER) {
    return callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED))
  }

  if (comparisonAmount && parseInt(value) > parseInt(comparisonAmount)) {
    return callback(
      new Error(
        `${ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN}${comparisonAmount}`
      )
    )
  }

  if (parseInt(value) === parseInt(comparisonAmount)) {
    return callback(
      new Error(`${ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO}${comparisonAmount}`)
    )
  }
  if (Number(value) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  }
  if (value === null || value === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_DEPOSIT))
  }
  callback()
}

export const validateABN = (value, callback, defaultErrorMessage) => {
  if (!value) {
    callback(defaultErrorMessage)
  } else if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (value.length <= 0 || value.length > 15) {
    callback(new Error(ERROR_MESSAGE_FOR_VALID_ABN))
  }
}

export const validateTrusteeACN = (value, callback, defaultErrorMessage) => {
  if (!value) {
    callback(defaultErrorMessage)
  } else if (isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
  } else if (
    value.split('.')[0].length <= 0 ||
    value.split('.')[0].length > 9
  ) {
    callback(new Error(ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN))
  }
}

export const validateDistinctMobileNumber = (
  rule,
  value,
  callback,
  currentMobileNumber = '',
  existingMobileNumbers = []
) => {
  if (value && value.length > 0 && !value.match(REGEX_FOR_AUS_PHONE)) {
    return callback(ERROR_MESSAGE_FOR_INVALID_MOBILE)
  }
  let mobile = `${value}`

  mobile = stripMobileNumberFormat(mobile)
  currentMobileNumber = stripMobileNumberFormat(`${currentMobileNumber}`)
  existingMobileNumbers = existingMobileNumbers
    .map((mobileNumber) => stripMobileNumberFormat(mobileNumber))
    .filter((mobileNumber) => mobileNumber !== currentMobileNumber)
  const isMobileNumberDistinct = !existingMobileNumbers.filter(
    (mobileNumber) => mobileNumber === mobile
  ).length
  if (!isMobileNumberDistinct) {
    return callback(new Error(ERROR_MESSAGE_FOR_DISTINCT_MOBILE_NUM))
  }
  return callback()
}

const stripMobileNumberFormat = (mobileNumber) => {
  if (mobileNumber && mobileNumber.indexOf('0') === 0) {
    return mobileNumber.substring(1)
  } else if (mobileNumber && mobileNumber.indexOf('61') === 0) {
    return mobileNumber.substring(2)
  }
  return mobileNumber
}
export const validateWarrentyRetailPrice = (
  _rule,
  value,
  callback,
  warrentyPrice
) => {
  if (parseFloat(warrentyPrice) > parseFloat(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_WARRENTY_RETAIL_PRICE))
  } else {
    return callback()
  }
}
export const validateAssestLiabilityType = (assets, id) => {
  const data = assets.find((x) => x.liability === id)
  return data && data.hasLiability
}
export const validatePositiveNumberWithZero = (value, callback) => {
  const checkVal = parseFloat(value).toFixed(2)
  if (Number(checkVal) && Number(checkVal) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else if (`${checkVal.split('.')[0]}`.length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH))
  } else if (Number(checkVal) > Number.MAX_SAFE_INTEGER) {
    callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED))
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD))
  } else {
    callback()
  }
}

export const validateForEquifaxScore = (value, callback) => {
  const minScore = -999
  const maxScore = 1200
  const checkVal = Number(value).toFixed(2)
  if (`${checkVal.split('.')[0]}`.length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH))
  } else if (Number(checkVal) < minScore || Number(checkVal) > maxScore) {
    callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED))
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD))
  } else if (isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID))
  } else {
    callback()
  }
}
