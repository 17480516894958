var render = function () {
  var _vm$documentData$shar, _vm$documentData$prim, _vm$documentData$prim2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "border mb-4"
  }, [_c('el-card', [_c('div', {
    staticClass: "el-alert"
  }, [_c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "icon": "el-icon-download",
      "plain": "",
      "data-selector": "download-all-btn"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.download(_vm.documentData);
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.LABEL_DOWNLOAD_ALL_DOCUMENTS) + "\n        ")]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "icon": "el-icon-download",
      "plain": "",
      "data-selector": "download-all-zip-btn"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.downloadAllZipDocuments(_vm.documentData);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_DOWNLOAD_ALL_DOCUMENTS_AS_ZIP))])], 1)])], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "application-form document-section"
  }, [_vm.documentForm ? [_c('el-form', {
    ref: "documentForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.documentData
    }
  }, [_vm.isFromSettlementTab ? _c('el-card', {
    attrs: {
      "data-selector": "st-settlement-docs-section"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', [_c('span', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "settlement-doc-header-text"
    }
  }, [_vm._v(_vm._s(_vm.SETTLEMENT_DOCUMENT_LABEL) + "\n              ")])])]), _vm._v(" "), _c('el-row', [_c('el-col', [_vm.isDocumentAvailible('sharedData').length === 0 ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs",
    attrs: {
      "data-selector": "set-doc-no-document-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.checkDocumentCondition('sharedData') || (_vm$documentData$shar = _vm.documentData.sharedData) !== null && _vm$documentData$shar !== void 0 && _vm$documentData$shar.sharedDocuments ? _c('div', [_c('el-row', {
    staticClass: "flex flex-wrap mr-0",
    class: _vm.documentData.sharedData.selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.sharedData.selectedDocuments.filter(function (doc) {
    return doc.documentFor && doc.purpose !== _vm.LABEL_BANK_STATEMENT_SECURE;
  }), function (documentUpload, index) {
    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "xs": 24,
        "md": 12,
        "data-selector": 'set-doc-tile-container_' + index
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "dialog-for": _vm.LABEL_DOCUMENT_SETTLEMENT,
        "primary-applicant-data": _vm.documentData.primaryData.primaryApplicant,
        "document-data": _vm.documentData.sharedData.sharedDocuments,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "application-status": _vm.sharedData.status
      }
    })], 1);
  }), 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus",
      "data-selector": "set-doc-add-document-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_DOCUMENT_SETTLEMENT,
          docType: 'settlement'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isFromSettlementTab ? _c('el-card', {
    staticClass: "mt-6",
    attrs: {
      "data-selector": "st-doc-pri-section"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', [_c('span', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "st-doc-pri-header-text"
    }
  }, [_vm._v(_vm._s(_vm.isCommercial ? _vm.primaryApplicantName : _vm.LABEL_SETTLEMENT_PRIMARY_APPLICANT) + "\n              ")]), _vm._v(" "), _vm.isCommercial ? _c('span', {
    staticClass: "text-grey-dark text-xs"
  }, [_vm._v("\n                " + _vm._s((_vm$documentData$prim = _vm.documentData.primaryData) === null || _vm$documentData$prim === void 0 ? void 0 : _vm$documentData$prim.designation) + "\n              ")]) : _vm._e()])]), _vm._v(" "), _c('el-row', [_c('el-col', [_vm.isDocumentAvailible('primaryData').length == 0 ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs",
    attrs: {
      "data-selector": "st-doc-pri-no-document-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.checkDocumentCondition('primaryData') || _vm.documentData.primaryData.documents ? _c('div', [_c('el-row', {
    staticClass: "flex flex-wrap mr-0",
    class: _vm.documentData.primaryData.selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.primaryData.selectedDocuments.filter(function (doc) {
    return doc.documentFor && doc.purpose !== _vm.LABEL_BANK_STATEMENT_SECURE;
  }), function (documentUpload, index) {
    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "xs": 24,
        "md": 12,
        "data-selector": 'st-pri-document-tile-container_' + index
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "dialog-for": _vm.LABEL_DOCUMENT_PRIMARY,
        "primary-applicant-data": _vm.documentData.primaryData.primaryApplicant,
        "document-data": _vm.documentData.primaryData.documents,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "option-for-document": _vm.LABEL_SETTLEMENT_PRIMARY,
        "application-status": _vm.sharedData.status
      }
    })], 1);
  }), 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus",
      "data-selector": "st-doc-pri-add-document-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_DOCUMENT_PRIMARY,
          docType: 'settlementPrimary'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.isFromSettlementTab ? _c('el-card', {
    attrs: {
      "data-selector": "doc-pri-section"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', [_c('span', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "pri-header-text"
    }
  }, [_vm._v(_vm._s(_vm.isCommercial ? _vm.primaryApplicantName : _vm.LABEL_PRIMARY_APPLICANT) + "\n              ")]), _vm._v(" "), _vm.isCommercial ? _c('span', {
    staticClass: "text-grey-dark text-xs"
  }, [_vm._v("\n                " + _vm._s((_vm$documentData$prim2 = _vm.documentData.primaryData) === null || _vm$documentData$prim2 === void 0 ? void 0 : _vm$documentData$prim2.designation) + "\n              ")]) : _vm._e()])]), _vm._v(" "), _c('el-row', [_c('el-col', [_vm.checkDocumentCondition('primaryData').length <= _vm.isDocumentAvailible('primaryData').length ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs",
    attrs: {
      "data-selector": "pri-no-document-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.checkDocumentCondition('primaryData') || _vm.documentData.primaryData.documents ? _c('div', [_c('el-row', {
    staticClass: "flex flex-wrap mr-0",
    class: _vm.documentData.primaryData.selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.primaryData.selectedDocuments.filter(function (doc) {
    return !doc.documentFor && doc.purpose !== _vm.LABEL_BANK_STATEMENT_SECURE;
  }), function (documentUpload, index) {
    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "xs": 24,
        "md": 12,
        "data-selector": 'pri-document-tile-container_' + index
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "dialog-for": _vm.LABEL_DOCUMENT_PRIMARY,
        "primary-applicant-data": _vm.documentData.primaryData.primaryApplicant,
        "document-data": _vm.documentData.primaryData.documents,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "document-for": _vm.documentFor,
        "application-status": _vm.sharedData.status,
        "primary-document-data": _vm.documentData.primaryData.selectedDocuments,
        "shared-document-data": _vm.documentData.sharedData.selectedDocuments
      }
    })], 1);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.documentData.primaryData.bankStatementRequestedAt ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 48
    }
  }, [_c('BankStatementUpload', {
    attrs: {
      "bank-statement-requested-at": _vm.documentData.primaryData.bankStatementRequestedAt,
      "applicant-data": _vm.documentData.primaryData.primaryApplicant,
      "file-data": _vm.documentData.primaryData.documents,
      "selected-bank-documents": _vm.documentData.primaryData.selectedDocuments,
      "dialog-for": _vm.LABEL_DOCUMENT_PRIMARY
    }
  })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "flex pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "request-documents-button w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus",
      "data-selector": "pri-add-document-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_DOCUMENT_PRIMARY,
          docType: _vm.LABEL_PRIMARY_LOWERCASE
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))]), _c('el-button', {
    staticClass: "w-auto request-documents-button",
    attrs: {
      "plain": "",
      "disabled": !_vm.primaryApplicantResendRequest.length
    },
    on: {
      "click": function ($event) {
        var _vm$documentData, _vm$documentData$prim3, _vm$documentData$prim4;

        return _vm.requestPendingDocuments(false, true, (_vm$documentData = _vm.documentData) === null || _vm$documentData === void 0 ? void 0 : (_vm$documentData$prim3 = _vm$documentData.primaryData) === null || _vm$documentData$prim3 === void 0 ? void 0 : (_vm$documentData$prim4 = _vm$documentData$prim3.primaryApplicant) === null || _vm$documentData$prim4 === void 0 ? void 0 : _vm$documentData$prim4._id);
      }
    }
  }, [_c('div', {
    staticClass: "flex items-end"
  }, [_vm.primaryApplicantResendRequest.length ? _c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": require("@/assets/icons/send.svg")
    }
  }) : _c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": require("@/assets/icons/send_gray.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.LABEL_REQUEST_PENDING_DOCUMENTS))])])]), _vm._v(" "), _c('el-button', {
    staticClass: "request-manually w-auto",
    attrs: {
      "plain": "",
      "disabled": !_vm.primaryApplicantResendRequest.length
    },
    on: {
      "click": function ($event) {
        var _vm$documentData2, _vm$documentData2$pri, _vm$documentData2$pri2;

        return _vm.requestPendingDocuments(true, true, (_vm$documentData2 = _vm.documentData) === null || _vm$documentData2 === void 0 ? void 0 : (_vm$documentData2$pri = _vm$documentData2.primaryData) === null || _vm$documentData2$pri === void 0 ? void 0 : (_vm$documentData2$pri2 = _vm$documentData2$pri.primaryApplicant) === null || _vm$documentData2$pri2 === void 0 ? void 0 : _vm$documentData2$pri2._id);
      }
    }
  }, [_c('div', {
    staticClass: "desc"
  }, [_c('span', {
    staticClass: "sub-text"
  }, [_vm._v(_vm._s(_vm.LABEL_ALTERNATIVELY) + ",")]), _vm._v(" "), _c('span', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_REQUEST_MANUALLY))])])])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isSecondaryDocumentAvailable() && !_vm.isCommercial && _vm.isFromSettlementTab ? _c('el-card', {
    staticClass: "mt-6",
    attrs: {
      "data-selector": "st-doc-sec-section"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "st-doc-sec-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SETTLEMENT_SECONDARY_APPLICANT))])]), _vm._v(" "), _c('el-row', [_c('el-col', [!_vm.isDocumentForSettlementApplicant().length ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs",
    attrs: {
      "data-selector": "st-doc-sec-no-document-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.documentData.secondaryData[0].selectedDocuments ? _c('div', [_c('el-row', {
    staticClass: "mr-0",
    class: _vm.documentData.secondaryData[0].selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.secondaryData[0].selectedDocuments.filter(function (doc) {
    return doc.documentFor && doc.purpose !== _vm.LABEL_BANK_STATEMENT_SECURE;
  }), function (documentUpload, index) {
    var _vm$documentData$seco, _vm$documentData$seco2;

    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "span": 12,
        "data-selector": 'st-doc-sec-document-tile-container_' + index
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "dialog-for": _vm.LABEL_DOCUMENT_SECONDARY,
        "primary-applicant-data": (_vm$documentData$seco = _vm.documentData.secondaryData[0]) === null || _vm$documentData$seco === void 0 ? void 0 : _vm$documentData$seco.applicant,
        "document-data": (_vm$documentData$seco2 = _vm.documentData.secondaryData[0]) === null || _vm$documentData$seco2 === void 0 ? void 0 : _vm$documentData$seco2.documents,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "option-for-document": _vm.LABEL_SETTLEMENT_SECONDARY,
        "application-status": _vm.sharedData.status
      }
    })], 1);
  }), 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus",
      "data-selector": "st-doc-sec-add-document-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_DOCUMENT_SECONDARY,
          docType: _vm.LABEL_SETTLEMENT_SECONDARY
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isSecondaryDocumentAvailable() && !_vm.isCommercial && !_vm.isFromSettlementTab ? _c('el-card', {
    staticClass: "mt-6",
    attrs: {
      "data-selector": "doc-sec-section"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_SECONDARY_APPLICANT))])]), _vm._v(" "), _c('el-row', [_c('el-col', [_vm.checkDocumentApplicantCondition().length <= _vm.isDocumentForSettlementApplicant().length ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs",
    attrs: {
      "data-selector": "sec-no-document-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.documentData.secondaryData[0].selectedDocuments ? _c('div', [_c('el-row', {
    staticClass: "mr-0",
    class: _vm.documentData.secondaryData[0].selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.secondaryData[0].selectedDocuments.filter(function (doc) {
    return !doc.documentFor && doc.purpose !== _vm.LABEL_BANK_STATEMENT_SECURE;
  }), function (documentUpload, index) {
    var _vm$documentData$seco3, _vm$documentData$seco4;

    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "span": 12,
        "data-selector": 'sec-document-tile-container_' + index
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "dialog-for": _vm.LABEL_DOCUMENT_SECONDARY,
        "primary-applicant-data": (_vm$documentData$seco3 = _vm.documentData.secondaryData[0]) === null || _vm$documentData$seco3 === void 0 ? void 0 : _vm$documentData$seco3.applicant,
        "document-data": (_vm$documentData$seco4 = _vm.documentData.secondaryData[0]) === null || _vm$documentData$seco4 === void 0 ? void 0 : _vm$documentData$seco4.documents,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "application-status": _vm.sharedData.status
      }
    })], 1);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.documentData.secondaryData[0].bankStatementRequestedAt ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 48
    }
  }, [_c('BankStatementUpload', {
    attrs: {
      "bank-statement-requested-at": _vm.documentData.secondaryData[0].bankStatementRequestedAt,
      "applicant-data": _vm.documentData.secondaryData[0].applicant,
      "file-data": _vm.documentData.secondaryData[0].documents,
      "selected-bank-documents": _vm.documentData.secondaryData[0].selectedDocuments,
      "dialog-for": _vm.LABEL_DOCUMENT_SECONDARY
    }
  })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "flex pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus",
      "data-selector": "sec-add-document-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_DOCUMENT_SECONDARY,
          docType: ''
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))]), _c('el-button', {
    staticClass: "w-auto request-documents-button",
    attrs: {
      "plain": "",
      "disabled": !_vm.secondaryApplicantResendRequest(0).length
    },
    on: {
      "click": function ($event) {
        var _vm$documentData3, _vm$documentData3$sec, _vm$documentData3$sec2;

        return _vm.requestPendingDocuments(false, false, (_vm$documentData3 = _vm.documentData) === null || _vm$documentData3 === void 0 ? void 0 : (_vm$documentData3$sec = _vm$documentData3.secondaryData[0]) === null || _vm$documentData3$sec === void 0 ? void 0 : (_vm$documentData3$sec2 = _vm$documentData3$sec.applicant) === null || _vm$documentData3$sec2 === void 0 ? void 0 : _vm$documentData3$sec2._id, 0);
      }
    }
  }, [_c('div', {
    staticClass: "flex items-end"
  }, [!_vm.secondaryApplicantResendRequest(0).length ? _c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": require("@/assets/icons/send_gray.svg")
    }
  }) : _c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": require("@/assets/icons/send.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.LABEL_REQUEST_PENDING_DOCUMENTS_MANUALLY))])])])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isSecondaryDocumentAvailable() && _vm.isCommercial && _vm.isFromSettlementTab ? [_vm._l(_vm.documentData.secondaryData, function (otherApplicant, otherApplicantIndex) {
    var _otherApplicant$appli, _otherApplicant$appli2;

    return [_c('el-card', {
      key: otherApplicantIndex
    }, [_c('div', {
      staticClass: "clearfix",
      attrs: {
        "slot": "header"
      },
      slot: "header"
    }, [_c('p', [_c('span', {
      staticClass: "text-xs font-extrabold"
    }, [_vm._v(_vm._s((otherApplicant === null || otherApplicant === void 0 ? void 0 : (_otherApplicant$appli = otherApplicant.applicant) === null || _otherApplicant$appli === void 0 ? void 0 : _otherApplicant$appli.firstName) + ' ' + (otherApplicant === null || otherApplicant === void 0 ? void 0 : (_otherApplicant$appli2 = otherApplicant.applicant) === null || _otherApplicant$appli2 === void 0 ? void 0 : _otherApplicant$appli2.lastName)))]), _vm._v(" "), _c('span', {
      staticClass: "text-grey-dark text-xs"
    }, [_vm._v(_vm._s(otherApplicant === null || otherApplicant === void 0 ? void 0 : otherApplicant.designation))])])]), _vm._v(" "), _c('el-row', [_c('el-col', [!_vm.isDocumentForCommercialApplicant(otherApplicant).length > 0 ? _c('div', {
      staticClass: "inline-block w-full mb-4"
    }, [_c('p', {
      staticClass: "text-light-black text-xs"
    }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                      "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                      " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), otherApplicant.selectedDocuments ? _c('div', [_c('el-row', {
      staticClass: "mr-0",
      class: otherApplicant.selectedDocuments.length > 0 ? 'my-2' : '',
      attrs: {
        "gutter": 40
      }
    }, _vm._l(otherApplicant.selectedDocuments.filter(function (doc) {
      return doc.documentFor;
    }), function (documentUpload, index) {
      return _c('el-col', {
        key: index,
        staticClass: "pr-4",
        attrs: {
          "span": 12
        }
      }, [documentUpload.documentFor ? [_c('DocumentUpload', {
        attrs: {
          "document-id": documentUpload._id,
          "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
          "document-purpose": documentUpload.purpose,
          "document-extra-name": documentUpload.name,
          "document-requested-date": documentUpload.requestedAt,
          "document-uploaded-date": documentUpload.uploadedAt,
          "document-num": index,
          "document-requested-via-email": documentUpload.requestedViaEmail,
          "dialog-for": _vm.LABEL_DOCUMENT_SECONDARY,
          "primary-applicant-data": otherApplicant.applicant,
          "document-data": otherApplicant.documents,
          "secondary-index": otherApplicantIndex,
          "is-application-settled": _vm.isApplicationSettled,
          "is-from-settlement-tab": _vm.isFromSettlementTab,
          "option-for-document": _vm.LABEL_SETTLEMENT_SECONDARY,
          "application-status": _vm.sharedData.status
        }
      })] : _vm._e()], 2);
    }), 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 20
      }
    }, [_c('el-col', [_c('div', {
      staticClass: "pt-4 border-t border-light-grey"
    }, [_c('el-button', {
      staticClass: "w-auto text-xs flex",
      attrs: {
        "plain": "",
        "disabled": _vm.isApplicationSettled,
        "icon": "el-icon-plus"
      },
      on: {
        "click": function ($event) {
          return _vm.addDocument({
            type: _vm.LABEL_DOCUMENT_SECONDARY,
            index: otherApplicantIndex,
            docType: _vm.LABEL_SETTLEMENT_OTHER
          });
        }
      }
    }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))])], 1)])], 1)], 1)];
  })] : _vm._e(), _vm._v(" "), _vm.isSecondaryDocumentAvailable() && _vm.isCommercial && !_vm.isFromSettlementTab ? [_vm._l(_vm.documentData.secondaryData, function (otherApplicant, otherApplicantIndex) {
    var _otherApplicant$appli3, _otherApplicant$appli4, _vm$secondaryApplican, _vm$secondaryApplican2;

    return [_c('el-card', {
      key: otherApplicantIndex,
      staticClass: "mt-6"
    }, [_c('div', {
      staticClass: "clearfix",
      attrs: {
        "slot": "header"
      },
      slot: "header"
    }, [_c('p', [_c('span', {
      staticClass: "text-xs font-extrabold"
    }, [_vm._v(_vm._s((otherApplicant === null || otherApplicant === void 0 ? void 0 : (_otherApplicant$appli3 = otherApplicant.applicant) === null || _otherApplicant$appli3 === void 0 ? void 0 : _otherApplicant$appli3.firstName) + ' ' + (otherApplicant === null || otherApplicant === void 0 ? void 0 : (_otherApplicant$appli4 = otherApplicant.applicant) === null || _otherApplicant$appli4 === void 0 ? void 0 : _otherApplicant$appli4.lastName)))]), _vm._v(" "), _c('span', {
      staticClass: "text-grey-dark text-xs"
    }, [_vm._v(_vm._s(otherApplicant === null || otherApplicant === void 0 ? void 0 : otherApplicant.designation))])])]), _vm._v(" "), _c('el-row', [_c('el-col', [_vm.checkCommercialApplicant(otherApplicant).length == 0 ? _c('div', {
      staticClass: "inline-block w-full mb-4"
    }, [_c('p', {
      staticClass: "text-light-black text-xs"
    }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                      "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                      " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), otherApplicant.selectedDocuments ? _c('div', [_c('el-row', {
      staticClass: "mr-0",
      class: otherApplicant.selectedDocuments.length > 0 ? 'my-2' : '',
      attrs: {
        "gutter": 40
      }
    }, _vm._l(otherApplicant.selectedDocuments.filter(function (doc) {
      return !doc.documentFor;
    }), function (documentUpload, index) {
      return _c('el-col', {
        key: index,
        staticClass: "pr-4",
        attrs: {
          "span": 12
        }
      }, [_c('DocumentUpload', {
        attrs: {
          "document-id": documentUpload._id,
          "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
          "document-purpose": documentUpload.purpose,
          "document-extra-name": documentUpload.name,
          "document-requested-date": documentUpload.requestedAt,
          "document-uploaded-date": documentUpload.uploadedAt,
          "document-num": index,
          "document-requested-via-email": documentUpload.requestedViaEmail,
          "dialog-for": _vm.LABEL_DOCUMENT_SECONDARY,
          "primary-applicant-data": otherApplicant.applicant,
          "document-data": otherApplicant.documents,
          "secondary-index": otherApplicantIndex,
          "is-application-settled": _vm.isApplicationSettled,
          "is-from-settlement-tab": _vm.isFromSettlementTab,
          "application-status": _vm.sharedData.status
        }
      })], 1);
    }), 1)], 1) : _vm._e(), _vm._v(" "), otherApplicant.bankStatementRequestedAt ? _c('el-row', {
      staticClass: "mt-4",
      attrs: {
        "gutter": 48
      }
    }, [_c('BankStatementUpload', {
      attrs: {
        "bank-statement-requested-at": otherApplicant.bankStatementRequestedAt,
        "applicant-data": otherApplicant.applicant,
        "file-data": otherApplicant.documents,
        "selected-bank-documents": otherApplicant.selectedDocuments,
        "secondary-index": otherApplicantIndex,
        "dialog-for": _vm.LABEL_DOCUMENT_SECONDARY
      }
    })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 20
      }
    }, [_c('el-col', [_c('div', {
      staticClass: "flex pt-4 border-t border-light-grey"
    }, [_c('el-button', {
      staticClass: "w-auto text-xs",
      attrs: {
        "plain": "",
        "disabled": _vm.isApplicationSettled,
        "icon": "el-icon-plus"
      },
      on: {
        "click": function ($event) {
          return _vm.addDocument({
            type: _vm.LABEL_DOCUMENT_SECONDARY,
            index: otherApplicantIndex,
            docType: ''
          });
        }
      }
    }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))]), _c('el-button', {
      staticClass: "w-auto request-documents-button",
      attrs: {
        "plain": "",
        "disabled": !((_vm$secondaryApplican = _vm.secondaryApplicantResendRequest(otherApplicantIndex)) !== null && _vm$secondaryApplican !== void 0 && _vm$secondaryApplican.length)
      },
      on: {
        "click": function ($event) {
          return _vm.requestPendingDocuments(false, false, otherApplicant === null || otherApplicant === void 0 ? void 0 : otherApplicant.applicant._id, otherApplicantIndex);
        }
      }
    }, [_c('div', {
      staticClass: "flex items-end"
    }, [(_vm$secondaryApplican2 = _vm.secondaryApplicantResendRequest(otherApplicantIndex)) !== null && _vm$secondaryApplican2 !== void 0 && _vm$secondaryApplican2.length ? _c('img', {
      staticStyle: {
        "margin-right": "10px"
      },
      attrs: {
        "src": require("@/assets/icons/send.svg")
      }
    }) : _c('img', {
      staticStyle: {
        "margin-right": "10px"
      },
      attrs: {
        "src": require("@/assets/icons/send_gray.svg")
      }
    }), _c('span', [_vm._v(_vm._s(_vm.LABEL_REQUEST_PENDING_DOCUMENTS_MANUALLY))])])])], 1)])], 1)], 1)];
  })] : _vm._e(), _vm._v(" "), _vm.isSecondaryDocumentAvailable() && !_vm.isCommercial && !_vm.isFromSettlementTab ? _c('el-card', {
    staticClass: "mt-6",
    attrs: {
      "data-selector": "doc-sha-section"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "sha-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SHARED))])]), _vm._v(" "), _c('el-row', [_c('el-col', [_vm.checkDocumentCondition('sharedData').length <= _vm.isDocumentAvailible('sharedData').length ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs",
    attrs: {
      "data-selector": "sha-no-document-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.checkDocumentCondition('sharedData') ? _c('div', [_c('el-row', {
    staticClass: "mr-0",
    class: _vm.documentData.sharedData.selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.sharedData.selectedDocuments.filter(function (v) {
    return !v.documentFor;
  }), function (documentUpload, index) {
    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "span": 12,
        "data-selector": 'sha-document-tile-container_' + index
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "dialog-for": _vm.LABEL_SHARED,
        "primary-applicant-data": _vm.documentData.primaryData.primaryApplicant,
        "document-data": _vm.documentData.sharedData.sharedDocuments,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "application-status": _vm.sharedData.status,
        "primary-document-data": _vm.documentData.primaryData.selectedDocuments,
        "shared-document-data": _vm.documentData.sharedData.selectedDocuments
      }
    })], 1);
  }), 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus",
      "data-selector": "sha-add-document-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_SHARED,
          docType: ''
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isCommercial && !_vm.isFromSettlementTab ? _c('el-card', {
    staticClass: "mt-6"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_BUSINESS_DOCUMENTS))])]), _vm._v(" "), _c('el-row', [_c('el-col', [!_vm.checkDocumentCondition('sharedData') || !_vm.checkDocumentCondition('sharedData').length ? _c('div', {
    staticClass: "inline-block w-full mb-4"
  }, [_c('p', {
    staticClass: "text-light-black text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_ADD_MORE_DOCUMENT_THIRD))])]) : _vm._e(), _vm._v(" "), _vm.checkDocumentCondition('sharedData') ? _c('div', [_c('el-row', {
    staticClass: "flex flex-wrap mr-0",
    class: _vm.documentData.sharedData.selectedDocuments.length > 0 ? 'my-2' : '',
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.documentData.sharedData.selectedDocuments.filter(function (doc) {
    return !doc.documentFor;
  }), function (documentUpload, index) {
    return _c('el-col', {
      key: index,
      staticClass: "pr-4",
      attrs: {
        "xs": 24,
        "md": 12
      }
    }, [_c('DocumentUpload', {
      attrs: {
        "document-id": documentUpload._id,
        "document-name": documentUpload.name ? documentUpload.name : _vm.getMappedNameWithPurpose(documentUpload.purpose),
        "document-purpose": documentUpload.purpose,
        "document-extra-name": documentUpload.name,
        "document-requested-date": documentUpload.requestedAt,
        "document-uploaded-date": documentUpload.uploadedAt,
        "document-num": index,
        "dialog-for": _vm.LABEL_SHARED,
        "document-data": _vm.documentData.sharedData.sharedDocuments,
        "primary-applicant-data": _vm.documentData.primaryData.primaryApplicant,
        "document-requested-via-email": documentUpload.requestedViaEmail,
        "is-application-settled": _vm.isApplicationSettled,
        "is-from-settlement-tab": _vm.isFromSettlementTab,
        "application-status": _vm.sharedData.status,
        "primary-document-data": _vm.documentData.primaryData.selectedDocuments,
        "shared-document-data": _vm.documentData.sharedData.selectedDocuments
      }
    })], 1);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.documentData.primaryData.bankStatementRequestedAt ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 48
    }
  }, [_c('BankStatementUpload', {
    attrs: {
      "bank-statement-requested-at": _vm.documentData.primaryData.bankStatementRequestedAt,
      "applicant-data": _vm.documentData.primaryData.primaryApplicant,
      "file-data": _vm.documentData.sharedData.selectedDocuments,
      "selected-bank-documents": _vm.documentData.sharedData.selectedDocuments,
      "dialog-for": _vm.LABEL_DOCUMENT_PRIMARY
    }
  })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', [_c('div', {
    staticClass: "pt-4 border-t border-light-grey"
  }, [_c('el-button', {
    staticClass: "w-auto text-xs",
    attrs: {
      "plain": "",
      "disabled": _vm.isApplicationSettled,
      "icon": "el-icon-plus"
    },
    on: {
      "click": function ($event) {
        return _vm.addDocument({
          type: _vm.LABEL_BUSINESS_DOCUMENTS,
          docType: ''
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))])], 1)])], 1)], 1) : _vm._e()], 2)] : _vm._e(), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "documents-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.backToPrevious();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_BACK_BUTTON))])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "documents-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.next();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_NEXT))])], 1), _vm._v(" "), _c('DocumentDialog', {
    attrs: {
      "dialog-data": _vm.dialogData,
      "show-dialog": _vm.openDialog,
      "dialog-for": _vm.dialogFor,
      "applicant-id": _vm.applicantId,
      "applicant-email": _vm.applicantEmail,
      "applicant-name": _vm.applicantName,
      "secondary-index": _vm.secondaryIndex,
      "dialog-label": _vm.documentDialogLabel,
      "is-commercial": _vm.isCommercial,
      "is-from-settlement-tab": _vm.isFromSettlementTab,
      "document-for": _vm.documentFor,
      "primary-document-data": _vm.documentData.primaryData.selectedDocuments,
      "shared-document-data": _vm.documentData.sharedData.selectedDocuments
    },
    on: {
      "closeDocumentDialog": _vm.closeDocumentDialog
    }
  }), _vm._v(" "), _c('AlertDetailsDialog', {
    attrs: {
      "dialog-opened": _vm.alertDialog,
      "width": "460px",
      "title": _vm.LABEL_RESEND_EMAIL_FOR_PENDING_DOCUMENTS,
      "subtitle": _vm.ResendDocumentsSubtitle(_vm.selectedIndex),
      "details": _vm.isPrimary ? _vm.primaryApplicantResendRequest : _vm.secondaryApplicantResendRequest(_vm.selectedIndex),
      "resend-applicant-id": _vm.resendApplicantId,
      "loading-dialog": _vm.loadingDialog
    },
    on: {
      "submitAlertDetailsDialog": _vm.submitAlertDetailsDialog
    }
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }