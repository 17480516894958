//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toCurrency } from '@/helpers/currency'
import { tradeInLiabilityCommon } from '@/helpers/tradeInLiabilityCommon'
import { format } from 'date-fns'
import LoanDetailsAssetDialog from './LoanDetailsAssetDialog.vue'
import LoanDetailsFormDialog from './UtilComponents/LoanDetailsFormDialog.vue'
import AssetDetailsView from './UtilComponents/AssetDetailsView.vue'
import LenderDialog from './LenderDialog.vue'
import {
  LABEL_LENDER,
  LABEL_LOAN_DETAILS,
  LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
  LABEL_DOCUMENT_DEPOSIT,
  LABEL_DOCUMENT_BALLOON,
  LABEL_TRADE_IN_EQUITY,
  LABEL_FOR_VIEW_PROPOSAL,
  LABEL_LOAN_BALLOON_DOLLAR,
  LABEL_LOAN_TREM,
  LABEL_BASE_RATE,
  LABEL_CUSTOMER_RATE,
  LABEL_LENDER_FEE,
  LABEL_ORIGINATION_FEE,
  LABEL_LENDING_COMMISSION,
  LABEL_MONTHLY_REPAYMENT_AMOUNT,
  LABEL_LENDER_MONTHLY_FEE,
  LABEL_PSPR_FEE,
  LABEL_PARTNER_REFERRAL_FEE,
  LABEL_FOR_ASSET_DETAILS,
  WARNING_MESSAGE_FOR_ASSET_DETAIL,
  LABEL_FOR_NAF,
  LABEL_FOR_RATES_FEES,
  LABEL_FOR_SAVE_PRE_SETTLEMENT,
  LABEL_DOCUMENT_VEHICLE,
  DOCUMENT_EDIT_LINK,
  LOAN_TERM_YEAR_OPTIONS,
  SUCCESS_TITLE_FOR_PRE_SETTLEMENT,
  SUCCESS_DESCRIPTION_FOR_PRE_SETTLEMENT,
  SUCCESS_TITLE_FOR_CREDIT_PROPSAL,
  SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL,
  LABEL_NOT_AVAILABLE,
  MAINTAB_DOCS_KEY,
  LABEL_APPLICATION_SETTLED_AT,
  LABEL_GO_TO_DOCUMENTS,
  LABEL_YES_PROCEED,
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT,
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT_DIFFERENT,
  LABEL_CANCEL,
  DOCUMENT_VIEW_LINK,
  APPLICATION_TRADE_IN_VEHICLE,
  APPLICATION_STATUS_STARTED,
  LABEL_DOCUMENT_ESTIMETED_PRICE,
  LABEL_TRADING_LIABILITIES,
  FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS,
  LABEL_REVIEW_AND_SEND_PROPOSAL,
  SAVE_AND_SEND_LABEL,
  LABEL_FOR_VIEW,
  LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
  LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION,
  LABEL_FOR_MISSING_DOCUMENTS,
  REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING,
  LABEL_FOR_STATUS_UPDATE,
  WARNING_MESSAGE_FOR_LENDER,
  LABEL_FOR_NAME,
  LABEL_LOAN_ID,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
  APPLICATION_STATUS_APPROVED,
  LOAN_TYPE_PERSONAL_LOAN,
  LABEL_NEXT,
  SETTLEMENT_MENU_SELLER_DETAILS_KEY,
  SETTLEMENT_MENU_DOCUMENT_KEY,
  SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
} from '~/constants/applications'

import {
  APPLICATION_STATUS_SETTLED,
  APPLICATION_STATUS_PRE_SETTLEMENT,
  APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  APPLICATION_REQUEST_TYPE_COMMERCIAL,
  USER_TYPE_SETTLEMENT,
} from '@/constants'

import { axiosErrorMessageExtractor } from '~/utils/error'
import { removeUndefinedProps } from '~/helpers/objectHelper'
import { yearViewOption } from '~/utils/form'
import { changeRoute } from '~/helpers/changeRoute'
import { getSignedURL } from '~/utils/url'
import NotificationBar from './UtilComponents/NotificationBar.vue'
import AssetInfo from '~/components/Applications/AssetInfo.vue'
import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import MissingDocumntDialog from './MissingDocumentDialog.vue'
import { validateUserRoles } from '~/helpers/user'
export default {
  components: {
    LoanDetailsAssetDialog,
    LoanDetailsFormDialog,
    AssetDetailsView,
    NotificationBar,
    LenderDialog,
    AssetInfo,
    TabFooter,
    MissingDocumntDialog,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    accountData: {
      type: Array,
      default: () => [],
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    /* eslint-disable */
    const checkNumber = (rule, value, callback) => {
      if ([undefined, null].includes(value)) {
        return callback(new Error('not an number'))
      }
      if (Number(value) && Number(value) < 0) {
        callback(new Error('no negative value'))
      } else {
        callback()
      }
    }
    /* eslint-enable */
    return {
      LABEL_LOAN_ID,
      LABEL_FOR_NAME,
      errorsdata: '',
      LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      remainingLiability: 0,
      LABEL_TRADING_LIABILITIES,
      submitBtnLoading: false,
      format,
      yearViewOption,
      toCurrency,
      APPLICATION_STATUS_SETTLED,
      APPLICATION_STATUS_PRE_SETTLEMENT,
      loanData: { ...this.sharedData, ...this.quoteData },
      isAssetDetailDialogOpen: false,
      loanDetailsFormDialog: false,
      areRequiredDocsUploadedBeforeSettlement: true,
      preSettlementDailog: false,
      LABEL_LENDER,
      LABEL_LOAN_DETAILS,
      LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      LABEL_FOR_VIEW_PROPOSAL,
      LABEL_DOCUMENT_DEPOSIT,
      LABEL_DOCUMENT_BALLOON,
      LABEL_TRADE_IN_EQUITY,
      LABEL_LOAN_BALLOON_DOLLAR,
      LABEL_LOAN_TREM,
      LABEL_BASE_RATE,
      LABEL_CUSTOMER_RATE,
      LABEL_LENDER_FEE,
      LOAN_TERM_YEAR_OPTIONS,
      LABEL_ORIGINATION_FEE,
      LABEL_LENDING_COMMISSION,
      LABEL_MONTHLY_REPAYMENT_AMOUNT,
      LABEL_LENDER_MONTHLY_FEE,
      LABEL_PSPR_FEE,
      LABEL_PARTNER_REFERRAL_FEE,
      LABEL_FOR_ASSET_DETAILS,
      WARNING_MESSAGE_FOR_ASSET_DETAIL,
      LABEL_FOR_NAF,
      LABEL_FOR_RATES_FEES,
      LABEL_DOCUMENT_VEHICLE,
      LABEL_FOR_SAVE_PRE_SETTLEMENT,
      SUCCESS_TITLE_FOR_PRE_SETTLEMENT,
      SUCCESS_DESCRIPTION_FOR_PRE_SETTLEMENT,
      SUCCESS_TITLE_FOR_CREDIT_PROPSAL,
      SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL,
      DOCUMENT_EDIT_LINK,
      LABEL_APPLICATION_SETTLED_AT,
      LABEL_GO_TO_DOCUMENTS,
      LABEL_YES_PROCEED,
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT,
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT_DIFFERENT,
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
      LABEL_CANCEL,
      LABEL_NEXT,
      SETTLEMENT_MENU_SELLER_DETAILS_KEY,
      SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
      isPreSettlementDone: false,
      isSubmitProposal: false,
      showCreditPropsalMsg: false,
      showSettlementMsg: false,
      selectedAccount: {
        businessName: '',
      },
      LABEL_NOT_AVAILABLE,
      MAINTAB_DOCS_KEY,
      DOCUMENT_VIEW_LINK,
      assetViewDialog: false,
      APPLICATION_TRADE_IN_VEHICLE,
      isDialogLoading: false,
      lenderData: null,
      APPLICATION_STATUS_STARTED,
      LABEL_DOCUMENT_ESTIMETED_PRICE,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS,
      LABEL_REVIEW_AND_SEND_PROPOSAL,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      openSendProposalConfirmation: false,
      cpdUrl: null,
      isSettlement: false,
      isPreSettlement: false,
      LABEL_FOR_VIEW,
      SAVE_AND_SEND_LABEL,
      LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION,
      LABEL_FOR_MISSING_DOCUMENTS,
      REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING,
      LABEL_FOR_STATUS_UPDATE,
      missingDocuments: [],
      WARNING_MESSAGE_FOR_LENDER,
      openLenderDialog: false,
      assignedToSettlementUser: '',
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
      APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
      APPLICATION_REQUEST_TYPE_COMMERCIAL,
      LOAN_TYPE_PERSONAL_LOAN,
      isPreSettlementUpdate: false,
      ShouldDisplayMissingDocumentDialog: false,
      errMessage: '',
    }
  },
  computed: {
    /* eslint-disable */
    isDiffDialog() {
      if (this.isCommercial) return false
      if (
        this.loanData.appData?.loanDetails?.shouldSendCpdOnPreSettlement !==
        undefined
      ) {
        return this.loanData.appData?.loanDetails?.shouldSendCpdOnPreSettlement
      }

      let isPreSettlementSameAsDecisionFlg = Boolean(
        this.loanData?.appData?.loanDetails?.isPreSettlementIsSameAsApproval
      )
        ? this.loanData?.appData?.loanDetails?.isPreSettlementIsSameAsApproval
        : false

      return !(
        this.loanData?.appData?.loanDetails?.isApprovalIsSameAsSubmission &&
        isPreSettlementSameAsDecisionFlg
      )
    },
    canEditLoanDetails() {
      return (
        validateUserRoles(this.$auth?.user?.systemRoleTypes, [
          USER_TYPE_SUPER_ADMIN,
          USER_TYPE_ADMIN,
          USER_TYPE_SETTLEMENT,
        ]) || this.loanDetailsForm.status !== APPLICATION_STATUS_SETTLED
      )
    },
    isSettled() {
      return this.loanDetailsForm.status === APPLICATION_STATUS_SETTLED
    },
    dateRange() {
      return { LOAN_TERM_YEAR_OPTIONS }
    },
    getBusinessName() {
      return (
        this.loanData.appData?.loanDetails?.lenderData?.businessName ||
        LABEL_NOT_AVAILABLE
      )
    },
    loanDetailsForm() {
      this.loanData = {
        appData: removeUndefinedProps(this.sharedData),
        appAccountData: removeUndefinedProps(this.accountData),
      }

      if (
        !this.loanData.appData.loanDetails.lenderData &&
        this.loanData.appData.loanDetails.lender
      ) {
        this.getLenderDetails(this.loanData.appData.loanDetails.lender)
      }
      return { ...this.sharedData, ...this.accountData }
    },
    IsStatusSettledOrPreSettled() {
      if (this.loanData.appData?.loanDetails?.settlement) {
        const { preSettledAt, settledAt } =
          this.loanData?.appData?.loanDetails?.settlement
        return preSettledAt || settledAt ? true : false
      }
    },
    isAssetVehicle() {
      if (this.loanData?.appData?.vehicle) {
        return (
          this.loanData?.appData?.vehicle?.assetType === LABEL_DOCUMENT_VEHICLE
        )
      }
    },
    hasLenderData() {
      return (
        this.getBusinessName &&
        this.loanData?.appData?.loanDetails?.settlement?.loanId?.trim().length
      )
    },
    showAssetValidationWarning() {
      return (
        this.loanData?.appData?.status &&
        [
          APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
          APPLICATION_STATUS_PRE_SETTLEMENT,
          APPLICATION_STATUS_APPROVED,
        ].includes(this.loanData?.appData?.status)
      )
    },
    hasValidAssetData() {
      const { vehicle } = this.loanData?.appData || {}
      if (!vehicle) {
        return false
      } else if (!vehicle.condition || !vehicle.purchaseSource) {
        return false
      } else if (this.isAssetVehicle && this.vehicleDetailsEnteredManually) {
        return !!vehicle.customerNote
      } else if (this.isAssetVehicle && !this.vehicleDetailsEnteredManually) {
        return (
          vehicle.makeDescription &&
          vehicle.familyDescription &&
          vehicle.year &&
          vehicle.codeDescription
        )
      } else if (!this.isAssetVehicle) {
        // if others
        return vehicle.customerNote
      }

      return true
    },
    hasAssetVehicleData() {
      if (this.loanData?.appData?.vehicle) {
        const { vehicle } = this.loanData?.appData
        return (
          vehicle.year ||
          vehicle.makeDescription ||
          vehicle.familyDescription ||
          (vehicle.codeDescription && vehicle.customerNote)
        )
      }
    },
    vehicleDetailsEnteredManually() {
      const { vehicle } = this.loanData?.appData || {}
      return (
        vehicle &&
        vehicle.assetType === LABEL_ASSET_TYPE_OPTION_VEHICLE &&
        !!vehicle.customerNote
      )
    },
    shouldDisplayMissingDocDialog() {
      return !this.areRequiredDocsUploadedBeforeSettlement
    },
    shouldShowPreSettlementBanner() {
      return (
        !this.isFromSettlementTab &&
        this.loanData.appData?.loanDetails?.settlement?.preSettledAt
      )
    },
    shouldShowSubmitSettlementBanner() {
      return (
        this.shouldShowPreSettlementBanner &&
        (this.loanData.appData?.loanDetails?.creditProposalDisclosureSentAt ||
          this.isCommercial) &&
        !this.loanData.appData?.loanDetails?.settlement?.settledAt
      )
    },
    proposalCreated() {
      let finalURL = null

      if (this.loanData?.appData?.sharedDocuments) {
        const document = this.loanData.appData.sharedDocuments.find(
          (docs) =>
            docs.purpose.toLowerCase() ===
            LABEL_CREDIT_PROPOSAL_DISCLOSURE.toLowerCase()
        )
        if (document) {
          finalURL = document.url
        }
      }

      return !!finalURL
    },
    shouldDisplayCpdAlert() {
      return (
        !this.isFromSettlementTab &&
        this.loanData.appData?.status === APPLICATION_STATUS_PRE_SETTLEMENT &&
        this.proposalCreated &&
        !this.isCommercial &&
        this.loanData.appData?.loanDetails?.settlement
          ?.shouldResendCpdOnSettlement
      )
    },
    shouldDisplaySuccessCpdAlert() {
      return (
        !this.isFromSettlementTab &&
        !this.shouldDisplayCpdAlert &&
        this.loanData?.appData?.applicationType !==
          APPLICATION_REQUEST_TYPE_COMMERCIAL &&
        this.loanData?.appData?.loanDetails?.settlement?.preSettledAt &&
        this.loanData?.appData?.loanDetails?.creditProposalDisclosureSentAt &&
        this.loanData?.appData?.loanDetails?.creditProposalDisclosureSentAt >
          this.loanData?.appData?.loanDetails?.settlement?.preSettledAt
      )
    },
  },
  async mounted() {
    this.checkTradeInLiability()
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
    })

    this.$nuxt.$on('closeLenderDialog', () => {
      this.openLenderDialog = false
    })
    this.$nuxt.$on('closeAssetDetailsDialog', () => {
      this.isAssetDetailDialogOpen = false
    })
    this.$nuxt.$on('closeAssetDetailsDialogSettlement', () => {
      this.isAssetDetailDialogOpen = false
    })
    this.$nuxt.$on('closeRequestloanDetailsDataDialog', (sendCpd) => {
      this.loanDetailsFormDialog = false
      if (sendCpd) this.shouldSendCpd()
    })
    this.$nuxt.$on('closeAssetDialog', () => {
      this.assetViewDialog = false
    })

    this.$nuxt.$on('close-send-proposal-dialog-pre-settlement', (sendAPI) => {
      this.openSendProposalConfirmation = false
      this.cpdUrl = null
      if (sendAPI) this.preSettleApplication()
    })

    this.$nuxt.$on(
      'close-send-proposal-dialog-pre-settlement-update',
      (sendAPI) => {
        this.isPreSettlementUpdate = false
        this.openSendProposalConfirmation = false
        this.cpdUrl = null
      }
    )

    if (this.loanData.appData.status === APPLICATION_STATUS_STARTED) {
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: 'loanDetails',
          subtab: 'submission',
        },
      })
      this.$nuxt.$emit('updateComponent', 'loanDetails|submission')
    }

    this.$nuxt.$on('show-pre-settlement-missing-document', (errorMsg) => {
      this.openSendProposalConfirmation = false
      this.cpdUrl = null
      this.ShouldDisplayMissingDocumentDialog =
        !this.ShouldDisplayMissingDocumentDialog
      this.errMessage = errorMsg
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('closeAssetDetailsDialog')
    this.$nuxt.$off('closeRequestloanDetailsDataDialog')
    this.$nuxt.$off('closeAssetDialog')
    this.$nuxt.$off('closeLenderDialog')
    this.$nuxt.$off('close-loader')
    this.$nuxt.$off('close-send-proposal-dialog-pre-settlement')
    this.$nuxt.$off('close-send-proposal-dialog-settlement')
  },
  methods: {
    checkTradeInLiability() {
      let remainingLiaArr = []
      remainingLiaArr =
        tradeInLiabilityCommon(
          this.sharedData?.tradeIn?._id,
          this.sharedData?.primaryApplicantData?.liabilities
        ) ?? []

      if (remainingLiaArr?.length)
        this.remainingLiability = remainingLiaArr[0].totalRemaining
    },
    viewAssetment(type) {
      const returnURL = this.loanData.appData.sharedDocuments?.find(
        (document) => document.purpose.toLowerCase() === type.toLowerCase()
      )?.url

      if (returnURL) window.open(getSignedURL(returnURL, this.$auth))
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    openRequestAssetDetail() {
      this.isAssetDetailDialogOpen = true
    },
    openLoanDetailsForms() {
      this.loanDetailsFormDialog = true
    },
    getMissingDocuments(errMessage) {
      let result = axiosErrorMessageExtractor(errMessage).match(
        this.REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING
      )[1]
      this.missingDocuments = result.replace(/(\[)|(\])/g, '').split(',')
    },
    async submit() {
      const { isApprovalIsSameAsSubmission, isPreSettlementIsSameAsApproval } =
        this.loanData.appData.loanDetails

      let isPreSettlementSameAsDecisionFlg = Boolean(
        isPreSettlementIsSameAsApproval
      )
        ? isPreSettlementIsSameAsApproval
        : false
      this.isDiffDialog = !(
        isApprovalIsSameAsSubmission && isPreSettlementSameAsDecisionFlg
      )

      if (
        this.loanData.appData.applicationType !==
        APPLICATION_REQUEST_TYPE_COMMERCIAL
      ) {
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        this.submitBtnLoading = true
        const { id, applicationId } = this.$route.params
        this.isDialogLoading = true
        await this.$store.dispatch('applications/generateProposal', {
          id,
          applicationId,
        })
      }
      this.isDialogLoading = false

      this.isPreSettlement = true
      this.isSettlement = false
      if (
        this.isDiffDialog &&
        this.loanData.appData.applicationType !==
          APPLICATION_REQUEST_TYPE_COMMERCIAL
      ) {
        this.openSendProposalConfirmation = true
      } else {
        this.preSettlementDailog = true
      }

      if (
        this.loanData.appData.applicationType !==
        APPLICATION_REQUEST_TYPE_COMMERCIAL
      ) {
        let returnURL = null
        this.loanData?.appData?.sharedDocuments?.forEach((doc) => {
          if (
            doc.purpose.toLowerCase() ===
            LABEL_CREDIT_PROPOSAL_DISCLOSURE.toLowerCase()
          ) {
            returnURL = doc.url
          }
        })
        this.cpdUrl = getSignedURL(returnURL, this.$auth)
        this.submitBtnLoading = false
      }
    },
    async preSettleApplication() {
      try {
        this.submitBtnLoading = true
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.loanDetailsForm?.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          this.submitBtnLoading = false
          return
        }
        this.isDialogLoading = true
        let loanDetails = { ...this.loanData.appData }
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        await this.$store.dispatch('applications/settlementApplication', {
          loanDetails,
          id,
          applicationId,
          preSettled: true,
        })
        if (this.isDiffDialog) {
          const uuID = Math.random().toString().slice(2, 11)
          sessionStorage.setItem('uniquePatchRequestID', uuID)
          await this.$store.dispatch('applications/generateProposal', {
            id,
            applicationId,
          })
          sessionStorage.setItem('uniquePatchRequestID', uuID)
          await this.$store.dispatch('applications/sendProposal', {
            id,
            applicationId,
          })
        }
        this.preSettlementDailog = false
        this.$nuxt.$emit('reset-settlement-data')
      } catch (err) {
        this.isDialogLoading = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
        this.submitBtnLoading = false
      }
    },
    submitProposal() {
      this.isSubmitProposal = true
    },
    async settleApplication() {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.loanDetailsForm?.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.isDialogLoading = true
        const loanDetails = { ...this.loanData.appData }
        await this.$store.dispatch('applications/settlementApplication', {
          loanDetails,
          id,
          applicationId,
          settled: true,
        })

        if (
          this.loanData.appData.loanDetails?.settlement
            ?.isPreSettlementSameAsSettlement === false &&
          !this.isCommercial
        ) {
          await this.$store.dispatch('applications/generateProposal', {
            id,
            applicationId,
            shouldNotifyTheError: false,
          })

          await this.$store.dispatch('applications/sendProposal', {
            id,
            applicationId,
          })
        }
      } catch (err) {
        if (
          axiosErrorMessageExtractor(err).match(
            this.REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING
          )
        ) {
          this.getMissingDocuments(err)
          this.isDialogLoading = false
          this.areRequiredDocsUploadedBeforeSettlement = false
        } else {
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
      } finally {
        this.isDialogLoading = false
      }
    },
    async redirectToDocuments() {
      if (this.isCommercial) {
        this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
        })
        return
      }
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: MAINTAB_DOCS_KEY,
        },
      })
      this.$nuxt.$emit('updateComponent', MAINTAB_DOCS_KEY)
    },
    async closeMissingDocDialogAndRedictToDocumentTab() {
      this.areRequiredDocsUploadedBeforeSettlement = true
      const { id, applicationId } = this.$route.params
      if (!this.isCommercial) {
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: MAINTAB_DOCS_KEY,
          },
        })
        this.$nuxt.$emit('updateComponent', MAINTAB_DOCS_KEY)
      } else {
        this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
        })
      }
    },
    openViewAssetDetail() {
      this.assetViewDialog = true
    },
    async getLenderDetails(id) {
      await this.$store.dispatch('applications/getAccountDetails', {
        id,
      })
    },
    async goBack() {
      const redirectTab = !this.sharedData.securedFinance
        ? SETTLEMENT_MENU_DOCUMENT_KEY
        : SETTLEMENT_MENU_SELLER_DETAILS_KEY
      this.$emit('sectionRedirect', redirectTab)
    },
    async goNext(subTab) {
      this.$emit('sectionRedirect', subTab)
    },
    shouldSendCpd() {
      try {
        if (
          this.isCommercial ||
          this.loanData.appData?.status !== APPLICATION_STATUS_PRE_SETTLEMENT
        ) {
          return false
        }
        if (
          this.loanData.appData?.loanDetails?.settlement
            ?.shouldResendCpdOnSettlement
        ) {
          this.isPreSettlement = false
          this.isSettlement = false
          this.isPreSettlementUpdate = true
          this.openSendProposalConfirmation = true
          let returnURL = null
          const document = this.loanData.appData.sharedDocuments.find(
            (docs) =>
              docs.purpose.toLowerCase() ===
              LABEL_CREDIT_PROPOSAL_DISCLOSURE.toLowerCase()
          )
          if (document) {
            returnURL = document.url
          }
          this.cpdUrl = getSignedURL(returnURL, this.$auth)
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    closeMissingDocDialog() {
      this.errMessage = ''
      this.ShouldDisplayMissingDocumentDialog = false
    },
    async reviewAndSendProposal(type) {
      try {
        this.isPreSettlement = false
        this.isSettlement = false
        this.isPreSettlementUpdate = true
        this.cpdUrl = null
        let returnURL = null
        const document = await this.loanData.appData.sharedDocuments.find(
          (docs) => docs.purpose.toLowerCase() === type.toLowerCase()
        )
        if (document) {
          returnURL = document.url
        }
        this.cpdUrl = await getSignedURL(returnURL, this.$auth)
        setTimeout(() => {
          this.openSendProposalConfirmation = true
        }, 1000)
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
