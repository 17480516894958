import { render, staticRenderFns } from "./AddSecondaryApplicantDialog.vue?vue&type=template&id=f134d78a&"
import script from "./AddSecondaryApplicantDialog.vue?vue&type=script&lang=js&"
export * from "./AddSecondaryApplicantDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDialog: require('/codebuild/output/src1661699396/src/cc-portal/components/AppDialog/index.vue').default})
