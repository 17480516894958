//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ERROR_MESSAGE_TRUSTEE_LAST_NAME, TITLE_FOR_OTHER } from '~/constants'
import {
  LOAN_WITHDRAWN_REASONS_OPTIONS,
  LOAN_WITHDRAWN_REASON_OTHER,
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
  DEFAULT_DOCUMENT_TYPES,
  LABEL_ADD_OTHER_DOCUMENT_TYPE,
  LABEL_DOCUMENT_TYPE_NAME,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_SHARED,
  LABEL_BUSINESS_DOCUMENTS,
  LABEL_DOCUMENT_SECONDARY,
  LABEL_SETTLEMENT_PRIMARY,
  LABEL_SETTLEMENT_SECONDARY,
  LABEL_SETTLEMENT_OTHER,
  DEFAULT_SETTLEMENT_DOCUMENT_TYPES,
  DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES,
} from '~/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { mapGetters } from 'vuex'
import {
  getMappedNameWithPurpose,
  getValidDocumentPostfix,
} from '~/helpers/application'

export default {
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    decisionData: {
      type: Object,
      default: () => ({}),
    },
    reason: {
      type: String,
      default: () => null,
    },
    documentName: {
      type: String,
      default: () => null,
    },
    documentPurpose: {
      type: String,
      default: () => null,
    },
    documentExtraName: {
      type: String,
      default: () => null,
    },
    dialogFor: {
      type: String,
      default: () => null,
    },
    primaryApplicantData: {
      type: Object,
      default: () => null,
    },
    documentData: {
      type: Array,
      default: () => [],
    },
    secondaryIndex: {
      type: Number,
      default: 0,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: false,
    },
    optionForDocument: {
      type: String,
      default: null,
    },
    primaryDocumentData: {
      type: Array,
      default: () => [],
    },
    sharedDocumentData: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  computed: {
    ...mapGetters('applications', [
      'getApplication',
      'getPrimaryApplicantData',
      'getOtherApplicants',
    ]),
    selectedDocuments() {
      const { dialogFor } = this
      let sourceObj = this.getApplication
      if (dialogFor === LABEL_DOCUMENT_PRIMARY) {
        sourceObj = this.getPrimaryApplicantData
      } else if (dialogFor === LABEL_DOCUMENT_SECONDARY) {
        sourceObj = this.getOtherApplicants[this.secondaryIndex]
      }
      return sourceObj.selectedDocuments
    },
    titlePurpose() {
      if (this.documentName === TITLE_FOR_OTHER) {
        return this.documentExtraName
      } else {
        return (
          DEFAULT_DOCUMENT_TYPES.find(
            ({ value }) => this.documentName === value
          )?.key || this.documentName
        )
      }
    },
    checkSettlementCondtition() {
      return [
        LABEL_SETTLEMENT_SECONDARY,
        LABEL_SETTLEMENT_PRIMARY,
        LABEL_SETTLEMENT_OTHER,
      ].includes(this.optionForDocument)
    },
    getAvailableDocs() {
      const docs = this.isFromSettlementTab
        ? this.checkSettlementCondtition
          ? this.DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES
          : this.DEFAULT_SETTLEMENT_DOCUMENT_TYPES
        : this.DEFAULT_DOCUMENT_TYPES
      return docs
    },
    documentTypeOptions() {
      const docs = this.getAvailableDocs
      return docs
        .filter(({ value }) => value !== this.documentName)
        .sort((a, b) => a.key.localeCompare(b.key))
    },
  },
  data() {
    return {
      changeDocumentTypeForm: {
        documentNewName: null,
        documentNewPurpose: null,
        addOtherDocumentName: null,
        addOtherDocument: false,
      },

      getIsDialogLoading: false,
      LOAN_WITHDRAWN_REASONS_OPTIONS,
      LOAN_WITHDRAWN_REASON_OTHER,
      getSelectedOutcomeOption: null,
      rules: {
        documentNewName: [
          {
            required: true,
            message: 'Document type is required',
            trigger: ['blur', 'change'],
          },
        ],
        addOtherDocumentName: [
          {
            required: true,
            message: 'Document name is required',
            trigger: ['blur', 'change'],
          },
        ],
      },
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
      DEFAULT_DOCUMENT_TYPES,
      LABEL_ADD_OTHER_DOCUMENT_TYPE,
      LABEL_DOCUMENT_TYPE_NAME,
      addOtherDocument: false,
      ERROR_MESSAGE_TRUSTEE_LAST_NAME,
      TITLE_FOR_OTHER,
      LABEL_SETTLEMENT_PRIMARY,
      LABEL_SETTLEMENT_SECONDARY,
      LABEL_SETTLEMENT_OTHER,
      DEFAULT_SETTLEMENT_DOCUMENT_TYPES,
      DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES,
    }
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('close-change-document-type-dialog')
      this.changeDocumentTypeForm = {
        documentNewName: null,
        documentNewPurpose: null,
        addOtherDocumentName: null,
      }
      this.addOtherDocument = false
    },

    handleChange() {
      this.changeDocumentTypeForm.documentNewPurpose =
        this.changeDocumentTypeForm.documentNewName
      this.changeDocumentTypeForm.documentNewName = getMappedNameWithPurpose(
        this.changeDocumentTypeForm.documentNewPurpose
      )
      if (
        [
          LABEL_DOCUMENT_PRIMARY,
          LABEL_SHARED,
          LABEL_BUSINESS_DOCUMENTS,
        ].includes(this.dialogFor)
      ) {
        let count = 0

        const dataObj = this.primaryDocumentData.filter(
          (x) => x.purpose === this.changeDocumentTypeForm.documentNewPurpose
        )
        count = count ? count : dataObj.length
        count = getValidDocumentPostfix(dataObj, count)

        const dataObj2 = this.sharedDocumentData.filter(
          (x) => x.purpose === this.changeDocumentTypeForm.documentNewPurpose
        )
        count = count ? count : dataObj2.length
        count = getValidDocumentPostfix(dataObj2, count)

        if (count) {
          this.changeDocumentTypeForm.documentNewName =
            this.changeDocumentTypeForm.documentNewName + ' (' + count + ')'
        }
      }
    },
    async submitForm() {
      try {
        this.getIsDialogLoading = true
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.changeDocumentTypeForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          this.getIsDialogLoading = false
          return
        }

        let newDocumentName = ''
        if (this.addOtherDocument) {
          newDocumentName = TITLE_FOR_OTHER
        } else {
          newDocumentName = this.changeDocumentTypeForm.documentNewPurpose
        }

        if (newDocumentName) {
          this.documentData.forEach((doc) => {
            if (
              !doc.deletedAt &&
              (doc.name === this.documentExtraName ||
                doc.purpose === this.documentPurpose)
            ) {
              doc.purpose = newDocumentName
              if (newDocumentName === TITLE_FOR_OTHER) {
                doc.name = this.changeDocumentTypeForm.addOtherDocumentName
              } else {
                delete doc.name
              }
            }
          })
        }

        let newDocType = {
          purpose: newDocumentName,
          name:
            newDocumentName === TITLE_FOR_OTHER
              ? this.changeDocumentTypeForm.addOtherDocumentName
              : this.changeDocumentTypeForm.documentNewName,
          requestedAt: new Date().toISOString(),
        }

        this.selectedDocuments.forEach((selectedDocument) => {
          if (
            !selectedDocument.deletedAt &&
            this.documentPurpose === TITLE_FOR_OTHER &&
            selectedDocument.name === this.documentExtraName
          ) {
            newDocType = {
              ...selectedDocument,
              ...newDocType,
            }
          } else if (
            !selectedDocument.deletedAt &&
            this.documentPurpose !== TITLE_FOR_OTHER &&
            selectedDocument.purpose === this.documentPurpose
          ) {
            newDocType = {
              ...selectedDocument,
              ...newDocType,
            }
          }
        })

        const selectedDocuments = this.selectedDocuments.filter(
          (selectedDocument) => {
            if (this.documentPurpose === TITLE_FOR_OTHER) {
              return selectedDocument.name !== this.documentExtraName
            } else {
              return selectedDocument.purpose !== this.documentPurpose
            }
          }
        )

        const isNewDocTypeAlreadyAdded = selectedDocuments.some(
          (selectedDocument) => {
            if (!selectedDocument.deletedAt) {
              if (newDocType.purpose === TITLE_FOR_OTHER) {
                return (
                  selectedDocument.purpose === newDocType.purpose &&
                  selectedDocument.name === newDocType.name
                )
              }

              return selectedDocument.purpose === newDocType.purpose
            }
          }
        )

        if (!isNewDocTypeAlreadyAdded) {
          selectedDocuments.push(newDocType)
        }

        await this.$store.dispatch('applications/setDocumentsData', {
          documentData: this.documentData,
          id,
          applicationId,
          secondaryIndex: this.secondaryIndex,
          type: this.dialogFor,
          selectedDocuments,
        })

        this.closeDialog()
        this.getIsDialogLoading = false
      } catch (err) {
        this.closeDialog()
        this.getIsDialogLoading = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
