//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_UNIT_STREET,
  ERROR_MESSAGE_FOR_SUBURB,
  ERROR_MESSAGE_FOR_POSTCODE,
  ERROR_MESSAGE_FOR_COUNTRY,
  ERROR_MESSAGE_FOR_TIME_AT_YEARS,
  ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
  ERROR_MESSAGE_FOR_LIVING_SITUATION,
  ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
  ERROR_MESSAGE_FOR_CITY,
  ERROR_MESSAGE_FOR_CITY_LENGTH,
  ERROR_MESSAGE_FOR_STATE,
  ERROR_MESSAGE_FOR_NUMBER_LENGTH,
  ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
  TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL,
  LABEL_FOR_PRIVACY_FORM,
  LABEL_FOR_NOTES,
  PLACEHOLDER_WRITE_ANY_NOTES,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  LABEL_FOR_DOCUSIGN_CERT,
  LABEL_FOR_PRIVACY_GUIDE,
} from '@/constants'
import { isPostcodeValid } from '@/helpers/validators'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import {
  APPLICANT_STATE_OPTIONS,
  LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
  YEAR_OPTIONS,
  EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
  MONTH_OPTIONS,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  SUBTAB_ASSETS_AND_LIABILITIES_KEY,
  SUBTAB_PRIMARY_PERSONAL_KEY,
  DOCUMENTS_SIGNED_AT,
  DOCUMENTS_SENT_AT,
  LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_DESCRIPTION_COMMERCIAL,
  LABEL_SEND,
  LABEL_AUSTRALIA,
  LABEL_RENTING_THROUGH_AGENT,
  LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE,
  LIVING_SITUATION_TENANT_NO_MORTGAGE,
} from '@/constants/applications'
import { getSignedURL } from '~/utils/url'
import { COUNTRIES } from '@/constants/countries'
import { changeRoute } from '~/helpers/changeRoute'
import { doesAddressHaveCompleteMeta } from '~/helpers/application'
import cloneDeep from 'lodash/cloneDeep'
import { format } from 'date-fns'
import { stateValidation, setState } from '~/helpers/address'
import { axiosErrorMessageExtractor } from '~/utils/error'
export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    secondaryApplicant: {
      type: Object,
      default: null,
    },
    accountDetails: {
      type: Array,
      default: null,
    },
    vehicleRequestGroup: {
      type: Object,
      default: null,
    },
    sharedData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      format,
      setState,
      stateValidation,
      applicationData: {},
      doesAddressHaveCompleteMeta,
      APPLICANT_STATE_OPTIONS,
      LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
      ERROR_MESSAGE_FOR_STATE,
      ERROR_MESSAGE_FOR_UNIT_STREET,
      ERROR_MESSAGE_FOR_SUBURB,
      ERROR_MESSAGE_FOR_POSTCODE,
      ERROR_MESSAGE_FOR_COUNTRY,
      ERROR_MESSAGE_FOR_TIME_AT_YEARS,
      ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
      ERROR_MESSAGE_FOR_LIVING_SITUATION,
      ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
      ERROR_MESSAGE_FOR_CITY,
      EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
      ERROR_MESSAGE_FOR_CITY_LENGTH,
      ERROR_MESSAGE_FOR_NUMBER_LENGTH,
      ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      LABEL_RENTING_THROUGH_AGENT,
      LABEL_FOR_NOTES,
      LABEL_AUSTRALIA,
      LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE,
      LIVING_SITUATION_TENANT_NO_MORTGAGE,
      PLACEHOLDER_WRITE_ANY_NOTES,
      SUBTAB_PRIMARY_PERSONAL_KEY,
      FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
      TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL,
      DOCUMENTS_SIGNED_AT,
      DOCUMENTS_SENT_AT,
      LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_DESCRIPTION_COMMERCIAL,
      LABEL_SEND,
      LABEL_FOR_PRIVACY_FORM,
      LABEL_FOR_PRIVACY_GUIDE,
      LABEL_FOR_DOCUSIGN_CERT,
      YEAR_OPTIONS,
      addressesData: null,
      googleSelected: false,
      COUNTRIES,
      isDefaultAddress: false,
      getIsDialogLoading: false,
      addresses: [],
      rules: {
        addressLine1: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_UNIT_STREET,
            trigger: ['blur', 'change'],
          },
          {
            min: 3,
            max: 60,
            message: ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
        city: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_CITY,
            trigger: ['blur', 'change'],
          },
          {
            min: 3,
            max: 30,
            message: ERROR_MESSAGE_FOR_CITY_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
        state: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATE,
            trigger: ['blur', 'change'],
          },
        ],
        postcode: [
          {
            required: true,
            /* eslint-disable */
            validator: (rule, value, callback) => {
              if (!value || (value && !isPostcodeValid(value))) {
                callback(new Error(ERROR_MESSAGE_FOR_POSTCODE))
              } else {
                callback()
              }
            },
            /* eslint-enable */
            message: ERROR_MESSAGE_FOR_POSTCODE,
            trigger: ['blur', 'change'],
          },
        ],
        atYears: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TIME_AT_YEARS,
            trigger: ['blur', 'change'],
          },
        ],
        atAdditionalMonths: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
            trigger: ['blur', 'change'],
          },
        ],
        livingSituation: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LIVING_SITUATION,
            trigger: ['blur', 'change'],
          },
        ],
      },
      secondaryApplicantIndex: 0,
      isPrimary: true,
    }
  },
  computed: {
    isComplianceSignedDocPresent() {
      const { sharedDocuments } = this.sharedData
      if (!sharedDocuments.length) {
        return false
      }
      return !!sharedDocuments.filter(
        (doc) =>
          doc.purpose.toLowerCase() === LABEL_FOR_PRIVACY_FORM.toLowerCase()
      ).length
    },
    isDocusignCertPresent() {
      const { sharedDocuments } = this.sharedData
      return !!sharedDocuments.filter(
        (doc) =>
          doc.purpose.toLowerCase() === LABEL_FOR_DOCUSIGN_CERT.toLowerCase()
      ).length
    },
    minimumResidency() {
      return (
        this.addressesData?.addresses?.filter((item) => item.atYears < 3) ?? []
      )
    },
    queryParam() {
      return this.$route.query.subtab
    },
    /* eslint-disable */
    addressForm() {
      const { subtab: subTab } = this.$route.query
      this.isPrimary = subTab?.indexOf('primary_') !== -1
      if (this.$route.query.secondsubtab || !this.isPrimary) {
        if (this.secondaryData.length) {
          this.addressesData = {
            ...this.secondaryData[this.secondaryApplicantIndex],
          }

          return { ...this.secondaryData[this.secondaryApplicantIndex] }
        }
      } else {
        this.addressesData = JSON.parse(JSON.stringify(this.primaryData))

        return { ...this.primaryData }
      }
    },
    /* eslint-enable */
    dateRange() {
      return { YEAR_OPTIONS, MONTH_OPTIONS }
    },
    totalMonths() {
      return this.addressesData?.addresses
        ? this.addressesData?.addresses
            ?.reduce((totalMonths, curr) => {
              const years = (curr.atYears || 0) * 12
              const months = curr.atAdditionalMonths || 0
              totalMonths += years + months
              return totalMonths
            }, 0)
            .toFixed(2) ?? 0
        : 0
    },
    primaryData() {
      return this.applicationDetails.primaryApplicantData
    },
    secondaryData() {
      return this.applicationDetails.otherApplicants
    },
  },
  watch: {
    queryParam() {
      this.updateAddressData()
    },
  },
  mounted() {
    this.updateAddressData()
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    checklivingSituation(livingSituation) {
      return [
        LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE,
        LIVING_SITUATION_TENANT_NO_MORTGAGE,
      ].includes(livingSituation)
    },
    sortAddressArr() {
      if (this.addressesData?.addresses?.length > 1) {
        this.addressesData.addresses = [...this.addressesData.addresses].sort(
          (a, b) => Number(b.isCurrent) - Number(a.isCurrent)
        )
      }
    },
    updateAddressData() {
      const { subtab: subTab } = this.$route.query
      this.isPrimary = subTab?.indexOf('primary_') !== -1
      this.secondaryApplicantIndex = this.primary
        ? 0
        : parseInt(subTab?.split('_')[1] - 2) ?? 0

      this.addressesData =
        this.$route.query.secondsubtab || !this.isPrimary
          ? { ...cloneDeep(this.secondaryData[this.secondaryApplicantIndex]) }
          : { ...cloneDeep(this.primaryData) }
      setTimeout(() => {
        this.isDefaultAddress = true
        this.sortAddressArr()
      }, 160)
      this.setDefaultCountry()
    },
    handleDocumentsUrl(purpose) {
      const { sharedDocuments } = this.sharedData
      if (sharedDocuments && sharedDocuments.length) {
        let redirectUrl =
          sharedDocuments.find(
            (doc) => doc.purpose.toLowerCase() === purpose.toLowerCase()
          )?.url || ''
        if (redirectUrl && redirectUrl.length) {
          redirectUrl = getSignedURL(redirectUrl, this.$auth)
          window.open(redirectUrl, '_blank')
        }
      }
    },
    setDefaultCountry() {
      if (this.addressesData) {
        this.addressesData.addresses = this.addressesData?.addresses?.map(
          (address) => {
            if (!address.country.length) {
              address.country = LABEL_AUSTRALIA
            }
            return address
          }
        )
      }
    },
    async sendESignEmailRequest() {
      try {
        const { primaryApplicant } = this.sharedData.primaryApplicantData
        const applicantId = this.isPrimary
          ? primaryApplicant?._id
            ? primaryApplicant?._id
            : primaryApplicant
          : this.sharedData?.otherApplicants[this.secondaryApplicantIndex]
              ?.applicant?._id ||
            this.sharedData?.otherApplicants[this.secondaryApplicantIndex]
              ?.applicant

        this.getIsDialogLoading = true
        const { id, applicationId } = this.$route.params
        await this.$store.dispatch('applications/eSignRequestEmail', {
          id,
          applicationId,
          applicantId,
        })
        await this.$store.dispatch('applications/fetchApplications', {
          id,
          applicationId,
        })
        this.getIsDialogLoading = false
      } catch (err) {
        this.getIsDialogLoading = false
        console.log(err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    countrySearch(queryString, cb) {
      const links = this.COUNTRIES
      const results = queryString
        ? links.filter(this.createFilter(queryString))
        : links
      // call callback function to return suggestions
      cb(results)
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().includes(queryString.toLowerCase()) !== -1
        )
      }
    },
    /* eslint-disable */
    getAddressData: function (addressData, index) {
      if (addressData) {
        this.$set(
          this.addressesData.addresses[index],
          'address',
          `${addressData.route}, ${addressData.locality} ${addressData.administrative_area_level_1} ${addressData.postal_code}`
        )
        this.$set(
          this.addressesData.addresses[index],
          'addressLine1',
          `${addressData.street_number ? addressData.street_number : ''} ${
            addressData.route
          }`
        )
        this.$set(
          this.addressesData.addresses[index],
          'city',
          addressData.locality
        )
        this.$set(
          this.addressesData.addresses[index],
          'state',
          addressData.administrative_area_level_1
        )
        this.$set(
          this.addressesData.addresses[index],
          'postcode',
          addressData.postal_code
        )
        this.$set(
          this.addressesData.addresses[index],
          'country',
          addressData.country
        )
        this.$set(
          this.addressesData.addresses[index],
          'streetName',
          addressData.route
        )
        if (addressData?.street_number) {
          let data =
            addressData.street_number.includes('/') &&
            addressData.street_number.includes('-')
              ? addressData.street_number.replace(/[/]/g, ' ')
              : addressData.street_number.replace(/[-/]/g, ' ')
          data = data.split(/[\s]/).reverse()
          this.$set(
            this.addressesData.addresses[index],
            'streetNumber',
            data[0] || ''
          )
          this.$set(
            this.addressesData.addresses[index],
            'unitNumber',
            data[1] && /^\d+$/.test(data[1]) ? data[1] : ''
          )
        }
      }
    },
    removeAddress(index) {
      this.addressesData.addresses.splice(index, 1)
    },
    addAddress() {
      if (this.$refs.addressesData) {
        this.isDefaultAddress = true
        let isValid = false
        this.$refs.addressesData.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
      }
      this.addressesData.addresses = [
        ...this.addressesData.addresses,
        {
          country: 'Australia',
          isCurrent: false,
          address: '',
          atYears: null,
          atAdditionalMonths: null,
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postcode: '',
          livingSituation: '',
          areYouRentingThroughAgent: true,
        },
      ]
      this.setDefaultCountry()
    },
    submit(isRedirect) {
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.addressesData.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      this.getIsDialogLoading = true

      this.updateCurrent()
      if (this.$route.query.secondsubtab || !this.isPrimary) {
        this.updateData(
          'updateSecondaryApplication',
          {
            applicant: this.addressesData,
            id,
            applicationId,
            index: this.secondaryApplicantIndex,
          },
          'updateSubComponent',
          isRedirect
        )
      } else {
        this.updateData(
          'updateApplication',
          {
            primaryApplicant: this.addressesData,
            id,
            applicationId,
          },
          'updateComponent',
          isRedirect
        )
      }
    },
    updateCurrent() {
      this.addressesData.addresses[0].isCurrent = true
    },
    async updateData(actionName, dataObj, updateEvent, isRedirect) {
      const { id, applicationId } = this.$route.params
      this.$store
        .dispatch(`applications/${actionName}`, dataObj)
        .then((res) => {
          if (!res) {
            this.getIsDialogLoading = false
          }
        })
      if (isRedirect) {
        this.$nuxt.$emit('changeContactsTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
          subTab: `${
            this.isPrimary
              ? 'primary_' + SUBTAB_ASSETS_AND_LIABILITIES_KEY
              : 'secondary_' +
                this.secondaryApplicantIndex +
                '_' +
                SUBTAB_ASSETS_AND_LIABILITIES_KEY
          }`,
          isPrimary: this.isPrimary,
          index: this.secondaryApplicantIndex,
        })
      }
    },
    async back() {
      if (this.$route.query.secondsubtab) {
        await changeRoute({
          route: this.$route,
          router: this.$router,
          query: {
            ...this.$route.query,
            secondsubtab: `${
              this.isPrimary
                ? 'primary_' + SUBTAB_PRIMARY_PERSONAL_KEY
                : 'secondary_' +
                  this.secondaryApplicantIndex +
                  '_' +
                  SUBTAB_PRIMARY_PERSONAL_KEY
            }`,
            isPrimary: this.isPrimary,
            index: this.secondaryApplicantIndex,
          },
        })
      } else {
        await changeRoute({
          route: this.$route,
          router: this.$router,
          query: {
            ...this.$route.query,
            subTab: `${
              this.isPrimary
                ? 'primary_' + SUBTAB_PRIMARY_PERSONAL_KEY
                : 'secondary_' +
                  this.secondaryApplicantIndex +
                  '_' +
                  SUBTAB_PRIMARY_PERSONAL_KEY
            }`,
            isPrimary: this.isPrimary,
            index: this.secondaryApplicantIndex,
          },
        })
        this.$nuxt.$emit('changeContactsTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
          subTab: `${
            this.isPrimary
              ? 'primary_' + SUBTAB_PRIMARY_PERSONAL_KEY
              : 'secondary_' +
                this.secondaryApplicantIndex +
                '_' +
                SUBTAB_PRIMARY_PERSONAL_KEY
          }`,
          isPrimary: this.isPrimary,
          index: this.secondaryApplicantIndex,
        })
      }
    },
    async setCountry(addressData) {
      this.isDefaultAddress = false
      await setState(addressData)
      this.isDefaultAddress = true
    },
  },
}
