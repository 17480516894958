//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ZeroConfirmationDialog from './ZeroConfirmationDialog.vue'

import {
  LABEL_LOAN_DETAILS,
  LABEL_FOR_RATES_FEES,
  LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
  LABEL_TRADE_IN_EQUITY,
  LABEL_DOCUMENT_DEPOSIT,
  LABEL_FOR_NAF,
  LABEL_DOCUMENT_BALLOON,
  LABEL_LOAN_BALLOON_DOLLAR,
  LABEL_LOAN_TREM,
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_FOR_PROVIDER,
  LABEL_FOR_PRODUCT,
  LABEL_FOR_WHOLESALE,
  LABEL_FOR_RETAIL,
  LABEL_FOR_COMMISSION,
  LABEL_DOCUMENT_LOAN_AMOUNT,
  LABEL_BASE_RATE,
  LABEL_MONTHLY_REPAYMENT_AMOUNT,
  LABEL_CUSTOMER_RATE,
  LABEL_LENDER_MONTHLY_FEE,
  LABEL_LENDER_FEE,
  LABEL_PSPR_FEE,
  LABEL_ORIGINATION_FEE,
  LABEL_PARTNER_REFERRAL_FEE,
  LABEL_LENDING_COMMISSION,
  LABEL_UPDATE,
  APPLICATION_TRADE_IN_VEHICLE,
  LABEL_DOCUMENT_ESTIMETED_PRICE,
  LABEL_TRADING_LIABILITIES,
  LABEL_NAF,
  LABEL_LENDING_COMMISSION_FEE,
  MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
  LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
  LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
} from '@/constants/applications'
import {
  ERROR_MESSAGE_BASE_RATE,
  ERROR_MESSAGE_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_CUSTOMER_RATE,
  ERROR_MESSAGE_ACCOUNT_KEEPING_FEE,
  ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE,
  ERROR_MESSAGE_PSPR_FEE,
  ERROR_MESSAGE_BROKER_FEE,
  ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE,
  ERROR_MESSAGE_LENDING_COMMISION_FEE,
  ERROR_MESSAGE_FOR_PURCHASE_PRICE,
  ERROR_MESSAGE_FOR_DEPOSIT,
  ERROR_MESSAGE_FOR_TRADE_IN,
  ERROR_MESSAGE_FOR_LOAN_TERM,
  ERROR_MESSAGE_FOR_BALLOON,
  ERROR_MESSAGE_FOR_BALLOON_ONLY,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
} from '@/constants'
import {
  validatePositiveNumber,
  validatePercentageWithGreaterThanZero,
  validateBalloonPercentage,
  checkAmount,
  validateAmountShouldGreaterThanZero,
  validateBrokerFee,
} from '@/helpers/validators'
import { toCurrency } from '@/helpers/currency'
import { tradeInLiabilityCommon } from '@/helpers/tradeInLiabilityCommon'

import { axiosErrorMessageExtractor } from '~/utils/error'
import { scrollToElement } from '~/utils/scroll'
import { getBalloonAmountWithApi } from '@/helpers/calculateBalloonAmount'
import { getBalloonPctWithApi } from '@/helpers/calculateBalloonPct'
export default {
  name: 'RequestAssetDetails',
  components: {
    ZeroConfirmationDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const checkPurchasePrice = (_rule, value, callback) =>
      checkAmount(_rule, value, callback)
    const checkFinanceDepositAmount = (_rule, value, callback) =>
      checkAmount(
        _rule,
        value,
        callback,
        this.loanDetailsData.loanDetails.settlement.purchasePrice
      )

    const checkNumberValue = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)

    const checkNumberShouldBeGreaterThanZero = (_rule, value, callback) =>
      validateAmountShouldGreaterThanZero(value, callback)

    return {
      LABEL_TRADING_LIABILITIES,
      LABEL_LOAN_DETAILS,
      LABEL_FOR_RATES_FEES,
      LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      LABEL_TRADE_IN_EQUITY,
      LABEL_DOCUMENT_DEPOSIT,
      LABEL_FOR_NAF,
      LABEL_DOCUMENT_BALLOON,
      LABEL_LOAN_BALLOON_DOLLAR,
      LABEL_LOAN_TREM,
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_FOR_PROVIDER,
      LABEL_NAF,
      LABEL_FOR_PRODUCT,
      LABEL_FOR_WHOLESALE,
      LABEL_FOR_RETAIL,
      LABEL_FOR_COMMISSION,
      LABEL_DOCUMENT_LOAN_AMOUNT,
      ERROR_MESSAGE_BASE_RATE,
      ERROR_MESSAGE_MONTHLY_REPAYMENT,
      ERROR_MESSAGE_CUSTOMER_RATE,
      ERROR_MESSAGE_ACCOUNT_KEEPING_FEE,
      ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE,
      ERROR_MESSAGE_PSPR_FEE,
      ERROR_MESSAGE_BROKER_FEE,
      ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE,
      ERROR_MESSAGE_LENDING_COMMISION_FEE,
      ERROR_MESSAGE_FOR_PURCHASE_PRICE,
      ERROR_MESSAGE_FOR_DEPOSIT,
      ERROR_MESSAGE_FOR_TRADE_IN,
      ERROR_MESSAGE_FOR_LOAN_TERM,
      ERROR_MESSAGE_FOR_BALLOON,
      ERROR_MESSAGE_FOR_BALLOON_ONLY,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      LABEL_BASE_RATE,
      LABEL_MONTHLY_REPAYMENT_AMOUNT,
      LABEL_CUSTOMER_RATE,
      LABEL_LENDER_MONTHLY_FEE,
      LABEL_LENDER_FEE,
      LABEL_PSPR_FEE,
      LABEL_ORIGINATION_FEE,
      LABEL_PARTNER_REFERRAL_FEE,
      LABEL_LENDING_COMMISSION,
      LABEL_UPDATE,
      APPLICATION_TRADE_IN_VEHICLE,
      ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
      LABEL_DOCUMENT_ESTIMETED_PRICE,
      loanDetailsData: { ...this.sharedData },
      rules: {
        'loanDetails.settlement.purchasePrice': [
          {
            required: true,
            validator: checkPurchasePrice,
            trigger: ['blur', 'change'],
          },
        ],
        loanAmount: [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.depositAmount': [
          {
            required: true,
            validator: checkFinanceDepositAmount,
            trigger: ['blur', 'change'],
          },
        ],
        'tradeIn.tradeInAmount': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.loanTermYears': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LOAN_TERM,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.balloonPct': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validateBalloonPercentage(
                value,
                callback,
                this.loanDetailsData.loanDetails.settlement.loanTermYears,
                ERROR_MESSAGE_FOR_BALLOON
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.balloonAmount': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.baseRate': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validatePercentageWithGreaterThanZero(
                value,
                callback,
                ERROR_MESSAGE_BASE_RATE
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.customerRate': [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validatePercentageWithGreaterThanZero(
                value,
                callback,
                ERROR_MESSAGE_CUSTOMER_RATE
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.monthlyRepayment': [
          {
            required: true,
            validator: checkNumberShouldBeGreaterThanZero,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.accountKeepingFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.lenderEstablishmentFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.ppsrFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.brokerFee': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              validateBrokerFee(value, callback)
            },
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.lendingCommision': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.netAmountFinanced': [
          {
            required: true,
            validator: checkNumberShouldBeGreaterThanZero,
            trigger: ['blur', 'change'],
          },
        ],
      },
      getIsDialogLoading: false,
      remainingLiability: 0,
      LABEL_LENDING_COMMISSION_FEE,
      MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
      LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
      LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
      openZeroConfirmationDialog: false,
      allZeroValueFieldsData: [],
      triggerValidation: false,
      customDebounce: null,
      btnLoading: false,
    }
  },
  mounted() {
    this.checkTradeInLiability()
    this.$nuxt.$on('submit-pre-settlement', (data) => this.submitForm(data))
    this.$nuxt.$on(
      'close-zero-confirmation-dialog-pre-settlement',
      () => (this.openZeroConfirmationDialog = false)
    )
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
      this.closeDialog()
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('submit-pre-settlement')
    this.$nuxt.$off('close-zero-confirmation-dialog-pre-settlement')
    this.$nuxt.$off('close-loader')
  },
  computed: {
    loanDetailsForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loanDetailsData = JSON.parse(JSON.stringify(this.sharedData))
      return { ...this.sharedData }
    },
    loanAmountTotal() {
      const { purchasePrice, depositAmount } =
        this.loanDetailsData.loanDetails.settlement
      const { tradeInAmount } = this.loanDetailsData.tradeIn ?? {}
      return (purchasePrice || 0) - (tradeInAmount || 0) - (depositAmount || 0)
    },
  },
  watch: {
    openDialog() {
      setTimeout(() => {
        this.triggerValidation = true
      }, 2000)
    },
  },
  methods: {
    onInput(field) {
      if (this.triggerValidation) {
        let isValid = false
        this.$refs.loanDetailsData.validateField(field, (result) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return (isValid = result)
        })
      }
    },
    async checkZeroValFields() {
      let isValid = false
      await this.$refs?.loanDetailsData?.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        const nodes = document.querySelectorAll('.laonSettlementPoup .is-error')
        if (nodes && nodes.length > 0 && nodes[0]) {
          this.$nextTick(() => {
            scrollToElement(nodes[0])
          })
        }
        return
      }

      this.allZeroValueFieldsData = []
      let { brokerFee, lendingCommision, lenderEstablishmentFee } =
        this.loanDetailsData?.loanDetails?.settlement
      this.checkZeroVal(brokerFee, LABEL_ORIGINATION_FEE)
      this.checkZeroVal(lendingCommision, LABEL_LENDING_COMMISSION_FEE)
      this.checkZeroVal(lenderEstablishmentFee, LABEL_LENDER_FEE)
      if (this.allZeroValueFieldsData.length) {
        this.openZeroConfirmationDialog = true
      } else {
        this.submitForm()
      }
    },
    checkZeroVal(val, type) {
      if (parseFloat(val) <= 0 || isNaN(parseFloat(val))) {
        this.allZeroValueFieldsData.push(type)
      }
    },
    checkTradeInLiability() {
      let remainingLiaArr = []
      remainingLiaArr =
        tradeInLiabilityCommon(
          this.loanDetailsData?.tradeIn?._id,
          this.loanDetailsData?.primaryApplicantData?.liabilities
        ) ?? []

      if (remainingLiaArr?.length)
        this.remainingLiability = remainingLiaArr[0].totalRemaining
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    closeDialog() {
      this.$nuxt.$emit('closeRequestloanDetailsDataDialog', false)
    },

    async calculateBallonAmount() {
      const { settlement } = this.loanDetailsData.loanDetails
      this.btnLoading = true
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        settlement.balloonAmount =
          (await getBalloonAmountWithApi(
            settlement?.purchasePrice || 0,
            settlement?.balloonPct || 0
          )) || 0
        this.btnLoading = false
      }, 600)
    },
    calculateBallonPct() {
      const { settlement } = this.loanDetailsData.loanDetails
      this.btnLoading = true
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        settlement.balloonPct =
          (await getBalloonPctWithApi(
            settlement?.purchasePrice || 0,
            settlement?.balloonAmount || 0
          )) || 0
        this.btnLoading = false
      }, 600)
    },
    async submitForm(data) {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        await this.$refs?.loanDetailsData?.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          const nodes = document.querySelectorAll(
            '.laonSettlementPoup .is-error'
          )
          if (nodes && nodes.length > 0 && nodes[0]) {
            this.$nextTick(() => {
              scrollToElement(nodes[0])
            })
          }
          return
        }

        this.getIsDialogLoading = true
        this.loanDetailsData.loanDetails.settlement.balloonPct = parseFloat(
          this.loanDetailsData.loanDetails.settlement.balloonPct
        ).toFixed(2)
        this.loanDetailsData.loanDetails.settlement.balloonAmount = parseFloat(
          this.loanDetailsData.loanDetails.settlement.balloonAmount
        ).toFixed(2)
        this.loanDetailsData.loanDetails.settlement.baseRate = parseFloat(
          this.loanDetailsData.loanDetails.settlement.baseRate
        ).toFixed(2)
        this.loanDetailsData.loanDetails.settlement.customerRate = parseFloat(
          this.loanDetailsData.loanDetails.settlement.customerRate
        ).toFixed(2)

        const fieldsToCompare = [
          'accountKeepingFee',
          'balloonAmount',
          'balloonPct',
          'baseRate',
          'brokerFee',
          'customerRate',
          'depositAmount',
          'lenderEstablishmentFee',
          'lendingCommision',
          'loanAmount',
          'loanTermYears',
          'monthlyRepayment',
          'partnerFinanceReferralFee',
          'ppsrFee',
          'purchasePrice',
        ]

        this.loanDetailsData.loanDetails.settlement.isPreSettlementSameAsSettlement =
          !fieldsToCompare.some((compareKey) => {
            return (
              parseFloat(
                this.loanDetailsData.loanDetails.settlement[compareKey]
              ) !==
              parseFloat(this.sharedData.loanDetails.settlement[compareKey])
            )
          })

        if (data) {
          this.loanDetailsData.loanDetails.submission.zeroValueReason =
            data?.zeroValueReason
          this.loanDetailsData.loanDetails.submission.zeroValueReasonType =
            data?.zeroValueReasonType
        }
        await this.$store.dispatch('applications/updateLoanDetailsSettlement', {
          loanDetails: { ...this.loanDetailsData },
          id,
          applicationId,
        })
        this.$nuxt.$emit('closeRequestloanDetailsDataDialog', true)
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.getIsDialogLoading = false
      }
    },
  },
}
