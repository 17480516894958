//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_PASSWORD,
  USER_SESSION_DATA_KEY,
  ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
  REGEX_FOR_PASSWORD,
  LOGIN_TITLE,
  LABEL_FOR_CONTACT_SUPPORT_FOR_FUEL,
  ERROR_MESSAGE_FOR_FOR_LOGOUT_AUTH_EXPIRE,
} from '@/constants'
import { axiosErrorMessageExtractor } from '../utils/error'
import { changeRoute } from '~/helpers/changeRoute'
import appTitleMixin from '~/mixins/appTitle.mixin'
import { datadogRum } from '@datadog/browser-rum'

export default {
  middleware: 'auth',
  mixins: [appTitleMixin],
  data() {
    return {
      LABEL_FOR_CONTACT_SUPPORT_FOR_FUEL,
      isRequestCodeLoading: false,
      isSubmitLoginLoading: false,
      email: '',
      codeRequested: false,
      loginData: {
        email: '',
        password: '',
      },
      passwordType: 'password',
    }
  },
  computed: {
    headConfig() {
      return {
        title: LOGIN_TITLE,
      }
    },
    rules() {
      return {
        email: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: 'blur',
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_PASSWORD,
            trigger: ['blur', 'change'],
          },
          {
            pattern: REGEX_FOR_PASSWORD,
            message: ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
      }
    },
  },
  mounted() {
    if (this.$route.query.isAuthTokenExpired === 'true') {
      this.$notify({
        title: 'Error',
        message: ERROR_MESSAGE_FOR_FOR_LOGOUT_AUTH_EXPIRE,
        type: 'error',
        duration: 5000,
      })
    }
  },
  methods: {
    togglePassword() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    async authenticationEmailPass() {
      let isValid = false
      this.$refs.emailloginForm.validate((result) => (isValid = result))
      if (!isValid) {
        return
      }
      try {
        this.isRequestCodeLoading = true
        if (!this.loginData.email) {
          return
        }
        const response = await this.$auth.loginWith('local', {
          data: this.loginData,
        })
        this.codeRequested = true
        const { user } = response.data
        this.$auth.setUser(user)
        this.$auth.$storage.setUniversal(USER_SESSION_DATA_KEY, user)
        datadogRum.setUser({
          id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          mobile: user.mobile,
          role: user.type,
        })

        const backUrl = this.$route?.query?.backUrl
          ? this.$route?.query?.backUrl.includes('/auth/login')
            ? `/finance/applications`
            : this.$route?.query?.backUrl
          : `/finance/applications`

        if (response?.data && backUrl) {
          await changeRoute({
            route: this.$route,
            router: this.$router,
            path: backUrl,
          })
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isRequestCodeLoading = false
      }
    },
  },
}
