//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  TITLE_FOR_COMPLIANCE,
  LABEL_FOR_LAST_SENT_AT,
  LABEL_FOR_LAST_CONSENTED_AT,
  LABEL_FOR_DATE,
  LABEL_FOR_MOBILE,
  LABEL_FOR_EMAIL,
  LABEL_FOR_IP,
  TITLE_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
  DESCRIPTION_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
} from '~/constants'
import {
  FINANCE_APPLICATION_TAB_CREDIT_REPORT_KEY,
  INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT,
  INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT,
  INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_GIVEN,
  MAINTAB_COMPLIANCE_FINANCE_KEY,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
export default {
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  data() {
    return {
      TITLE_FOR_COMPLIANCE,
      LABEL_FOR_LAST_SENT_AT,
      LABEL_FOR_LAST_CONSENTED_AT,
      LABEL_FOR_DATE,
      LABEL_FOR_MOBILE,
      LABEL_FOR_EMAIL,
      LABEL_FOR_IP,
      TITLE_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
      DESCRIPTION_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT,
      format,
      complianceData: {
        ...this.primaryData,
        ...this.secondaryData,
        ...this.sharedData,
      },
      counterForPrimary: 0,
      counterForSecondary: 0,
      getIsDialogLoading: false,
      proposalButtonLoading: false,
      INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT,
      INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT,
      INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_GIVEN,
    }
  },
  computed: {
    ...mapGetters('applications', ['getPrimaryApplicant']),
    alertStatus() {
      return !this.sharedData.insurancePrivacyConsent?.date &&
        !this.sharedData.insurancePrivacyConsent?.lastSentAt?.date
        ? INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT
        : !this.sharedData.insurancePrivacyConsent?.date &&
          this.sharedData.insurancePrivacyConsent?.lastSentAt?.date
        ? INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT
        : INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_GIVEN
    },
  },
  methods: {
    async sendInsurancePrivacyConsentEmailAndSmsRequest(applicantId) {
      this.getIsDialogLoading = true
      try {
        //TODO uuid library to add uid
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        const { id, applicationId } = this.$route.params
        await this.$store.dispatch(
          'applications/insurancePrivacyConsentRequestEmailAndSms',
          {
            id,
            applicationId,
            applicantId,
          }
        )

        await this.$store.dispatch('applications/fetchApplications', {
          id,
          applicationId,
        })
        this.getIsDialogLoading = false
      } catch (err) {
        this.getIsDialogLoading = false
      }
    },

    async submit() {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_TAB_CREDIT_REPORT_KEY,
        })
      }
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: 'creditReport',
        },
      })
      this.$nuxt.$emit('updateComponent', 'creditReport')
    },
    async backToPrevious() {
      this.$nuxt.$emit('change-compliance-tab', MAINTAB_COMPLIANCE_FINANCE_KEY)
    },
  },
}
