var render = function () {
  var _vm$sharedData, _vm$sharedData$insura, _vm$sharedData$insura2, _vm$sharedData2, _vm$sharedData2$insur, _vm$sharedData$insura3, _vm$sharedData$insura4, _vm$sharedData$insura5, _vm$sharedData$insura6, _vm$sharedData$insura7, _vm$sharedData$insura8, _vm$sharedData$insura9, _vm$sharedData$insura10, _vm$sharedData$insura11, _vm$sharedData$insura12, _vm$sharedData$insura13, _vm$sharedData$insura14, _vm$sharedData$insura15, _vm$sharedData$insura16, _vm$sharedData$insura17, _vm$sharedData$insura18, _vm$sharedData$insura19, _vm$sharedData$insura20, _vm$sharedData$insura21;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_c('el-card', {
    staticClass: "application-form compliance-tab pb-child-none"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "compliance-header-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_COMPLIANCE))])]), _vm._v(" "), _c('div', {
    staticClass: "mb-6",
    attrs: {
      "data-selector": "privacy-section"
    }
  }, [_c('div', {
    staticClass: "el-alert text-black is-light mb-6",
    class: _vm.alertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT ? 'bg-primary el-alert--primary' : _vm.alertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT ? 'bg-FFF2EE' : 'el-alert--success',
    attrs: {
      "role": "alert"
    }
  }, [_vm.alertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/info.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }) : _vm.alertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/send-orange.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.alertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT ? _vm.DESCRIPTION_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT : _vm.alertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT ? ("Privacy consent request sent at " + (_vm.format(new Date((_vm$sharedData = _vm.sharedData) === null || _vm$sharedData === void 0 ? void 0 : (_vm$sharedData$insura = _vm$sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura === void 0 ? void 0 : (_vm$sharedData$insura2 = _vm$sharedData$insura.lastSentAt) === null || _vm$sharedData$insura2 === void 0 ? void 0 : _vm$sharedData$insura2.date), 'dd/MM/yyyy hh:mm aaa'))) : ("Privacy consent submitted at " + (_vm.format(new Date((_vm$sharedData2 = _vm.sharedData) === null || _vm$sharedData2 === void 0 ? void 0 : (_vm$sharedData2$insur = _vm$sharedData2.insurancePrivacyConsent) === null || _vm$sharedData2$insur === void 0 ? void 0 : _vm$sharedData2$insur.date), 'dd/MM/yyyy hh:mm aaa')))))])])]), _vm._v(" "), _vm.primaryData && _vm.primaryData.primaryApplicant ? _c('div', {
    staticClass: "mb-6"
  }, [_c('el-row', {
    staticClass: "mb-child-xs-4",
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 3
    }
  }, [_vm.primaryData && _vm.primaryData.primaryApplicant && _vm.primaryData.primaryApplicant.firstName ? _c('p', {
    staticClass: "mb-2 text-xs font-extrabold",
    attrs: {
      "data-selector": "p-applicant-name"
    }
  }, [_vm._v(_vm._s(_vm.primaryData.primaryApplicant.firstName) + "\n              " + _vm._s(_vm.primaryData.primaryApplicant.lastName))]) : _vm._e(), _vm._v(" "), _vm.primaryData.primaryApplicant ? _c('p', {
    staticClass: "text-xs",
    attrs: {
      "data-selector": "applicant-type-primary-text"
    }
  }, [_vm._v("\n              Primary applicant\n            ")]) : _vm._e()]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 5
    }
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_SENT_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-last-sent-date"
    }
  }, [_vm._v("\n                " + _vm._s((_vm$sharedData$insura3 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura3 !== void 0 && (_vm$sharedData$insura4 = _vm$sharedData$insura3.lastSentAt) !== null && _vm$sharedData$insura4 !== void 0 && _vm$sharedData$insura4.date ? _vm.format(new Date((_vm$sharedData$insura5 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura5 === void 0 ? void 0 : (_vm$sharedData$insura6 = _vm$sharedData$insura5.lastSentAt) === null || _vm$sharedData$insura6 === void 0 ? void 0 : _vm$sharedData$insura6.date), 'dd/MM/yyyy hh:mm aaa') : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_MOBILE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-mobile"
    }
  }, [_vm._v(_vm._s((_vm$sharedData$insura7 = (_vm$sharedData$insura8 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura8 === void 0 ? void 0 : (_vm$sharedData$insura9 = _vm$sharedData$insura8.lastSentAt) === null || _vm$sharedData$insura9 === void 0 ? void 0 : _vm$sharedData$insura9.mobile) !== null && _vm$sharedData$insura7 !== void 0 ? _vm$sharedData$insura7 : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_EMAIL) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-email"
    }
  }, [_vm._v("\n                " + _vm._s((_vm$sharedData$insura10 = (_vm$sharedData$insura11 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura11 === void 0 ? void 0 : (_vm$sharedData$insura12 = _vm$sharedData$insura11.lastSentAt) === null || _vm$sharedData$insura12 === void 0 ? void 0 : _vm$sharedData$insura12.email) !== null && _vm$sharedData$insura10 !== void 0 ? _vm$sharedData$insura10 : '--'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6,
      "xl": 4
    }
  }, [_c('p', {
    staticClass: "mb-2 text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_CONSENTED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label",
    attrs: {
      "data-selector": "p-last-signed-at"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_DATE) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value"
  }, [_vm._v("\n                " + _vm._s((_vm$sharedData$insura13 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura13 !== void 0 && _vm$sharedData$insura13.date ? _vm.format(new Date((_vm$sharedData$insura14 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura14 === void 0 ? void 0 : _vm$sharedData$insura14.date), 'dd/MM/yyyy hh:mm aaa') : '--'))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mb-2 compliance-documents-label"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_IP) + "\n              "), _c('span', {
    staticClass: "compliance-documents-value",
    attrs: {
      "data-selector": "p-ip-address"
    }
  }, [_vm._v(_vm._s(((_vm$sharedData$insura15 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura15 === void 0 ? void 0 : _vm$sharedData$insura15.ipAddress) || '--'))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 6
    }
  }, [_c('div', [[_c('el-button', {
    staticClass: "w-auto mb-6",
    attrs: {
      "type": (_vm$sharedData$insura16 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura16 !== void 0 && (_vm$sharedData$insura17 = _vm$sharedData$insura16.lastSentAt) !== null && _vm$sharedData$insura17 !== void 0 && _vm$sharedData$insura17.date ? 'default' : 'primary',
      "data-selector": "p-send-resend-btn",
      "plain": (_vm$sharedData$insura18 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura18 === void 0 ? void 0 : (_vm$sharedData$insura19 = _vm$sharedData$insura18.lastSentAt) === null || _vm$sharedData$insura19 === void 0 ? void 0 : _vm$sharedData$insura19.date
    },
    on: {
      "click": function ($event) {
        return _vm.sendInsurancePrivacyConsentEmailAndSmsRequest(_vm.primaryData.primaryApplicant._id);
      }
    }
  }, [_vm._v("\n                  " + _vm._s((_vm$sharedData$insura20 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura20 !== void 0 && (_vm$sharedData$insura21 = _vm$sharedData$insura20.lastSentAt) !== null && _vm$sharedData$insura21 !== void 0 && _vm$sharedData$insura21.date ? 'Resend' : 'Send'))])]], 2)]), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6
    }
  })], 1)], 1) : _vm._e()])]), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "comp-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.backToPrevious();
      }
    }
  }, [_vm._v("Back")])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "comp-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("Next")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }