export default {
  async fetchUsers(
    { commit },
    {
      status = 'All',
      currentPage = 1,
      search = '',
      sortKey = 'createdAt',
      sortDirection = 'desc',
    }
  ) {
    try {
      let requestUrl = '/user?'
      const sortField = (sortDirection === 'desc' ? '-' : '') + sortKey
      if (status !== 'All') {
        requestUrl += '&status=' + status
      }
      if (currentPage) {
        requestUrl += '&page=' + currentPage
      }
      if (search) {
        requestUrl += '&requestId=' + search
      }
      requestUrl += '&sort=' + sortField
      commit('startTableLoading')
      const requests = await this.$axios.get(requestUrl)
      const { data } = requests
      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })

      const tableData = data.docs.map((userdata) => {
        userdata.customer =
          userdata.firstName && userdata.lastName
            ? userdata.firstName + ' ' + userdata.lastName
            : '--'
        userdata.createdAt = new Date(userdata.createdAt).toDateString()
        return userdata
      })
      commit('setUsers', tableData)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error getting users :(',
      })
    } finally {
      commit('stopTableLoading')
    }
  },
  async updateUser(_, user) {
    await this.$axios.patch(`/user/${user._id}`, user)
  },
  async createUser(_, user) {
    await this.$axios.post('/user', user)
  },
}
