//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
  ERROR_MESSAGE_PLEASE_ENTER_PRODUCT,
  ERROR_MESSAGE_PLEASE_ENTER_COMMISSION,
  FORM_TYPE_ADD,
  FORM_TYPE_EDIT,
  LABEL_SAVE,
} from '@/constants'
import {
  LABEL_FOR_ADD_WARRANTY,
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_FOR_PROVIDER,
  LABEL_FOR_PRODUCT,
  LABEL_FOR_WHOLESALE,
  LABEL_FOR_RETAIL,
  LABEL_FOR_COMMISSION,
  LABEL_UPDATE,
  LABEL_REMOVE,
  LABEL_FOR_WARRANTY,
} from '~/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'
import {
  validatePositiveNumber,
  validateWarrentyRetailPrice,
} from '~/helpers/validators'

export default {
  name: 'RequestAssetDetails',
  props: {
    openDialog: {
      type: String,
      default: '',
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    /* eslint-disable */
    const checkNumberValue = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)
    const validateRetailPrice = (_rule, value, callback) => {
      validateWarrentyRetailPrice(
        _rule,
        value,
        callback,
        this.warrantyData.loanDetails.settlement.warranty.wholesale
      )
    }
    /* eslint-enable */
    return {
      LABEL_FOR_ADD_WARRANTY,
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_FOR_PROVIDER,
      LABEL_FOR_PRODUCT,
      LABEL_FOR_WHOLESALE,
      LABEL_FOR_RETAIL,
      LABEL_FOR_COMMISSION,
      LABEL_UPDATE,
      LABEL_REMOVE,
      FORM_TYPE_ADD,
      FORM_TYPE_EDIT,
      LABEL_SAVE,
      LABEL_FOR_WARRANTY,
      ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
      ERROR_MESSAGE_PLEASE_ENTER_PRODUCT,
      warrantyData: { ...this.sharedData },
      PROVIDERS: [],
      getIsDialogLoading: false,
      rules: {
        'loanDetails.settlement.warranty.provider': [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.warranty.product': [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_ENTER_PRODUCT,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.warranty.wholesale': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.warranty.retail': [
          {
            required: true,
            validator: checkNumberValue,
            trigger: ['blur', 'change'],
          },
          {
            validator: validateRetailPrice,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.warranty.commission': [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_ENTER_COMMISSION,
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
      this.closeDialog()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },

  computed: {
    loanDetailsForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.warrantyData = JSON.parse(JSON.stringify(this.sharedData))
      return { ...this.sharedData }
    },
  },
  watch: {
    async openDialog() {
      const response = await this.$store.dispatch(
        `applications/getAllProviders`
      )
      this.PROVIDERS = response.data
    },
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeRequestWarrantyDialog')
    },
    calculateCommission() {
      this.warrantyData.loanDetails.settlement.warranty.commission =
        this.warrantyData?.loanDetails?.settlement?.warranty?.retail -
        this.warrantyData?.loanDetails?.settlement?.warranty?.wholesale
    },
    async submitForm(removeData = false) {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.warrantyData.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }

        this.getIsDialogLoading = true

        if (removeData) {
          this.warrantyData.loanDetails.settlement.warranty = {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          }
        }

        await this.$store.dispatch('applications/updateWarranty', {
          warrantyDetails: { ...this.warrantyData },
          id,
          applicationId,
        })
        this.$nuxt.$emit('closeRequestWarrantyDialog')
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.getIsDialogLoading = false
      }
    },
  },
}
