import { render, staticRenderFns } from "./LoanSubmission.vue?vue&type=template&id=8529d07e&"
import script from "./LoanSubmission.vue?vue&type=script&lang=js&"
export * from "./LoanSubmission.vue?vue&type=script&lang=js&"
import style0 from "./LoanSubmission.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormattedNumericInput: require('/codebuild/output/src1661699396/src/cc-portal/components/FormattedNumericInput.vue').default,LoanSubmissionDialog: require('/codebuild/output/src1661699396/src/cc-portal/components/LoanSubmissionDialog.vue').default,EditSubmissionConfirmDialog: require('/codebuild/output/src1661699396/src/cc-portal/components/EditSubmissionConfirmDialog.vue').default,CpdSendConfirmDialog: require('/codebuild/output/src1661699396/src/cc-portal/components/CpdSendConfirmDialog.vue').default})
