//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAmountWithOutCurrencyForMatches } from '~/helpers/application'

import {
  LABEL_FOR_MATCHES,
  LABEL_FOR_LAST_UPDATED_AT,
  LABEL_FOR_LENDER_MATCHES_FOUND,
  LABEL_FOR_NO_MATCHES_FOUND,
  LABEL_FOR_SELECT_VEHICLE,
  LABEL_FOR_ENTER_DETAILS_MANUALLY,
  LABEL_FOR_APPLICANT_PROFILE,
  ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO,
  ERROR_MESSAGE_FOR_TYPE,
  ERROR_MESSAGE_FOR_MAKE,
  ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
  ERROR_MESSAGE_FOR_REGISTRATION_PLATE,
  ERROR_MESSAGE_FOR_VIN,
  ERROR_MESSAGE_FOR_DETAILS,
  ERROR_MESSAGE_FOR_SALE_TYPE,
  ERROR_MESSAGE_FOR_MODEL_CONDITION,
  ERROR_MESSAGE_FOR_MODEL_TYPE,
  ERROR_MESSAGE_FOR_MODEL_YEAR,
  ERROR_MESSAGE_FOR_MODEL,
  ERROR_MESSAGE_FOR_BALLOON,
  ERROR_MESSAGE_FOR_LOAN_TERM,
  ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
  LABEL_FOR_FORTNIGHT,
  LABEL_FOR_WEEK,
  LABEL_FOR_CUSTOMER_RATE_FOR_LENDER,
  LABEL_FOR_COMPARISON_RATE_FOR_LENDER,
  LABEL_FOR_NO_PRELIMINARY_LENDER_MATCHES_ARE_FOUND_FOR_THE_CUSTOMER,
  LABEL_SELECTED_BY_CUSTOMER,
  LABEL_FIXED_RATE,
  LABEL_VIEW_DETAILS,
  LABEL_FOR_VEHICLE_DESCRIPTION,
  LABEL_FOR_MONTHS,
  LABEL_FOR_MONTH,
  ERROR_MESSAGE_FOR_REPAYMENTS,
  LABEL_FOR_ORIGINATION_FEE_CAPITAL,
  LABEL_FOR_BROKER_LOADING,
  LABEL_APPLY,
  LABEL_MONTHLY_OPTION_LABEL,
  APPLICATION_STATUS_READY_FOR_SUBMISSION,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
} from '~/constants'
import LenderMatch from './../UtilComponents/LenderMatch.vue'
import ApplicantProfile from './../UtilComponents/ApplicantProfile.vue'
import lenderDetailsDialog from './../UtilComponents/lenderDetailsDialog.vue'
import { differenceInCalendarMonths } from 'date-fns'
import { VEHICLE_TYPE_ASSET } from '~/constants/vehicles'
import { PARTNER_LENDER_EXCLUSION_LIST } from '~/constants/partners'
import { Loading } from 'element-ui'
import { toCurrency } from '@/helpers/currency'
import { getBalloonAmountWithApi } from '@/helpers/calculateBalloonAmount'
import { getBalloonPctWithApi } from '@/helpers/calculateBalloonPct'
import { convertValuesToNumber } from '@/helpers/convertValuesToNumber'
import {
  REQUESTED_LOAN_DETAILS,
  LABEL_DOCUMENT_LOAN_TYPE,
  LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
  LABEL_TRADE_IN_EQUITY,
  LABEL_DOCUMENT_DEPOSIT,
  LABEL_DOCUMENT_TRADE_IN,
  LABEL_DOCUMENT_ESTIMETED_PRICE,
  LABEL_DOCUMENT_LOAN_AMOUNT,
  LABEL_LOAN_TREM,
  LABEL_DOCUMENT_BALLOON,
  LABEL_DOCUMENT_BALLOON_ONLY,
  LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
  LABEL_FOR_CANCEL_BUTTON,
  APPLICATION_TRADE_IN_VEHICLE,
  LABEL_PLACEHOLDER_LOAN_AMOUNT_REQUIREMENT,
  REQUIREMENT_LOAN_DETAILS,
  LABEL_TRADING_LIABILITIES,
  LABEL_DOCUMENT_TYPE,
  ASSET_TYPE_OPTIONS_FOR_QUOTE_DETAILS,
  LABEL_DOCUMENT_DETAILS,
  PLACEHOLDER_FOR_ASSET_DESCRIPTION,
  LABEL_DOCUMENT_MAKE,
  LABEL_DOCUMENT_MODEL,
  LABEL_DOCUMENT_YEAR,
  LABEL_DOCUMENT_MODEL_TYPE,
  LABEL_DOCUMENT_CONDITION,
  CONDITION_OPTIONS,
  LABEL_DOCUMENT_SALE_TYPE,
  SALE_TYPE_OPTIONS,
  LABEL_DOCUMENT_VIN,
  LABEL_DOCUMENT_REGISTRATION_PLATE,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  LABEL_ASSET_TYPE_OPTION_OTHER,
  LABEL_QUOTE_DETAILS,
  LABEL_LOAN_DETAILS,
  LABEL_BROKER_FEE,
  LABEL_GENERATE_MATCHES,
  LENDER_MATCHES_SORTED_BY,
  LENDER_MATCHES_SHOW_REPAYMENT,
  LOAN_TYPE_PERSONAL_LOAN,
  LABEL_BUSINESS_DETAILS,
  LABEL_REPAYMENT_HIGH_TO_LOW,
  LABEL_REPAYMENT_LOW_TO_HIGH,
  LABEL_CUSTOMER_RATE_HIGH_TO_LOW,
  LABEL_TOTAL_INCOME_LOW_TO_HIGH,
  LABEL_VEHICLE_VALUATION,
  LABEL_SHOW_REPAYMENT,
  LABEL_SORTED_BY,
  LABEL_GST_REGISTRATION,
  LABEL_ABN_LENGTH,
  LABEL_ABN,
  LABEL_STRUCTURE,
  LABEL_COLLAPSE_MENU_SHOW,
  LABEL_COLLAPSE_MENU_HIDE,
  LABEL_CONDITION_OPTIONS_NEW,
  LABEL_CONDITION_OPTIONS_DEMO,
  LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT,
  LABEL_EXPENSE_FREQUENCY_WEEKLY,
  LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
  LABEL_EXPENSE_FREQUENCY_MONTHLY,
  LABEL_EXPENSE_FREQUENCY_YEARLY,
  ERROR_MESSAGES_FOR_BROKERLOADING,
  ERROR_MESSAGES_FOR_ORIGINATIONFEE_EQUAL_OR_GREATER_TO_ZERO,
  ERROR_MESSAGES_FOR_ORIGINATIONFEE,
  FUEL_KEYWORD,
  CARCLARITY_KEYWORD,
  LABEL_PURCHASE_PRICE,
  LABEL_LOAN_AMOUNT,
  LABEL_TRADE_IN_AMOUNT,
} from '~/constants/applications'
import {
  DEFAULT_VEHICLE_OBJECT,
  LABEL_ASSET_DETAILS,
} from '~/constants/referrals'
import {
  validatePositiveNumber,
  checkAmount,
  validateNumber,
  validateBalloonPercentageWithGreaterThanZero,
} from '~/helpers/validators'
import { yearViewOption } from '~/utils/form'

import { format } from 'date-fns'
import { setVehicleYear } from '~/helpers/application'
import { mapGetters } from 'vuex'

export default {
  data() {
    const checkPurchasePrice = (_rule, value, callback) =>
      checkAmount(_rule, value, callback)
    const checkFinanceDepositAmount = (_rule, value, callback) =>
      checkAmount(
        _rule,
        value,
        callback,
        this.applicationMatchesForm.purchasePrice
      )
    var validateBallonAmount = (rule, value, callback) => {
      if (
        this.applicationMatchesForm?.balloonAmount >
        this.applicationMatchesForm?.purchasePrice
      ) {
        callback(
          new Error(
            ERROR_MESSAGES_FOR_ORIGINATIONFEE +
              this.applicationMatchesForm?.purchasePrice
          )
        )
      } else {
        callback()
      }
    }
    const checkTradeInAmount = (_rule, value, callback) => {
      if (this.triggerValidation) {
        validateNumber(_rule, this.tradeInAmount, callback)
      }
    }
    var validateBrokerLoading = (rule, value, callback) => {
      this.brokerLoadingisValid = true
      if (
        parseFloat(value) === '' ||
        parseFloat(value) > this.maxBrokerLoading ||
        parseFloat(value) < 0
      ) {
        this.brokerLoadingisValid = false
        callback(
          new Error(ERROR_MESSAGES_FOR_BROKERLOADING + this.maxBrokerLoading)
        )
      } else {
        this.brokerLoadingisValid = true
        callback()
      }
    }
    var validateOriginationFee = (rule, value, callback) => {
      this.brokerLoadingisValid = true
      if (value < 0 || value === '') {
        callback(
          new Error(ERROR_MESSAGES_FOR_ORIGINATIONFEE_EQUAL_OR_GREATER_TO_ZERO)
        )
      } else if (value > this.maxOriginationFee) {
        callback(
          new Error(
            ERROR_MESSAGES_FOR_ORIGINATIONFEE + `${this.maxOriginationFee}`
          )
        )
      } else {
        callback()
      }
    }
    return {
      yearViewOption,
      globalSettingsRules: {
        originationFee: [
          {
            required: true,
            validator: validateOriginationFee,
            trigger: ['blur', 'change'],
          },
        ],
        brokerLoading: [
          {
            required: true,
            validator: validateBrokerLoading,
            trigger: ['blur', 'change'],
          },
        ],
      },
      rules: {
        loanAmount: {
          validator: (_rule, value, callback) => {
            validatePositiveNumber(this.loanAmountTotal, callback)
          },
          trigger: ['blur', 'change'],
        },
        purchasePrice: [
          {
            required: true,
            validator: checkPurchasePrice,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              if (value <= 0) {
                callback(
                  new Error(ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO)
                )
              } else {
                callback()
              }
            },
            message: ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO,
            trigger: ['blur', 'change'],
          },
        ],
        balloonPct: [
          {
            validator: (_rule, value, callback) => {
              validateBalloonPercentageWithGreaterThanZero(
                value,
                callback,
                this.applicationMatchesForm.loanTermYears,
                ERROR_MESSAGE_FOR_BALLOON,
                100
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
        ballonAmount: [
          {
            validator: validateBallonAmount,
            trigger: ['blur', 'change'],
          },
        ],
        financeDepositAmount: [
          {
            validator: checkFinanceDepositAmount,
            trigger: ['blur', 'change'],
          },
        ],

        assetType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        makeCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MAKE,
            trigger: ['blur', 'change'],
          },
        ],
        familyCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL,
            trigger: ['blur', 'change'],
          },
        ],

        codeDescription: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        condition: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_CONDITION,
            trigger: ['blur', 'change'],
          },
        ],
        purchaseSource: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_SALE_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        'vehicle.assetDetail': [
          {
            message: ERROR_MESSAGE_FOR_DETAILS,
            trigger: ['blur', 'change'],
          },
        ],
        loanTermYears: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LOAN_TERM,
            trigger: ['blur', 'change'],
          },
        ],
        customerNote: [
          {
            message: ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
            trigger: ['blur', 'change'],
          },
        ],
        tradeInAmount: [
          {
            validator: checkTradeInAmount,
            trigger: ['blur', 'change'],
          },
        ],
        year: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_YEAR,
            trigger: ['blur', 'change'],
          },
        ],
      },
      LABEL_EXPENSE_FREQUENCY_WEEKLY,
      LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
      LABEL_EXPENSE_FREQUENCY_MONTHLY,
      LABEL_EXPENSE_FREQUENCY_YEARLY,
      format,
      LABEL_FOR_ORIGINATION_FEE_CAPITAL,
      LABEL_FOR_BROKER_LOADING,
      LABEL_FOR_VEHICLE_DESCRIPTION,
      LABEL_FOR_NO_PRELIMINARY_LENDER_MATCHES_ARE_FOUND_FOR_THE_CUSTOMER,
      LABEL_FOR_CUSTOMER_RATE_FOR_LENDER,
      LABEL_FOR_COMPARISON_RATE_FOR_LENDER,
      LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT,
      LABEL_FOR_FORTNIGHT,
      LABEL_FOR_WEEK,
      ERROR_MESSAGE_FOR_BALLOON,
      LENDER_MATCHES_SHOW_REPAYMENT,
      ERROR_MESSAGE_FOR_TYPE,
      ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO,
      LABEL_GENERATE_MATCHES,
      LABEL_FOR_APPLICANT_PROFILE,
      LABEL_FOR_ENTER_DETAILS_MANUALLY,
      LABEL_FOR_SELECT_VEHICLE,
      LABEL_ASSET_DETAILS,
      LABEL_LOAN_DETAILS,
      REQUESTED_LOAN_DETAILS,
      LABEL_DOCUMENT_LOAN_TYPE,
      LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      LABEL_TRADE_IN_EQUITY,
      LABEL_DOCUMENT_DEPOSIT,
      LABEL_DOCUMENT_TRADE_IN,
      LABEL_DOCUMENT_ESTIMETED_PRICE,
      LABEL_DOCUMENT_LOAN_AMOUNT,
      LABEL_LOAN_TREM,
      LABEL_DOCUMENT_BALLOON,
      LABEL_DOCUMENT_BALLOON_ONLY,
      LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
      LABEL_FOR_CANCEL_BUTTON,
      APPLICATION_TRADE_IN_VEHICLE,
      LABEL_PLACEHOLDER_LOAN_AMOUNT_REQUIREMENT,
      REQUIREMENT_LOAN_DETAILS,
      LABEL_TRADING_LIABILITIES,
      LABEL_FOR_MATCHES,
      LABEL_FOR_LAST_UPDATED_AT,
      LABEL_FOR_LENDER_MATCHES_FOUND,
      LABEL_FOR_NO_MATCHES_FOUND,
      activeName: '1',
      LABEL_DOCUMENT_TYPE,
      ASSET_TYPE_OPTIONS_FOR_QUOTE_DETAILS,
      LABEL_DOCUMENT_DETAILS,
      PLACEHOLDER_FOR_ASSET_DESCRIPTION,
      LABEL_DOCUMENT_MAKE,
      LABEL_DOCUMENT_MODEL,
      LABEL_DOCUMENT_YEAR,
      LABEL_DOCUMENT_MODEL_TYPE,
      LABEL_DOCUMENT_CONDITION,
      CONDITION_OPTIONS,
      LABEL_DOCUMENT_SALE_TYPE,
      SALE_TYPE_OPTIONS,
      LABEL_DOCUMENT_VIN,
      LABEL_DOCUMENT_REGISTRATION_PLATE,
      manuallySelected: false,
      MAKE: [],
      YEARS_OPTIONS: [],
      MODEL_OPTIONS: [],
      MODEL_TYPES_OPTIONS: [],
      yearDisabled: true,
      modelTypeDisabled: true,
      applicationMatchesForm: JSON.parse(JSON.stringify(this.sharedData)),
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      activeName1: 'first',
      LABEL_QUOTE_DETAILS,
      ERROR_MESSAGE_FOR_MODEL,
      ERROR_MESSAGE_FOR_MODEL_YEAR,
      ERROR_MESSAGE_FOR_MODEL_TYPE,
      ERROR_MESSAGE_FOR_MODEL_CONDITION,
      ERROR_MESSAGE_FOR_SALE_TYPE,
      ERROR_MESSAGE_FOR_DETAILS,
      ERROR_MESSAGE_FOR_VIN,
      ERROR_MESSAGE_FOR_REGISTRATION_PLATE,
      ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
      LENDER_MATCHES_SORTED_BY,
      LABEL_CONDITION_OPTIONS_NEW,
      LABEL_CONDITION_OPTIONS_DEMO,
      repayments: 'Monthly',
      sortedBy: LABEL_REPAYMENT_LOW_TO_HIGH,
      generatedLenderMatches: [],
      ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
      ERROR_MESSAGE_FOR_REPAYMENTS,
      LOAN_TYPE_PERSONAL_LOAN,
      isLenderDetailsDialogOpen: false,
      LABEL_SELECTED_BY_CUSTOMER,
      LABEL_FIXED_RATE,
      LABEL_VIEW_DETAILS,
      LABEL_BUSINESS_DETAILS,
      LABEL_REPAYMENT_HIGH_TO_LOW,
      LABEL_REPAYMENT_LOW_TO_HIGH,
      LABEL_CUSTOMER_RATE_HIGH_TO_LOW,
      LABEL_TOTAL_INCOME_LOW_TO_HIGH,
      LABEL_VEHICLE_VALUATION,
      LABEL_SHOW_REPAYMENT,
      LABEL_SORTED_BY,
      LABEL_GST_REGISTRATION,
      LABEL_ABN_LENGTH,
      LABEL_ABN,
      LABEL_STRUCTURE,
      LABEL_COLLAPSE_MENU_HIDE,
      collapseMenuStatus: LABEL_COLLAPSE_MENU_HIDE,
      LABEL_COLLAPSE_MENU_SHOW,
      vehicleValuation:
        this.sharedData?.vehicle?.latestValuation?.retailPrice || 0,
      VEHICLE_TYPE_ASSET,
      LABEL_FOR_MONTHS,
      LABEL_FOR_MONTH,
      tradeInAmount: 0,
      modelDisabled: true,
      triggerValidation: false,
      dynamicPricingGlobalSettings: {
        user: '',
        region: '',
      },
      value4: 48,
      globalSettings: {
        brokerLoading: 0,
        originationFee: 0,
        loanTermYears: 1,
      },
      maxOriginationFee: 0,
      brokerLoadingisValid: true,
      globalSettingsLoading: false,
      LABEL_APPLY,
      loanTermYearsList: [],
      factsData: [],
      customDebounce: null,
      isGenerateLenderMatches: false,
      btnLoading: false,
    }
  },
  components: {
    LenderMatch,
    ApplicantProfile,
    lenderDetailsDialog,
  },

  computed: {
    ...mapGetters('applications', [
      'getMatchesGenerated',
      'getGeneratedMatchesData',
      'getLenderMatchesSettings',
      'getLenderMatchesApplicationMatchesForm',
      'getReferrerAccount',
    ]),
    maxBrokerLoading() {
      return this.isConsumer
        ? Math.max(
            ...this.generatedLenderMatches
              .map((o) => {
                return o?.commission?.dialDown
              })
              .filter((option) => option !== undefined)
          ) || 2
        : Math.max(
            ...this.generatedLenderMatches
              .map((o) => {
                return o?.commission?.maxBrokerIncomePctNAFPct
              })
              .filter((option) => option !== undefined)
          ) || 8.8
    },
    carCondition() {
      return this.applicationMatchesForm?.vehicle?.condition
    },
    isLoanTypePersonal() {
      return this.applicationMatchesForm?.loanType === LOAN_TYPE_PERSONAL_LOAN
    },
    years() {
      const year = new Date().getFullYear()
      let years_option = []
      const month = new Date().getMonth()
      if (this.carCondition === this.LABEL_CONDITION_OPTIONS_NEW) {
        let data = setVehicleYear(year, 1)
        years_option = data
        if (month >= 10) {
          years_option.unshift(data[0] + 1)
        }
      } else {
        const count =
          this.carCondition === this.LABEL_CONDITION_OPTIONS_DEMO ? 1 : 25
        years_option = setVehicleYear(year, count)
      }
      return years_option
    },
    loanAmountTotal() {
      // FORMULA: Loan Amount + (New Purchase - Old Purchase) - (New Deposit - Old Deposit) - (New TradeIn - Old TradeIn)
      const loanAmount = parseFloat(
        (this.applicationMatchesForm?.purchasePrice || 0) -
          (this.applicationMatchesForm?.vehicle?.financeDepositAmount || 0) -
          ((this.tradeInAmount !== undefined ? this.tradeInAmount : 0) || 0)
      ).toFixed(2)
      if (loanAmount < 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.applicationMatchesForm.balloonPct = 0
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.applicationMatchesForm.balloonAmount = 0
      }
      return loanAmount
    },
    selectedlenderDetailsData() {
      return this.sharedData?.quotesData?.find(
        (data) => data?.selectedByCustomerUser
      )
    },
    isAssetTypeVehicleOrOther() {
      const { vehicle } = this.applicationMatchesForm
      return (
        vehicle &&
        [
          LABEL_ASSET_TYPE_OPTION_VEHICLE,
          LABEL_ASSET_TYPE_OPTION_OTHER,
        ].includes(vehicle.assetType)
      )
    },
    isAssetTypeVehicle() {
      const { vehicle } = this.applicationMatchesForm
      return vehicle && vehicle.assetType === LABEL_ASSET_TYPE_OPTION_VEHICLE
    },
    isAssetTypeOther() {
      const { vehicle } = this.applicationMatchesForm
      return vehicle && vehicle.assetType === LABEL_ASSET_TYPE_OPTION_OTHER
    },
    isAssetTypeUnknown() {
      const { vehicle } = this.applicationMatchesForm
      return (
        vehicle &&
        vehicle.assetType === LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY
      )
    },
    loanDetailsData() {
      return this.fetchCommonLoginData()
    },
  },
  created() {
    this.setMakes()
  },
  async mounted() {
    this.changeCondition()
    if (!this.applicationMatchesForm?.tradeIn?.tradeInAmount) {
      this.tradeInAmount = this.applicationMatchesForm?.tradeIn?.tradeInAmount
    }
    if (!this.applicationMatchesForm?.vehicle) {
      this.applicationMatchesForm.vehicle = DEFAULT_VEHICLE_OBJECT
    }
    if (!this.getMatchesGenerated) {
      await this.generateLenderMatchesFirst()
      this.sortLenderMatches()
      await this.$store.dispatch('applications/updateMatchesGenerated', {
        data: this.generatedLenderMatches,
      })
      this.setGlobalSettings()
      await this.$store.dispatch(
        'applications/updateLenderMatchesApplicationMatchesForm',
        {
          data: JSON.parse(JSON.stringify(this.applicationMatchesForm)),
        }
      )
      this.updateMatchesSettings()
    } else {
      this.generatedLenderMatches = this.getGeneratedMatchesData

      this.resetGlobalSettings()
      this.sortLenderMatches()
    }
    this.$nuxt.$on('close-lender-detail-dialog', (val) => {
      this.isLenderDetailsDialogOpen = val
    })
    setTimeout(() => {
      this.triggerValidation = true
      if (this.applicationMatchesForm?.vehicle?.customerNote) {
        this.manuallySelected = true
      }
    }, 2000)
    this.updateLoanTermYear()
    this.$nuxt.$on('onIndividualMatchHandler', (inputData) => {
      this.onIndividualMatchHandler(inputData)
    })
    if (
      this.applicationMatchesForm?.vehicle &&
      !this.applicationMatchesForm?.vehicle?.assetType
    ) {
      this.applicationMatchesForm.vehicle.assetType =
        LABEL_ASSET_TYPE_OPTION_VEHICLE
    }
    this.$nuxt.$on('updateMatches', () => {
      this.regenerateAndUpadteLenderMatches()
    })
    this.calculateBalloonAmt()
    this.assetsManuallySelected()
  },
  async beforeDestroy() {
    this.$nuxt.$off('close-lender-detail-dialog')
    this.$nuxt.$off('onIndividualMatchHandler')
    this.$nuxt.$off('updateMatches')
    await this.$store.dispatch(
      'applications/updateLenderMatchesApplicationMatchesForm',
      {
        data: JSON.parse(JSON.stringify(this.applicationMatchesForm)),
      }
    )
    this.updateMatchesSettings()
  },
  watch: {
    manuallySelected(value) {
      if (!value && this.applicationMatchesForm?.vehicle) {
        this.applicationMatchesForm.vehicle.customerNote = ''
      }
    },
    'applicationMatchesForm.balloonPct'() {
      this.calculateBalloonAmt()
      if (this.applicationMatchesForm?.vehicle?.customerNote) {
        this.manuallySelected = true
      }
    },
  },
  methods: {
    fetchCommonLoginData(data) {
      return {
        purchasePrice: getAmountWithOutCurrencyForMatches(
          data,
          LABEL_PURCHASE_PRICE
        ),
        loanAmount: getAmountWithOutCurrencyForMatches(data, LABEL_LOAN_AMOUNT),
        deposit: this.getDepositAmount(data),
        balloonPct: this.getBalloonPct(data),
        loanTermYears: this.getLoanTermYears(data),
        tradeInAmount: getAmountWithOutCurrencyForMatches(
          data,
          LABEL_TRADE_IN_AMOUNT
        ),
      }
    },
    onKeyPressForTradeInAmount(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 45) {
        event.preventDefault()
      }
    },

    getDepositAmount(data) {
      let checkArray = data?.loanDetails
      if (checkArray) {
        if (checkArray?.settlement?.depositAmount) {
          if (
            [
              APPLICATION_STATUS_READY_FOR_SUBMISSION,
              APPLICATION_STATUS_SUBMITTED_TO_LENDER,
            ].includes(data.status)
          ) {
            return checkArray?.submission?.depositAmount
          }
          return checkArray.settlement.depositAmount
        } else if (checkArray?.approval?.depositAmount) {
          return checkArray.approval.depositAmount
        } else if (checkArray?.submission?.depositAmount) {
          return checkArray?.submission?.depositAmount
        } else {
          return (
            (this.vehicleDetails && this.vehicleDetails.financeDepositAmount) ||
            0
          )
        }
      } else {
        return (
          (this.vehicleDetails && this.vehicleDetails.financeDepositAmount) || 0
        )
      }
    },
    getBalloonPct(data) {
      let checkArray = data?.loanDetails
      if (checkArray) {
        if (checkArray?.settlement?.balloonPct) {
          if (
            [
              APPLICATION_STATUS_READY_FOR_SUBMISSION,
              APPLICATION_STATUS_SUBMITTED_TO_LENDER,
            ].includes(data.status)
          ) {
            return checkArray?.submission?.balloonPct
          }
          return checkArray.settlement.balloonPct
        } else if (checkArray?.approval?.balloonPct) {
          return checkArray.approval.balloonPct
        } else if (checkArray?.submission?.balloonPct) {
          return checkArray.submission.balloonPct
        } else {
          return data.balloonPct
        }
      } else {
        return data.balloonPct
      }
    },

    getLoanTermYears(data) {
      let checkArray = data
      if (checkArray) {
        if (checkArray?.loanDetails?.settlement?.loanTermYears) {
          if (
            [
              APPLICATION_STATUS_READY_FOR_SUBMISSION,
              APPLICATION_STATUS_SUBMITTED_TO_LENDER,
            ].includes(data.status)
          ) {
            return checkArray?.loanDetails?.submission?.loanTermYears
          }
          return checkArray.loanDetails.settlement.loanTermYears
        } else if (checkArray?.loanDetails?.approval?.loanTermYears) {
          return checkArray.loanDetails.approval.loanTermYears
        } else if (checkArray?.loanDetails?.submission?.loanTermYears) {
          return checkArray.loanDetails.submission.loanTermYears
        } else {
          return data.loanTermYears
        }
      } else {
        return data.loanTermYears
      }
    },
    async regenerateAndUpadteLenderMatches() {
      if (!this.getMatchesGenerated) {
        await this.generateLenderMatchesFirst()
        this.sortLenderMatches()
        await this.$store.dispatch('applications/updateMatchesGenerated', {
          data: this.generatedLenderMatches,
        })
        this.setGlobalSettings()
        await this.$store.dispatch(
          'applications/updateLenderMatchesApplicationMatchesForm',
          {
            data: JSON.parse(JSON.stringify(this.applicationMatchesForm)),
          }
        )
        this.updateMatchesSettings()
      } else {
        this.generatedLenderMatches = JSON.parse(
          JSON.stringify(this.getGeneratedMatchesData)
        )
        this.sortLenderMatches()
      }
    },
    async updateMatchesSettings() {
      const matchesSettings = {
        globalSetting: { ...this.globalSettings },
        sortedBy: this.sortedBy,
        repayments: this.repayments,
      }
      await this.$store.dispatch('applications/setlenderMatchesSettings', {
        data: matchesSettings,
      })
    },
    onKeyDown(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which
      // disables up and down arrow key to avoid converting big numbers to exponential
      if (keyCode === 38 || keyCode === 40) {
        event.preventDefault()
      }
    },
    onKeyPress(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault()
      }
    },
    updateLoanTermYear() {
      this.getLoanTermYearsList(this.getGeneratedMatchesData)
    },
    async onIndividualMatchHandler(individualSettings) {
      this.matchSettingLoading = true
      this.isGenerateLenderMatches = false
      const userData = JSON.parse(localStorage.getItem('auth.user'))
      const { id } = this.$route.params

      const requestData = {
        rateAdjustment: parseFloat(individualSettings.brokerLoading),
        brokerOriginationFee: Number(individualSettings.originationFee),
        loanTerm: individualSettings.loanTermYears,
        actor: userData.email,
        system: FUEL_KEYWORD,
        context: CARCLARITY_KEYWORD,
        vRGId: { vehicleRequestGroup: id },
        vehicle: { ...this.applicationMatchesForm.vehicle },
        application: { ...this.applicationMatchesForm },
      }
      const { matches, facts } = await this.$store.dispatch(
        'applications/adjustIndividualMatch',
        {
          id,
          matchId: individualSettings?.adjustedMatchId,
          individualFilters: requestData,
          brokerOriginationFee: this.globalSettings.originationFee,
        }
      )
      if (matches.length) {
        const matchIndex = this.generatedLenderMatches?.findIndex((match) => {
          return (
            match?.matchId === individualSettings?.adjustedMatchId ||
            match?._id === individualSettings?.adjustedMatchId
          )
        })
        this.generatedLenderMatches[matchIndex] = matches[0]
        this.generatedLenderMatches[matchIndex].facts = facts
        this.generatedLenderMatches[matchIndex].individualSettings =
          individualSettings

        await this.$store.dispatch('applications/updateMatchesGenerated', {
          data: this.generatedLenderMatches,
        })
        this.updateLoanTermYear()
      }
      this.$nuxt.$emit('close-match-settingloading')
    },
    resetGlobalSettings() {
      if (this.getLenderMatchesSettings) {
        this.globalSettings = {
          ...this.getLenderMatchesSettings?.globalSetting,
        }
        this.sortedBy = this.getLenderMatchesSettings?.sortedBy
          ? this.getLenderMatchesSettings?.sortedBy
          : LABEL_REPAYMENT_LOW_TO_HIGH
        this.repayments = this.getLenderMatchesSettings?.repayments
          ? this.getLenderMatchesSettings?.repayments
          : LABEL_MONTHLY_OPTION_LABEL

        if (this.getLenderMatchesApplicationMatchesForm) {
          this.applicationMatchesForm = JSON.parse(
            JSON.stringify(this.getLenderMatchesApplicationMatchesForm)
          )
          this.updateLoanTermYear()
          this.assetsManuallySelected()
        }

        this.maxOriginationFee =
          Math.max(
            ...this.generatedLenderMatches?.map((o) => {
              return o?.commission?.brokerFeeLimit
            })
          ) || 990
      }
    },
    setGlobalSettings() {
      this.maxOriginationFee =
        Math.max(
          ...this.generatedLenderMatches?.map((o) => {
            return o?.commission?.brokerFeeLimit
          })
        ) || 990

      this.globalSettings.originationFee = 990
      this.globalSettings.loanTermYears = this.applicationMatchesForm
        ?.loanTermYears
        ? this.applicationMatchesForm?.loanTermYears
        : 1

      this.globalSettings.brokerLoading = this.isConsumer
        ? Math.max(
            ...this.generatedLenderMatches
              .map((o) => {
                return o?.commission?.dialDown
              })
              .filter((option) => option !== undefined)
          ) || 2
        : Math.max(
            ...this.generatedLenderMatches
              .map((o) => {
                return o?.commission?.maxBrokerIncomePctNAFPct
              })
              .filter((option) => option !== undefined)
          ) || 8.8
    },
    async submitGlobalSettings() {
      let isValid = false
      this.isGenerateLenderMatches = false
      this.globalSettingsLoading = true
      this.$refs.globalSettings.validate((result) => {
        return (isValid = result)
      })

      if (
        !isValid ||
        this.globalSettings.brokerLoading === undefined ||
        this.globalSettings.originationFee === undefined
      ) {
        this.globalSettingsLoading = false
        return
      }
      const userData = JSON.parse(localStorage.getItem('auth.user'))
      const { id, applicationId } = this.$route.params

      const requestData = {
        rateAdjustment: parseFloat(this.globalSettings.brokerLoading),
        brokerOriginationFee: Number(this.globalSettings.originationFee),
        loanTerm: this.globalSettings.loanTermYears,
        actor: userData.email,
        system: FUEL_KEYWORD,
        context: CARCLARITY_KEYWORD,
        vRGId: { vehicleRequestGroup: id },
        vehicle: { ...this.applicationMatchesForm.vehicle },
        application: { ...this.applicationMatchesForm },
      }

      const { facts, matches } = await this.$store.dispatch(
        'applications/globalSettingsLenderMatches',
        {
          id,
          applicationId,
          globalFilters: requestData,
        }
      )

      matches.map((match) => {
        match.facts = facts
        return match
      })
      await this.updateLenderMatches(matches)
      await this.$store.dispatch('applications/updateMatchesGenerated', {
        data: matches,
      })
      this.updateMatchesSettings()
      this.globalSettingsLoading = false
    },
    formatTooltip(val) {
      return val / 100
    },
    changeval(val) {
      this.globalSettings.brokerLoading = !isNaN(parseFloat(val))
        ? parseFloat(val)
        : 0
    },

    changeCondition() {
      const year = new Date().getFullYear()
      if (this.carCondition === this.LABEL_CONDITION_OPTIONS_NEW) {
        this.applicationMatchesForm.vehicle.year = year
      }
    },
    assetsManuallySelected() {
      if (this.manuallySelected) {
        this.vehicleValuation =
          this.applicationMatchesForm?.vehicle?.latestValuation?.retailPrice ||
          this.sharedData?.vehicle?.latestValuation?.retailPrice ||
          0
      } else {
        this.vehicleValuation =
          this.applicationMatchesForm?.vehicle?.latestValuation?.retailPrice ||
          this.sharedData?.vehicle?.latestValuation?.retailPrice ||
          0
      }
    },
    openSelectedByCustomerModal() {
      this.isLenderDetailsDialogOpen = true
    },
    quoteDetailsMenuCollapse(e) {
      if (e) {
        this.collapseMenuStatus = this.LABEL_COLLAPSE_MENU_HIDE
      } else {
        this.collapseMenuStatus = this.LABEL_COLLAPSE_MENU_SHOW
      }
    },
    sortLenderMatches() {
      if (this.generatedLenderMatches?.length) {
        if (this.sortedBy === LABEL_REPAYMENT_HIGH_TO_LOW)
          this.generatedLenderMatches = [...this.generatedLenderMatches].sort(
            (a, b) =>
              Number(b?.quote?.monthlyRepayment) -
              Number(a?.quote?.monthlyRepayment)
          )
        else if (this.sortedBy === LABEL_REPAYMENT_LOW_TO_HIGH) {
          this.generatedLenderMatches = [...this.generatedLenderMatches].sort(
            (a, b) =>
              Number(a?.quote?.monthlyRepayment) -
              Number(b?.quote?.monthlyRepayment)
          )
        } else if (this.sortedBy === LABEL_CUSTOMER_RATE_HIGH_TO_LOW) {
          this.generatedLenderMatches = [...this.generatedLenderMatches].sort(
            (a, b) => Number(a?.customerRate) - Number(b?.customerRate)
          )
        } else if (this.sortedBy === LABEL_TOTAL_INCOME_LOW_TO_HIGH) {
          this.generatedLenderMatches = [...this.generatedLenderMatches].sort(
            (a, b) => Number(b?.income?.total) - Number(a?.income?.total)
          )
        }
        this.updateMatchesSettings()
      }
    },
    async setModels() {
      this.modelDisabled = false
      this.yearDisabled = true
      this.modelTypeDisabled = true
      this.applicationMatchesForm.vehicle.familyCode = ''
      this.applicationMatchesForm.vehicle.year = ''
      this.applicationMatchesForm.vehicle.codeDescription = ''
      const response = await this.$store.dispatch(`applications/getAllModels`, {
        makeCode: this.applicationMatchesForm.vehicle.makeCode,
      })
      this.MODEL_OPTIONS = response.data
    },
    async setYears() {
      this.yearDisabled = false
      this.modelTypeDisabled = true
      this.applicationMatchesForm.vehicle.year = ''
      this.applicationMatchesForm.vehicle.codeDescription = ''

      const response = await this.$store.dispatch(`applications/getAllYears`, {
        makeCode: this.applicationMatchesForm.vehicle.makeCode,
        familyCode: this.applicationMatchesForm.vehicle.familyCode,
      })
      this.YEARS_OPTIONS = response.data
    },
    async setModelTypes(shouldRemoveCurrentValue = true) {
      this.modelTypeDisabled = false

      if (shouldRemoveCurrentValue)
        this.applicationMatchesForm.vehicle.codeDescription = ''

      const response = await this.$store.dispatch(
        `applications/getAllModelTypes`,
        {
          makeCode: this.applicationMatchesForm.vehicle.makeCode,
          familyCode: this.applicationMatchesForm.vehicle.familyCode,
          year: this.applicationMatchesForm.vehicle.year,
        }
      )
      this.MODEL_TYPES_OPTIONS = response.data

      if (
        this.applicationMatchesForm?.vehicle &&
        this.applicationMatchesForm?.vehicle?.assetType ===
          this.LABEL_ASSET_TYPE_OPTION_VEHICLE
      ) {
        if (this.MAKE.length > 0) {
          const getMakeDescription = this.MAKE.filter(
            (checkMakeCode) =>
              checkMakeCode.makeCode ===
              this.applicationMatchesForm.vehicle.makeCode
          )

          if (getMakeDescription.length > 0) {
            this.applicationMatchesForm.vehicle.makeDescription =
              getMakeDescription[0].description
          }
        }

        if (this.MODEL_OPTIONS.length > 0) {
          const getFamilyDescription = this.MODEL_OPTIONS.filter(
            (checkFamilyCode) =>
              checkFamilyCode.familyCode ===
              this.applicationMatchesForm.vehicle.familyCode
          )

          if (getFamilyDescription.length > 0) {
            this.applicationMatchesForm.vehicle.familyDescription =
              getFamilyDescription[0].description
          }
        }

        if (this.MODEL_TYPES_OPTIONS.length > 0) {
          const getModelTypeDescription = this.MODEL_TYPES_OPTIONS.filter(
            (checkModelType) =>
              checkModelType.code ===
              this.applicationMatchesForm.vehicle.codeDescription
          )

          if (getModelTypeDescription.length > 0) {
            this.applicationMatchesForm.vehicle.code =
              getModelTypeDescription[0].code
            this.applicationMatchesForm.vehicle.codeDescription =
              getModelTypeDescription[0].description
          }
        }
      }

      const payload = {
        code: this.applicationMatchesForm?.vehicle?.code,
        codeDescription: this.applicationMatchesForm?.vehicle?.description,
        familyCode: this.applicationMatchesForm?.vehicle?.familyCode,
        familyDescription: this.applicationMatchesForm.vehicle?.familyCode,
        makeCode: this.applicationMatchesForm.vehicle?.makeCode,
        type: VEHICLE_TYPE_ASSET,
        year: this.applicationMatchesForm.vehicle?.year.toString(),
        yearDescription: this.applicationMatchesForm.vehicle?.year.toString(),
      }
      const latestValuationResponse = await this.$store.dispatch(
        `applications/getCarEstimatePrice`,
        { payload }
      )
      if (latestValuationResponse?.status === 200 && response?.data) {
        this.vehicleValuation =
          latestValuationResponse?.data?.latestValuation?.tradeInAvgCustomer
      } else {
        this.vehicleValuation =
          this.sharedData?.vehicle?.latestValuation?.retailPrice || 0
      }
    },
    async filterMatchesExclusion(matches) {
      const referrerAccount = this.getReferrerAccount
      let exclusionList = []
      if (referrerAccount) {
        const response = await this.$store.dispatch(
          'applications/getReferrerAccount',
          { accountId: referrerAccount }
        )
        if (response?.data?.partnerId) {
          exclusionList =
            PARTNER_LENDER_EXCLUSION_LIST[response?.data?.partnerId] || []
        }
      }
      return matches.filter(
        ({ lenderName }) => !exclusionList.includes(lenderName)
      )
    },
    async regenerateLenderMatches() {
      const { id: vrgId, applicationId } = this.$route.params
      const loadingInstance = Loading.service({ fullscreen: true })
      try {
        await this.$store.dispatch('applications/regenerateLenderMatches', {
          vrgId,
          applicationId,
        })
      } catch (err) {
        console.error(err)
      }
      loadingInstance.close()
    },
    async generateLenderMatchesFirst() {
      const { id, applicationId } = this.$route.params
      const loadingInstance = Loading.service({ fullscreen: true })
      try {
        if (
          this.applicationMatchesForm?.vehicle?.latestValuation?.retailPrice
        ) {
          this.applicationMatchesForm.vehicle.latestValuation.retailPrice =
            parseInt(this.vehicleValuation)
        }
        if (this.applicationMatchesForm?.tradeIn) {
          this.applicationMatchesForm.tradeIn.tradeInAmount = this.tradeInAmount
        } else {
          this.applicationMatchesForm = JSON.parse(
            JSON.stringify({
              ...this.applicationMatchesForm,
              tradeIn: { tradeInAmount: this.tradeInAmount },
            })
          )
        }
        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...this.sharedData.vehicle },
          application: { ...this.sharedData },
        }
        const { facts, matches } = await this.$store.dispatch(
          'applications/generateLenderFirstMatches',
          {
            id,
            applicationId,
            applicationMatchesFormData,
          }
        )
        if (facts) {
          this.factsResponse = facts
        }
        matches?.map((match) => {
          match.facts = facts
          return match
        })
        this.generatedLenderMatches = await this.filterMatchesExclusion(matches)
        this.setGlobalSettings()
        this.loanAmountTotal = this.fetchCommonLoginData(facts)?.loanAmount
        this.applicationMatchesForm.purchasePrice =
          this.fetchCommonLoginData(facts)?.purchasePrice
        this.applicationMatchesForm.vehicle.financeDepositAmount =
          this.fetchCommonLoginData(facts)?.deposit
        this.tradeInAmount = this.fetchCommonLoginData(facts)?.tradeInAmount
        this.applicationMatchesForm.balloonPct =
          this.fetchCommonLoginData(facts)?.balloonPct
        this.applicationMatchesForm.balloonAmount = facts?.balloonAmount
        this.applicationMatchesForm.loanTermYears = JSON.parse(
          JSON.stringify(this.fetchCommonLoginData(facts)?.loanTermYears)
        )
        this.applicationMatchesForm.vehicle.customerNote =
          facts?.vehicle?.customerNote
        this.applicationMatchesForm.vehicle.makeCode = facts?.vehicle?.makeCode
        this.applicationMatchesForm.vehicle.familyCode =
          facts?.vehicle?.familyCode
        this.applicationMatchesForm.vehicle.year = facts?.vehicle?.year
        this.applicationMatchesForm.vehicle.codeDescription =
          facts?.vehicle?.codeDescription
        this.applicationMatchesForm.vehicle.assetDetail =
          facts?.vehicle?.assetDetail
        this.applicationMatchesForm.vehicle.year = facts?.vehicle?.year
        this.applicationMatchesForm.vehicle.condition =
          facts?.vehicle?.condition
        this.applicationMatchesForm.vehicle.purchaseSource =
          facts?.vehicle?.purchaseSource
        this.vehicleValuation = facts?.vehicleValuation
        this.applicationMatchesForm.vehicle.assetType = facts?.assetType
        if (this.applicationMatchesForm?.vehicle?.latestValuation) {
          this.applicationMatchesForm.vehicle.latestValuation.retailPrice =
            facts?.vehicle?.latestValuation?.retailPrice
        } else {
          this.applicationMatchesForm.vehicle.latestValuation = {}
          this.applicationMatchesForm.vehicle.latestValuation.retailPrice =
            facts?.vehicle?.latestValuation?.retailPrice
        }
      } catch (err) {
        console.error(err)
      }
      loadingInstance.close()
    },
    async generateLenderMatches() {
      let isValid = false
      this.isGenerateLenderMatches = true
      this.$refs.applicationMatchesForm.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      let applicationMatchesFormData = {}
      const { id, applicationId } = this.$route.params
      const loadingInstance = Loading.service({ fullscreen: true })
      try {
        if (
          this.applicationMatchesForm?.vehicle?.latestValuation?.retailPrice
        ) {
          this.applicationMatchesForm.vehicle.latestValuation.retailPrice =
            parseInt(this.vehicleValuation)
        }

        if (this.applicationMatchesForm?.tradeIn) {
          this.applicationMatchesForm.tradeIn.tradeInAmount = this.tradeInAmount
        } else {
          this.applicationMatchesForm = JSON.parse(
            JSON.stringify({
              ...this.applicationMatchesForm,
              tradeIn: { tradeInAmount: this.tradeInAmount },
            })
          )
        }
        if (this.applicationMatchesForm.loanTermYears) {
          this.applicationMatchesForm.loanTermMonths =
            this.applicationMatchesForm.loanTermYears * 12
        }
        if (this.vehicleValuation) {
          this.applicationMatchesForm.vehicle.vehicleValuation =
            this.vehicleValuation
        }

        this.applicationMatchesForm = await convertValuesToNumber(
          this.applicationMatchesForm,
          ['purchasePrice', 'financeDepositAmount', 'balloonPct']
        )

        if (this.applicationMatchesForm?.tradeIn?.tradeInAmount) {
          this.applicationMatchesForm.tradeIn.tradeInAmount = Number(
            this.applicationMatchesForm.tradeIn.tradeInAmount
          )
        }

        if (this.applicationMatchesForm?.vehicle?.financeDepositAmount) {
          this.applicationMatchesForm.vehicle.financeDepositAmount = Number(
            this.applicationMatchesForm.vehicle.financeDepositAmount
          )
        }

        this.applicationMatchesForm.loanAmount = Number(this.loanAmountTotal)
        applicationMatchesFormData = {
          ...applicationMatchesFormData,
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...this.applicationMatchesForm.vehicle },
          application: { ...this.applicationMatchesForm },
        }
        const response = await this.$store.dispatch(
          'applications/generateMatches',
          {
            id,
            applicationId,
            applicationMatchesFormData,
            generatedLenderMatches: this.generatedLenderMatches,
          }
        )
        response?.matches.map((match) => {
          match.facts = response?.facts
          return match
        })
        this.generatedLenderMatches = await this.filterMatchesExclusion(
          response?.matches
        )
        this.sortLenderMatches()
        this.setGlobalSettings()
        this.updateMatchesSettings()
        await this.$store.dispatch(
          'applications/updateLenderMatchesApplicationMatchesForm',
          {
            data: JSON.parse(JSON.stringify(this.applicationMatchesForm)),
          }
        )
        await this.$store.dispatch('applications/updateMatchesGenerated', {
          data: this.generatedLenderMatches,
        })
        this.factsResponse = response?.facts
        this.getLoanTermYearsList(response?.facts)

        this.$nuxt.$emit('update-match-settings', true)
        this.isGenerateLenderMatches = false
      } catch (err) {
        this.isGenerateLenderMatches = false
        console.error(err)
      }
      loadingInstance.close()
    },
    getLoanTermYearsList(obj) {
      if (obj.length) {
        this.loanTermYearsList = _.range(
          Math.min(
            ...obj
              .map((o) => {
                return o?.minLoanTermYears
              })
              .filter((option) => option !== undefined)
          ),
          Math.max(
            ...obj
              .map((o) => {
                return o?.maxLoanTermYears
              })
              .filter((option) => option !== undefined)
          ) + 1
        )
      }
    },
    async updateLenderMatches(val) {
      if (val?.length >= 0) {
        const matches = JSON.parse(JSON.stringify(val))
        this.generatedLenderMatches = await this.filterMatchesExclusion(matches)
        this.updateLoanTermYear()
        this.$nuxt.$emit('update-match-settings', false)
        this.sortLenderMatches()
      }
    },

    async calculateBalloonAmt() {
      this.btnLoading = false
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        if (
          (this.manuallySelected && this.vehicleValuation) ||
          this.applicationMatchesForm?.vehicle?.assetType ===
            LABEL_ASSET_TYPE_OPTION_OTHER
        ) {
          this.vehicleValuation = this.applicationMatchesForm.purchasePrice
        }

        this.applicationMatchesForm.balloonAmount =
          (await getBalloonAmountWithApi(
            this.applicationMatchesForm.purchasePrice || 0,
            this.applicationMatchesForm?.balloonPct || 0
          )) || 0
        this.btnLoading = true
      }, 600)
    },
    async calculateBalloonPerc() {
      this.btnLoading = false
      clearTimeout(this.customDebounce)
      this.customDebounce = setTimeout(async () => {
        this.applicationMatchesForm.balloonPct =
          (await getBalloonPctWithApi(
            this.applicationMatchesForm.purchasePrice || 0,
            this.applicationMatchesForm?.balloonAmount || 0
          )) || 0
        this.btnLoading = true
      }, 600)
    },

    numberWithCommas(x) {
      return toCurrency(x)
    },
    totalMonths(data) {
      let month = 0
      if (data) {
        month = differenceInCalendarMonths(new Date(), new Date(data))
      }
      return month
    },
    checkAssetType() {
      if (
        this.applicationMatchesForm.vehicle.assetType ===
        this.sharedData.vehicle.assetType
      ) {
        this.applicationMatchesForm.vehicle.customerNote =
          this.applicationMatchesForm.vehicle.customerNote
        this.vehicleValuation =
          this.sharedData?.vehicle?.latestValuation?.retailPrice || 0
      } else {
        this.applicationMatchesForm.vehicle.customerNote = ''
        this.vehicleValuation = this.applicationMatchesForm.purchasePrice || 0
      }
    },
    async setMakes() {
      const { data = [] } = await this.$store.dispatch(
        `applications/getAllMakes`
      )
      this.MAKE = data
    },
  },
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
  },
}
