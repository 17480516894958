import _ from 'lodash'
import {
  ERROR_MESSAGE_LOGIN_REQUIRED,
  LEAD_CREATED_SUCCESSFULLY,
} from '@/constants'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  async fetchVehicleRequestGroupTable(
    { commit },
    { status = 'All', currentPage = 1, search = '' }
  ) {
    try {
      let requestUrl = '/vehicle-request-groups/requests?'
      if (status !== 'All') {
        requestUrl += '&status=' + status
      }
      if (currentPage) {
        requestUrl += '&page=' + currentPage
      }
      if (search) {
        requestUrl += '&requestId=' + search
      }
      commit('startTableLoading')
      const requests = await this.$axios.get(requestUrl)
      const { data } = requests
      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })

      let tableData = requests.data.docs.map((vrg) => {
        vrg.customer = vrg.user.firstName + ' ' + vrg.user.lastName
        vrg.createdAt = new Date(vrg.buying.requests.createdAt).toDateString()
        if (!vrg.buying.requests.status) {
          vrg.buying.requests.status = 'Open'
        }
        return vrg
      })
      tableData = _.orderBy(
        tableData,
        'buying.requests.completedLeadApplicationAt',
        'desc'
      )
      commit('setVehicleRequestGroupsTable', tableData)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('stopTableLoading')
    }
  },
  async createApplicationFromCrm(
    {},
    { leadId, opportunityId, opportunityApplicationType }
  ) {
    try {
      let requestUrl = `/vehicle-request-groups/finance/applications/create-from-salesforce?leadId=${leadId}&opportunityId=${opportunityId}&opportunityApplicationType=${opportunityApplicationType}`
      const response = await this.$axios.post(requestUrl)
      const { data: responseData } = response
      const { message = null } = responseData
      const modalOptions = {
        title: 'Success',
        message: LEAD_CREATED_SUCCESSFULLY,
      }
      if (message) {
        modalOptions.title = 'Warning'
        modalOptions.message = message || ''
        this.$notify.warning(modalOptions)
      } else {
        this.$notify.success(modalOptions)
      }
      return responseData
    } catch (error) {
      let errorMsg = null
      if ([401, 403].includes(error?.response?.status)) {
        errorMsg = ERROR_MESSAGE_LOGIN_REQUIRED
      } else {
        errorMsg = error?.response?.data?.error
      }

      this.$notify.error({
        title: 'Error',
        message: errorMsg,
      })
    }
  },
}
