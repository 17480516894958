var render = function () {
  var _vm$addressesData, _vm$addressesData2, _vm$addressesData3, _vm$addressesData4, _vm$addressesData5, _vm$addressesData6, _vm$addressesData7, _vm$addressesData8, _vm$addressesData9, _vm$addressesData10, _vm$addressesData11, _vm$addressesData14;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_vm.addressesData ? _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "el-alert text-black is-light mb-6",
    class: (_vm$addressesData = _vm.addressesData) !== null && _vm$addressesData !== void 0 && _vm$addressesData.eSignRequestedAt ? '' : 'bg-primary el-alert--primary',
    style: (_vm$addressesData2 = _vm.addressesData) !== null && _vm$addressesData2 !== void 0 && _vm$addressesData2.eSignAt || (_vm$addressesData3 = _vm.addressesData) !== null && _vm$addressesData3 !== void 0 && _vm$addressesData3.eSignRequestedAt ? 'background: rgba(0, 184, 73, 0.1);' : '',
    attrs: {
      "role": "alert",
      "data-selector": "pcq-alert"
    }
  }, [(_vm$addressesData4 = _vm.addressesData) !== null && _vm$addressesData4 !== void 0 && _vm$addressesData4.eSignAt ? _c('img', {
    attrs: {
      "src": require("@/assets/icons/ok.svg")
    }
  }) : _vm._e(), !((_vm$addressesData5 = _vm.addressesData) !== null && _vm$addressesData5 !== void 0 && _vm$addressesData5.eSignAt) && (_vm$addressesData6 = _vm.addressesData) !== null && _vm$addressesData6 !== void 0 && _vm$addressesData6.eSignRequestedAt ? _c('img', {
    attrs: {
      "src": require("@/assets/icons/success_right_arrow.svg")
    }
  }) : _vm._e(), _vm._v(" "), !_vm.addressesData.eSignRequestedAt ? _c('i', {
    staticClass: "applicationAddESignCls el-icon-warning-outline"
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content pl-6"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "pcq-alert-title"
    }
  }, [_vm._v(_vm._s((_vm$addressesData7 = _vm.addressesData) !== null && _vm$addressesData7 !== void 0 && _vm$addressesData7.eSignAt ? _vm.TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL : (_vm$addressesData8 = _vm.addressesData) !== null && _vm$addressesData8 !== void 0 && _vm$addressesData8.eSignRequestedAt ? _vm.TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL : _vm.TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "pcq-alert-description"
    }
  }, [_vm._v(_vm._s((_vm$addressesData9 = _vm.addressesData) !== null && _vm$addressesData9 !== void 0 && _vm$addressesData9.eSignAt ? ((_vm.DOCUMENTS_SIGNED_AT) + " " + (_vm.format(new Date((_vm$addressesData10 = _vm.addressesData) === null || _vm$addressesData10 === void 0 ? void 0 : _vm$addressesData10.eSignAt), 'dd/MM/yyyy hh:mm aaa'))) : (_vm$addressesData11 = _vm.addressesData) !== null && _vm$addressesData11 !== void 0 && _vm$addressesData11.eSignRequestedAt ? ((_vm.DOCUMENTS_SENT_AT) + " " + (_vm.format(new Date(_vm.addressesData.eSignRequestedAt), 'dd/MM/yyyy hh:mm aaa'))) : _vm.LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_DESCRIPTION_COMMERCIAL))])]), _vm._v(" "), !_vm.addressesData.eSignRequestedAt ? _c('el-button', {
    staticClass: "w-auto ml-auto",
    attrs: {
      "type": "primary",
      "data-selector": "pcq-send-btn"
    },
    on: {
      "click": function ($event) {
        var _vm$addressesData12, _vm$addressesData12$a, _vm$addressesData13, _vm$addressesData13$p;

        return _vm.sendESignEmailRequest(_vm.$route.query.secondsubtab || !_vm.isPrimary ? (_vm$addressesData12 = _vm.addressesData) === null || _vm$addressesData12 === void 0 ? void 0 : (_vm$addressesData12$a = _vm$addressesData12.applicant) === null || _vm$addressesData12$a === void 0 ? void 0 : _vm$addressesData12$a._id : (_vm$addressesData13 = _vm.addressesData) === null || _vm$addressesData13 === void 0 ? void 0 : (_vm$addressesData13$p = _vm$addressesData13.primaryApplicant) === null || _vm$addressesData13$p === void 0 ? void 0 : _vm$addressesData13$p._id);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SEND))]) : _vm._e(), _vm._v(" "), (_vm$addressesData14 = _vm.addressesData) !== null && _vm$addressesData14 !== void 0 && _vm$addressesData14.eSignAt ? _c('el-dropdown', {
    staticClass: "ml-auto",
    on: {
      "command": _vm.handleDocumentsUrl
    }
  }, [_c('el-button', {
    attrs: {
      "disabled": !_vm.isComplianceSignedDocPresent && !_vm.isDocusignCertPresent,
      "plain": "",
      "data-selector": "privacy-view-btn"
    }
  }, [_vm._v("\n          View"), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c('el-dropdown-menu', {
    staticClass: "text-center",
    staticStyle: {
      "width": "6rem"
    },
    attrs: {
      "slot": "dropdown",
      "disabled": !_vm.isComplianceSignedDocPresent && !_vm.isDocusignCertPresent,
      "data-selector": "privacy-view-options-container"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "disabled": !_vm.isComplianceSignedDocPresent,
      "command": _vm.LABEL_FOR_PRIVACY_FORM
    }
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_FOR_PRIVACY_GUIDE) + "\n          ")]), _vm._v(" "), _c('el-dropdown-item', {
    attrs: {
      "disabled": !_vm.isDocusignCertPresent,
      "command": _vm.LABEL_FOR_DOCUSIGN_CERT
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_DOCUSIGN_CERT))])], 1)], 1) : _vm._e()], 1)]) : _vm._e(), _vm._v(" "), _vm.totalMonths < 36 ? _c('el-alert', {
    staticClass: "mb-4 font-extrabold",
    attrs: {
      "title": "Minimum 3 years of residency is required.",
      "type": "warning",
      "show-icon": "",
      "data-selector": "residency-alert"
    }
  }) : _vm._e(), _vm._v(" "), _vm.addressesData ? _c('el-card', {
    staticClass: "application-form address-tab-form"
  }, [Array.isArray(_vm.addressesData.addresses) && _vm.addressesData.addresses.length > 0 ? _c('el-form', {
    ref: "addressesData",
    attrs: {
      "model": _vm.addressesData
    }
  }, _vm._l(_vm.addressesData.addresses, function (singleAddress, index) {
    return _c('div', {
      key: index,
      attrs: {
        "data-selector": 'address_container_' + index
      }
    }, [_c('div', {
      class: index == 0 ? 'pt-0 el-card__header' : 'el-card__header'
    }, [_c('div', {
      staticClass: "clearfix items-center flex justify-between",
      attrs: {
        "slot": "header"
      },
      slot: "header"
    }, [index == 0 ? _c('p', {
      staticClass: "text-xs font-extrabold",
      attrs: {
        "data-selector": "address-type"
      }
    }, [_vm._v("Current address\n            ")]) : _c('p', {
      staticClass: "text-xs font-extrabold",
      attrs: {
        "data-selector": "address-type"
      }
    }, [_vm._v("Previous address " + _vm._s(index))]), _vm._v(" "), _c('el-link', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index >= 1,
        expression: "index >= 1"
      }],
      staticClass: "font-extrabold",
      attrs: {
        "underline": false,
        "type": "danger",
        "data-selector": 'remove-address_' + index
      },
      on: {
        "click": function ($event) {
          return _vm.removeAddress(index);
        }
      }
    }, [_vm._v("Remove")])], 1)]), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24
      }
    }, [_c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Enter full address"
      }
    }, [_c('div', {
      staticClass: "el-input el-input--suffix"
    }, [_vm.isDefaultAddress ? _c('VueGoogleAutocomplete', {
      staticClass: "form-control el-input el-input__inner",
      attrs: {
        "data-selector": "full-address-input",
        "id": 'googleSearch_' + _vm.addressesData.addresses[index].address,
        "placeholder": _vm.EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
        "country": ['au'],
        "name": "googleAddress",
        "value": _vm.doesAddressHaveCompleteMeta(_vm.addressesData.addresses[index]) ? _vm.addressesData.addresses[index].address : '',
        "preloaded-data": _vm.doesAddressHaveCompleteMeta(_vm.addressesData.addresses[index]) ? _vm.addressesData.addresses[index].address : ''
      },
      on: {
        "placechanged": function ($event) {
          return _vm.getAddressData($event, index);
        }
      }
    }) : _vm._e(), _vm._v(" "), _c('span', {
      staticClass: "el-input__suffix"
    }, [_c('i', {
      staticClass: "el-input__icon el-icon-search font-extrabold text-black"
    })])], 1)])], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Unit / Street",
        "prop": 'addresses.' + index + '.addressLine1',
        "rules": _vm.rules.addressLine1,
        "data-selector": "unit-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "unit-input"
      },
      model: {
        value: _vm.addressesData.addresses[index].addressLine1,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "addressLine1", $$v);
        },
        expression: "addressesData.addresses[index].addressLine1"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Suburb",
        "prop": 'addresses.' + index + '.city',
        "rules": _vm.rules.city,
        "data-selector": "suburb-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "suburb-input"
      },
      model: {
        value: _vm.addressesData.addresses[index].city,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "city", $$v);
        },
        expression: "addressesData.addresses[index].city"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [singleAddress ? _c('el-form-item', {
      staticClass: "mb-0",
      attrs: {
        "label": "State",
        "prop": 'addresses.' + index + '.state',
        "rules": _vm.stateValidation(_vm.addressesData.addresses[index].country),
        "data-selector": "state-input-container"
      }
    }, [_vm.addressesData.addresses[index].country === _vm.LABEL_AUSTRALIA ? _c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "select"
      },
      model: {
        value: _vm.addressesData.addresses[index].state,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "state", $$v);
        },
        expression: "addressesData.addresses[index].state"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": "state-options-container"
      }
    }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)]) : _c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "select",
        "disabled": "true"
      }
    }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Postcode",
        "prop": 'addresses.' + index + '.postcode',
        "rules": _vm.rules.postcode,
        "data-selector": "postcode-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "postcode-input"
      },
      model: {
        value: _vm.addressesData.addresses[index].postcode,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "postcode", $$v);
        },
        expression: "addressesData.addresses[index].postcode"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Country",
        "prop": 'addresses.' + index + '.country',
        "data-selector": "country-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "select country"
      },
      on: {
        "input": function ($event) {
          return _vm.setCountry(_vm.addressesData.addresses[index]);
        }
      },
      model: {
        value: _vm.addressesData.addresses[index].country,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "country", $$v);
        },
        expression: "addressesData.addresses[index].country"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": "country-options-container"
      }
    }, _vm._l(_vm.COUNTRIES, function (data) {
      return _c('el-option', {
        key: data.code,
        attrs: {
          "label": data.value,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1)], 1), _vm._v(" "), _c('el-row', [_c('el-col', {
      class: [_vm.addressesData.addresses.length === 1 ? 'pt-5 border-t border-b border-light-grey' : 'pt-5 border-t border-light-grey'],
      attrs: {
        "sm": 24
      }
    }, [_c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "md": 12,
        "lg": 6
      }
    }, [singleAddress ? _c('el-form-item', {
      attrs: {
        "label": "Time at address (Years)",
        "prop": 'addresses.' + index + '.atYears',
        "rules": _vm.rules.atYears,
        "data-selector": "years-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "select"
      },
      model: {
        value: _vm.addressesData.addresses[index].atYears,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "atYears", $$v);
        },
        expression: "addressesData.addresses[index].atYears"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": "years-options-container"
      }
    }, _vm._l(_vm.dateRange.YEAR_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.value,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "md": 12,
        "lg": 6
      }
    }, [singleAddress ? _c('el-form-item', {
      attrs: {
        "label": "Time at address (Months)",
        "prop": 'addresses.' + index + '.atAdditionalMonths',
        "rules": _vm.rules.atAdditionalMonths,
        "data-selector": "months-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "select"
      },
      model: {
        value: _vm.addressesData.addresses[index].atAdditionalMonths,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "atAdditionalMonths", $$v);
        },
        expression: "\n                      addressesData.addresses[index].atAdditionalMonths\n                    "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": "months-options-container"
      }
    }, _vm._l(_vm.dateRange.MONTH_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.value,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Situation",
        "prop": 'addresses.' + index + '.livingSituation',
        "rules": _vm.rules.livingSituation,
        "data-selector": "situation-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "select"
      },
      model: {
        value: _vm.addressesData.addresses[index].livingSituation,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "livingSituation", $$v);
        },
        expression: "addressesData.addresses[index].livingSituation"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": "situation-options-container"
      }
    }, _vm._l(_vm.LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD, function (data) {
      return _c('el-option', {
        key: data.value,
        attrs: {
          "label": data.value,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _vm.checklivingSituation(_vm.addressesData.addresses[index].livingSituation) ? _c('el-col', {
      staticClass: "mb-6 flex items-center",
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('span', {
      staticClass: "text-sm"
    }, [_vm._v(_vm._s(_vm.LABEL_RENTING_THROUGH_AGENT))]), _vm._v(" "), _c('el-form-item', {
      staticClass: "ml-auto inline-block mb-0",
      attrs: {
        "prop": 'addresses.' + index + '.areYouRentingThroughAgent'
      }
    }, [_c('el-radio-group', {
      model: {
        value: _vm.addressesData.addresses[index].areYouRentingThroughAgent,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "areYouRentingThroughAgent", $$v);
        },
        expression: "\n                      addressesData.addresses[index].areYouRentingThroughAgent\n                    "
      }
    }, [_c('el-radio-button', {
      attrs: {
        "label": true,
        "data-selector": "rta-yes"
      }
    }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
      attrs: {
        "label": false,
        "data-selector": "rta-no"
      }
    }, [_vm._v("No")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.checklivingSituation(_vm.addressesData.addresses[index].livingSituation) && _vm.addressesData.addresses[index].areYouRentingThroughAgent === true ? _c('div', {
      staticClass: "full-width flex"
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12,
        "data-selector": "agent-name-input-container"
      }
    }, [_c('text-input', {
      attrs: {
        "value": _vm.addressesData.addresses[index].realEstateAgentName,
        "form-prop": 'addresses.' + index + '.realEstateAgentName',
        "form-rule": [{
          max: 30,
          message: _vm.ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
          trigger: ['blur', 'change']
        }],
        "label": "Real estate agent name",
        "data-selector": "agent-name-input"
      },
      model: {
        value: _vm.addressesData.addresses[index].realEstateAgentName,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "realEstateAgentName", $$v);
        },
        expression: "\n                      addressesData.addresses[index].realEstateAgentName\n                    "
      }
    })], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12,
        "data-selector": "agent-number-input-container"
      }
    }, [_c('text-input', {
      attrs: {
        "value": _vm.addressesData.addresses[index].realEstateAgentPhoneNumber,
        "form-prop": 'addresses.' + index + '.realEstateAgentPhoneNumber',
        "accept-only-numbers": true,
        "form-rule": [{
          pattern: /^[0-9]/,
          trigger: ['blur', 'change'],
          message: _vm.ERROR_MESSAGE_FOR_NUMBER_ONLY
        }, {
          max: 10,
          message: _vm.ERROR_MESSAGE_FOR_NUMBER_LENGTH,
          trigger: ['blur', 'change']
        }],
        "label": "Real estate agent number",
        "data-selector": "agent-number-input"
      },
      model: {
        value: _vm.addressesData.addresses[index].realEstateAgentPhoneNumber,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "realEstateAgentPhoneNumber", $$v);
        },
        expression: "\n                      addressesData.addresses[index]\n                        .realEstateAgentPhoneNumber\n                    "
      }
    })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.checklivingSituation(_vm.addressesData.addresses[index].livingSituation) && _vm.addressesData.addresses[index].areYouRentingThroughAgent === false ? _c('el-col', {
      staticClass: "flex items-center",
      attrs: {
        "sm": 48,
        "lg": 42
      }
    }, [_c('el-form-item', {
      staticClass: "gentNotes",
      attrs: {
        "label": _vm.LABEL_FOR_NOTES,
        "prop": "notesAboutTenant"
      }
    }, [_c('el-input', {
      attrs: {
        "type": "textarea",
        "autosize": {
          minRows: 5,
          maxRows: 5
        },
        "cols": 51,
        "maxlength": "200",
        "placeholder": _vm.PLACEHOLDER_WRITE_ANY_NOTES,
        "data-selector": "notes-textarea"
      },
      model: {
        value: _vm.addressesData.addresses[index].notesAboutTenant,
        callback: function ($$v) {
          _vm.$set(_vm.addressesData.addresses[index], "notesAboutTenant", $$v);
        },
        expression: "addressesData.addresses[index].notesAboutTenant"
      }
    })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), 0) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('el-card', {
    staticClass: "application-form child-pt-0"
  }, [_vm.addressForm ? [_c('el-button', {
    staticClass: "font-extrabold w-auto center",
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus",
      "plain": "",
      "data-selector": "add-address-btn"
    },
    on: {
      "click": _vm.addAddress
    }
  }, [_vm._v("Add Address")])] : _vm._e()], 2), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v("Back")])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "disabled": _vm.totalMonths < 36,
      "plain": "",
      "data-selector": "save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v("Save")]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "disabled": _vm.totalMonths < 36,
      "data-selector": "save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v("Save & Next")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }