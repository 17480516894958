export const differenceInDays = (targetDate) => {
  const currentDate = new Date()
  const diffTime = new Date(targetDate) - currentDate
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const lastDayOfMonth = () => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

/*
 * Returns a new date with timezone offset included into UTC of date
 */
export const toUTCWithoutOffset = (date = new Date()) => {
  if (!(date instanceof Date)) {
    throw new TypeError('Date is not a Date object')
  }
  const month = Number(date.getMonth())
  const day = date.getDate()
  const year = date.getFullYear()
  const dateWithoutOffSet = new Date(year, month, day)

  return new Date(
    dateWithoutOffSet.getTime() -
      dateWithoutOffSet.getTimezoneOffset() * 60 * 1000
  )
}
