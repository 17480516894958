//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomVueNumeric from './CustomVueNumeric.vue'

export default {
  components: { CustomNumeric: CustomVueNumeric },
  props: {
    value: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '0' },
    min: { type: Number, default: 0 },
    max: { type: Number, default: undefined },
    precision: { type: Number, default: 2 },
    disabled: { type: Boolean, default: false },
    inputClass: { type: String, default: '' },
    acceptOnlyNumbers: { type: Boolean, default: false },
    formRules: {
      type: Array,
      default: () => [],
    },
    formProp: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    formClassName: {
      type: String,
      default: '',
    },
    emptyValue: {
      type: [Number, Object],
      default: 0,
    },
    dataSelector: {
      type: String,
      default: '',
    },
  },
  methods: {
    onKeyDown(event) {
      if (this.acceptOnlyNumbers) {
        const keyCode = event.keyCode ? event.keyCode : event.which
        console.log('YES', keyCode)

        // disables up and down arrow key to avoid converting big numbers to exponential
        if (keyCode === 38 || keyCode === 40) {
          event.preventDefault()
        }
      }
    },
    onKeyPress(event) {
      if (this.acceptOnlyNumbers) {
        const keyCode = event.keyCode ? event.keyCode : event.which
        console.log('YES - 1', keyCode)
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          event.preventDefault()
        }
      }
    },
    onInput(event) {
      this.$emit('input', event)
    },
    onChange(event) {
      this.$emit('change', event)
    },
    onBlur(event) {
      this.$emit('blur', event)
    },
  },
}
