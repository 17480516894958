var render = function () {
  var _vm$loanData, _vm$loanData$appData, _vm$loanData$appData$, _vm$loanData$appData2, _vm$loanData$appData3, _vm$loanData$appData4, _vm$loanData$appData5, _vm$loanData$appData6, _vm$loanData$appData7, _vm$loanData$appData8, _vm$loanData$appData9, _vm$loanData$appData10, _vm$loanData$appData11, _vm$loanData$appData12, _vm$loanData$appData13, _vm$loanData$appData14, _vm$loanData$appData15, _vm$loanData$appData16, _vm$sharedData$insura, _vm$sharedData, _vm$sharedData$insura2, _vm$sharedData$insura3, _Date, _vm$sharedData2, _vm$sharedData2$insur, _vm$sharedData$insura4, _vm$sharedData$insura5, _vm$sharedData$insura6, _vm$sharedData$insura7, _vm$sharedData$insura8, _vm$sharedData$insura9, _vm$sharedData$insura10, _vm$loanData$appData17, _vm$loanData$appData18, _vm$loanData$appData19, _vm$loanData$appData20, _vm$loanData$appData21, _vm$loanData$appData22, _vm$loanData$appData23, _vm$loanData$appData24, _vm$loanData$appData25;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loanDetailsForm ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    staticClass: "loan-details-form"
  }, [_c('el-form', {
    ref: "loanDetailsForm",
    attrs: {
      "model": _vm.loanData
    }
  }, [(_vm$loanData = _vm.loanData) !== null && _vm$loanData !== void 0 && (_vm$loanData$appData = _vm$loanData.appData) !== null && _vm$loanData$appData !== void 0 && (_vm$loanData$appData$ = _vm$loanData$appData.loanDetails) !== null && _vm$loanData$appData$ !== void 0 && _vm$loanData$appData$.settlement ? _c('el-card', {
    staticClass: "application-form pb-child-none mb-4"
  }, [_c('div', {
    staticClass: "items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "st-warranty-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_WARRANTY)), _vm.loanData.appData.loanDetails.settlement.warranty.provider && !_vm.disableWarranty ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "st-warranty-edit-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openWarrantyForm(_vm.FORM_TYPE_EDIT);
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))]) : _vm._e()])]), _vm._v(" "), !_vm.loanData.appData.loanDetails.settlement.warranty.provider ? _c('div', {
    staticClass: "border-t border-light-grey pt-4"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "icon": "el-icon-plus",
      "plain": "",
      "disabled": _vm.disableWarranty,
      "data-selector": "st-add-warranty-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openWarrantyForm(_vm.FORM_TYPE_ADD);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ADD_WARRANTY))])], 1) : _vm._e(), _vm._v(" "), (_vm$loanData$appData2 = _vm.loanData.appData) !== null && _vm$loanData$appData2 !== void 0 && (_vm$loanData$appData3 = _vm$loanData$appData2.loanDetails) !== null && _vm$loanData$appData3 !== void 0 && (_vm$loanData$appData4 = _vm$loanData$appData3.settlement) !== null && _vm$loanData$appData4 !== void 0 && (_vm$loanData$appData5 = _vm$loanData$appData4.warranty) !== null && _vm$loanData$appData5 !== void 0 && _vm$loanData$appData5.provider ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_vm.loanData.appData ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_PROVIDER))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-w-provider"
    }
  }, [_vm._v("\n              " + _vm._s(((_vm$loanData$appData6 = _vm.loanData.appData) === null || _vm$loanData$appData6 === void 0 ? void 0 : (_vm$loanData$appData7 = _vm$loanData$appData6.loanDetails) === null || _vm$loanData$appData7 === void 0 ? void 0 : (_vm$loanData$appData8 = _vm$loanData$appData7.settlement) === null || _vm$loanData$appData8 === void 0 ? void 0 : (_vm$loanData$appData9 = _vm$loanData$appData8.warranty) === null || _vm$loanData$appData9 === void 0 ? void 0 : (_vm$loanData$appData10 = _vm$loanData$appData9.providerData) === null || _vm$loanData$appData10 === void 0 ? void 0 : _vm$loanData$appData10.businessName) || _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_PRODUCT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-w-product"
    }
  }, [_vm._v("\n              " + _vm._s(((_vm$loanData$appData11 = _vm.loanData.appData) === null || _vm$loanData$appData11 === void 0 ? void 0 : (_vm$loanData$appData12 = _vm$loanData$appData11.loanDetails) === null || _vm$loanData$appData12 === void 0 ? void 0 : (_vm$loanData$appData13 = _vm$loanData$appData12.settlement) === null || _vm$loanData$appData13 === void 0 ? void 0 : (_vm$loanData$appData14 = _vm$loanData$appData13.warranty) === null || _vm$loanData$appData14 === void 0 ? void 0 : _vm$loanData$appData14.product) || _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_WHOLESALE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-w-wholesale"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.loanData.appData.loanDetails.settlement.warranty.wholesale >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.warranty.wholesale) : _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RETAIL))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-w-retail"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.loanData.appData.loanDetails.settlement.warranty.retail >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.warranty.retail) : _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_COMMISSION))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-w-commission"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.loanData.appData.loanDetails.settlement.warranty.commission >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.warranty.commission) : _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), (_vm$loanData$appData15 = _vm.loanData.appData) !== null && _vm$loanData$appData15 !== void 0 && (_vm$loanData$appData16 = _vm$loanData$appData15.loanDetails) !== null && _vm$loanData$appData16 !== void 0 && _vm$loanData$appData16.settlement ? _c('el-card', {
    staticClass: "application-form pb-child-none"
  }, [_c('div', {
    staticClass: "el-alert text-black is-light mb-6",
    class: _vm.insuranceAlertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT ? 'bg-primary el-alert--primary' : _vm.insuranceAlertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT ? 'bg-FFF2EE' : 'el-alert--success',
    attrs: {
      "role": "alert"
    }
  }, [_vm.insuranceAlertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/info.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }) : _vm.insuranceAlertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/send-orange.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "privacy-check-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.insuranceAlertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_NOT_SENT ? _vm.DESCRIPTION_FOR_COMPLIANCE_INSURANCE_PRIVACY_CONSENT : _vm.insuranceAlertStatus == _vm.INSURANCE_PRIVACY_CONSENT_REQUEST_STATUS_CONSENT_SENT ? ("Privacy consent request sent at " + (_vm.format(new Date((_vm$sharedData$insura = (_vm$sharedData = _vm.sharedData) === null || _vm$sharedData === void 0 ? void 0 : (_vm$sharedData$insura2 = _vm$sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura2 === void 0 ? void 0 : (_vm$sharedData$insura3 = _vm$sharedData$insura2.lastSentAt) === null || _vm$sharedData$insura3 === void 0 ? void 0 : _vm$sharedData$insura3.date) !== null && _vm$sharedData$insura !== void 0 ? _vm$sharedData$insura : ''), 'dd/MM/yyyy hh:mm aaa'))) : ("Privacy consent submitted at " + (_vm.format((_Date = new Date((_vm$sharedData2 = _vm.sharedData) === null || _vm$sharedData2 === void 0 ? void 0 : (_vm$sharedData2$insur = _vm$sharedData2.insurancePrivacyConsent) === null || _vm$sharedData2$insur === void 0 ? void 0 : _vm$sharedData2$insur.date)) !== null && _Date !== void 0 ? _Date : '', 'dd/MM/yyyy hh:mm aaa')))))])]), _vm._v(" "), !((_vm$sharedData$insura4 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura4 !== void 0 && _vm$sharedData$insura4.date) ? _c('div', {
    staticClass: "send-button"
  }, [_c('el-button', {
    attrs: {
      "type": (_vm$sharedData$insura5 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura5 !== void 0 && (_vm$sharedData$insura6 = _vm$sharedData$insura5.lastSentAt) !== null && _vm$sharedData$insura6 !== void 0 && _vm$sharedData$insura6.date ? 'default' : 'primary',
      "data-selector": "p-send-resend-btn",
      "plain": (_vm$sharedData$insura7 = _vm.sharedData.insurancePrivacyConsent) === null || _vm$sharedData$insura7 === void 0 ? void 0 : (_vm$sharedData$insura8 = _vm$sharedData$insura7.lastSentAt) === null || _vm$sharedData$insura8 === void 0 ? void 0 : _vm$sharedData$insura8.date
    },
    on: {
      "click": function ($event) {
        return _vm.sendInsurancePrivacyConsentEmailAndSmsRequest(_vm.primaryData.primaryApplicant._id);
      }
    }
  }, [_vm._v("\n            " + _vm._s((_vm$sharedData$insura9 = _vm.sharedData.insurancePrivacyConsent) !== null && _vm$sharedData$insura9 !== void 0 && (_vm$sharedData$insura10 = _vm$sharedData$insura9.lastSentAt) !== null && _vm$sharedData$insura10 !== void 0 && _vm$sharedData$insura10.date ? 'Resend' : 'Send'))])], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "st-insurance-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_INSURANCE)), _vm.loanData.appData.loanDetails.settlement.insurance.provider && !_vm.disableInsurance ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "st-insurance-edit-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openInsuranceForm(_vm.FORM_TYPE_EDIT);
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))]) : _vm._e()])]), _vm._v(" "), !_vm.loanData.appData.loanDetails.settlement.insurance.provider ? _c('div', {
    staticClass: "border-t border-light-grey pt-4"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "icon": "el-icon-plus",
      "plain": "",
      "disabled": _vm.disableInsurance,
      "data-selector": "st-add-insurance-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openInsuranceForm(_vm.FORM_TYPE_ADD);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ADD_INSURANCE))])], 1) : _vm._e(), _vm._v(" "), _vm.loanData.appData.loanDetails.settlement.insurance.provider ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_vm.loanData.appData ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_PROVIDER))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-i-provider"
    }
  }, [_vm._v("\n              " + _vm._s(((_vm$loanData$appData17 = _vm.loanData.appData) === null || _vm$loanData$appData17 === void 0 ? void 0 : (_vm$loanData$appData18 = _vm$loanData$appData17.loanDetails) === null || _vm$loanData$appData18 === void 0 ? void 0 : (_vm$loanData$appData19 = _vm$loanData$appData18.settlement) === null || _vm$loanData$appData19 === void 0 ? void 0 : (_vm$loanData$appData20 = _vm$loanData$appData19.insurance) === null || _vm$loanData$appData20 === void 0 ? void 0 : (_vm$loanData$appData21 = _vm$loanData$appData20.providerData) === null || _vm$loanData$appData21 === void 0 ? void 0 : _vm$loanData$appData21.businessName) || _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_POLICY))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-i-policy"
    }
  }, [_vm._v("\n              " + _vm._s(((_vm$loanData$appData22 = _vm.loanData.appData) === null || _vm$loanData$appData22 === void 0 ? void 0 : (_vm$loanData$appData23 = _vm$loanData$appData22.loanDetails) === null || _vm$loanData$appData23 === void 0 ? void 0 : (_vm$loanData$appData24 = _vm$loanData$appData23.settlement) === null || _vm$loanData$appData24 === void 0 ? void 0 : (_vm$loanData$appData25 = _vm$loanData$appData24.insurance) === null || _vm$loanData$appData25 === void 0 ? void 0 : _vm$loanData$appData25.policyInfo) || _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_FREQUENCY))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-i-frequency"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.getInsuranceFrquency) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_PREMIUM))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-i-premium"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.loanData.appData.loanDetails.settlement.insurance.premium >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.insurance.premium) : _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e(), _vm._v(" "), _vm.loanData.appData ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_COMMISSION))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "saved-i-commission"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.loanData.appData.loanDetails.settlement.insurance.commision >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.insurance.commision) : _vm.LABEL_NOT_AVAILABLE) + "\n            ")])])]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1), _vm._v(" "), _c('WarrantyFormDialog', {
    attrs: {
      "open-dialog": _vm.WarrantyFormDialogState,
      "shared-data": _vm.loanData.appData
    }
  }), _vm._v(" "), _c('InsuranceFormDialog', {
    attrs: {
      "open-dialog": _vm.InsuranceFormDialogState,
      "shared-data": _vm.loanData.appData
    }
  }), _vm._v(" "), _c('tab-footer', {
    attrs: {
      "saveBtnLabel": _vm.LABEL_NEXT,
      "showBackButton": true,
      "showNextButton": false
    },
    on: {
      "goBack": function ($event) {
        return _vm.sectionRedirect(_vm.SETTLEMENT_MENU_LOAN_DETAILS_KEY);
      },
      "submitForm": _vm.gotoNext
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }