//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  MAINTAB_COMPLIANCE_FINANCE_KEY,
  MAINTAB_COMPLIANCE_FINANCE_LABEL,
  MAINTAB_COMPLIANCE_INSURANCE_KEY,
  MAINTAB_COMPLIANCE_INSURANCE_LABEL,
} from '~/constants/applications'
import ComplianceFinanceTab from './ComplianceFinanceTab.vue'
import ComplianceInsuranceTab from './ComplianceInsuranceTab.vue'
export default {
  components: {
    ComplianceFinanceTab,
    ComplianceInsuranceTab,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    accountData: {
      type: Array,
      default: () => [],
    },
    loanDetails: {
      type: Object,
      default: () => ({}),
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
    this.$nuxt.$on('change-compliance-tab', (newActiveSubTab) => {
      this.setActiveSubTab(newActiveSubTab)
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
    this.$nuxt.$off('change-compliance-tab')
  },
  data() {
    return {
      activeSubTab: MAINTAB_COMPLIANCE_FINANCE_KEY,
      MAINTAB_COMPLIANCE_INSURANCE_KEY,
      MAINTAB_COMPLIANCE_FINANCE_KEY,
      MAINTAB_COMPLIANCE_FINANCE_LABEL,
      MAINTAB_COMPLIANCE_INSURANCE_LABEL,
    }
  },
  methods: {
    setActiveSubTab(newActiveSubTab) {
      this.activeSubTab = newActiveSubTab
    },
  },
}
