var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "modal-income loan-details title-hide",
    attrs: {
      "width": "30%",
      "close-on-click-modal": false,
      "visible": _vm.openDialog != '',
      "destroy-on-close": "",
      "close-on-press-escape": false,
      "data-selector": "insurance-edit-modal"
    }
  }, [_c('div', [_vm.loanDetailsForm ? [_c('div', {
    staticClass: "application-form"
  }, [_c('el-form', {
    ref: "insuranceData",
    attrs: {
      "model": _vm.insuranceData,
      "rules": _vm.rules
    }
  }, [_c('el-card', {
    staticClass: "card-child-p-0 pb-4",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.openDialog === _vm.FORM_TYPE_ADD ? _vm.LABEL_FOR_INSURANCE : _vm.LABEL_FOR_ADD_INSURANCE))])])]), _vm._v(" "), _vm.insuranceData.loanDetails.settlement ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.insurance.provider",
      "label": _vm.LABEL_FOR_PROVIDER,
      "data-selector": "iem-provider-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.insuranceData.loanDetails.settlement.insurance.provider,
      callback: function ($$v) {
        _vm.$set(_vm.insuranceData.loanDetails.settlement.insurance, "provider", $$v);
      },
      expression: "\n                    insuranceData.loanDetails.settlement.insurance.provider\n                  "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "iem-provider-options-container"
    }
  }, _vm._l(_vm.PROVIDERS, function (data) {
    return _c('el-option', {
      key: data._id,
      attrs: {
        "label": data.businessName,
        "value": data._id
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.insurance.policyInfo",
      "label": _vm.LABEL_FOR_POLICY,
      "data-selector": "iem-policy-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "iem-policy-input"
    },
    model: {
      value: _vm.insuranceData.loanDetails.settlement.insurance.policyInfo,
      callback: function ($$v) {
        _vm.$set(_vm.insuranceData.loanDetails.settlement.insurance, "policyInfo", $$v);
      },
      expression: "\n                    insuranceData.loanDetails.settlement.insurance.policyInfo\n                  "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.insurance.frequency",
      "label": _vm.LABEL_FOR_FREQUENCY,
      "data-selector": "iem-frequency-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.insuranceData.loanDetails.settlement.insurance.frequency,
      callback: function ($$v) {
        _vm.$set(_vm.insuranceData.loanDetails.settlement.insurance, "frequency", $$v);
      },
      expression: "\n                    insuranceData.loanDetails.settlement.insurance.frequency\n                  "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "iem-frequency-options-container"
    }
  }, _vm._l(_vm.INSURANCE_FREQUENCY, function (data, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.shouldShowFrequencyAmount ? _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.insurance.frequencyAmount",
      "label": _vm.LABEL_MONTHLY_PREMIMUM,
      "data-selector": "iem-monthly-premium-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "iem-monthly-premium-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculatePremimum();
      }
    },
    model: {
      value: _vm.insuranceData.loanDetails.settlement.insurance.frequencyAmount,
      callback: function ($$v) {
        _vm.$set(_vm.insuranceData.loanDetails.settlement.insurance, "frequencyAmount", $$v);
      },
      expression: "\n                    insuranceData.loanDetails.settlement.insurance\n                      .frequencyAmount\n                  "
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.insurance.premium",
      "label": _vm.LABEL_FOR_PREMIUM,
      "data-selector": "iem-premium-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": _vm.shouldDisableInsurancePremium,
      "data-selector": "iem-premium-input"
    },
    model: {
      value: _vm.insuranceData.loanDetails.settlement.insurance.premium,
      callback: function ($$v) {
        _vm.$set(_vm.insuranceData.loanDetails.settlement.insurance, "premium", $$v);
      },
      expression: "\n                    insuranceData.loanDetails.settlement.insurance.premium\n                  "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.insurance.commision",
      "label": _vm.LABEL_FOR_COMMISSION,
      "data-selector": "iem-commission-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "iem-commission-input"
    },
    model: {
      value: _vm.insuranceData.loanDetails.settlement.insurance.commision,
      callback: function ($$v) {
        _vm.$set(_vm.insuranceData.loanDetails.settlement.insurance, "commision", $$v);
      },
      expression: "\n                    insuranceData.loanDetails.settlement.insurance.commision\n                  "
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mb-0 border-t border-light-grey pt-4 child-w-full flex items-center"
  }, [_c('el-button', {
    staticClass: "mr-auto",
    attrs: {
      "plain": "",
      "type": "text",
      "data-selector": "iem-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _vm.openDialog === _vm.FORM_TYPE_EDIT ? _c('el-button', {
    attrs: {
      "plain": "",
      "type": "danger",
      "data-selector": "iem-remove-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REMOVE))]) : _vm._e(), _vm._v(" "), _c('el-button', {
    attrs: {
      "type": "primary",
      "data-selector": "iem-update-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.openDialog === _vm.FORM_TYPE_ADD ? _vm.LABEL_SAVE : _vm.LABEL_UPDATE) + "\n            ")])], 1)], 1)], 1)] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }