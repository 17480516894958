var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "visible": _vm.shouldShowImpersonateDialog,
      "width": "40%",
      "center": "",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false,
      "modalAppendToBody": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.shouldShowImpersonateDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('p', {
    staticClass: "el-dialog__title mb-2 text-center font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_TITLE_IMPERSONATE_ACCELERATE))])]), _vm._v(" "), _c('el-form', {
    ref: "impersonateForm",
    attrs: {
      "model": _vm.impersonateForm
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "mobile",
      "rules": _vm.mobileNumberRules(),
      "data-selector": "phone-input-container"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "placeholder": "04XXXXXXXX",
      "data-selector": "phone-input"
    },
    model: {
      value: _vm.impersonateForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.impersonateForm, "mobile", $$v);
      },
      expression: "impersonateForm.mobile"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "mt-4 pt-4 border-t border-light-grey flex justify-between"
  }, [_c('el-button', {
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": !_vm.impersonateForm.mobile.length
    },
    on: {
      "click": _vm.impersonate
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SWITCH_TO_THAT_USER))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }