//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
  LABEL_FOR_RESEND_DOCUSIGN_ENVELOPE,
  RESEND_DOCUSIGN_ENVELOPE_DESCRIPTION,
} from '@/constants/applications'

export default {
  name: 'ResendDocusignEnvelopeDialogue',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    applicantId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      getIsDialogLoading: false,
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
      RESEND_DOCUSIGN_ENVELOPE_DESCRIPTION,
      saveBtnLoading: false,
    }
  },
  computed: {
    /* eslint-disable */

    title() {
      return LABEL_FOR_RESEND_DOCUSIGN_ENVELOPE
    },
  },

  async mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
      this.closeDialog()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeResendDocusignEnvelopeDialog')
    },
    async submit() {
      const { id, applicationId } = this.$route.params
      this.saveBtnLoading = true
      // TODO: create a util class for reusability
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await this.$store.dispatch('applications/eSignRequestEmail', {
        id,
        applicationId,
        applicantId: this.applicantId,
        forceNew: true,
      })
      await this.$store.dispatch('applications/fetchApplications', {
        id,
        applicationId,
      })
      this.getIsDialogLoading = false
      this.saveBtnLoading = false
      this.$nuxt.$emit('closeResendDocusignEnvelopeDialog')
    },
  },
}
